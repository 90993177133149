import { handleRequest } from "../constant/helpers"

/**
* Fetch Visualize Map Info
* @param {*} id 
*/
export const fetchVisualizeMapInfo = id => handleRequest('get', `/projects/${id}/visualize-map`, false, 'application/json')



/**
* Fetch Visualize Map Info
* for specific measurment
* @param {*} pId 
* @param {*} mId 
*/
export const fetchVisualizeMapForMeasurment = (pId, mId) => handleRequest('get', `/projects/${pId}/calculation/measurement-file-raw-data/${mId}`, false, 'application/json')


/**
* Fetch Visualize Map Info
* for all measurment
* @param {*} pId 
*/
export const fetchVisualizeMapForAllMeasurment = (pId) => handleRequest('get', `/projects/${pId}/all-measurement-file-raw-data`, false, 'application/json')