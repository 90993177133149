import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Network = ({
    fill = colorCodes.BLUE_4,
    height = 24,
    width = 24,
    style = {},
    viewBox = '0 0 24 24',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <path id="network" d="M20.857,8.857h3.429A1.714,1.714,0,0,0,26,7.143V3.714A1.714,1.714,0,0,0,24.286,2H20.857a1.714,1.714,0,0,0-1.714,1.714v.857H16.571a1.714,1.714,0,0,0-1.714,1.714v6.857H12.286V10.571a1.714,1.714,0,0,0-1.714-1.714H3.714A1.714,1.714,0,0,0,2,10.571v6.857a1.714,1.714,0,0,0,1.714,1.714h6.857a1.714,1.714,0,0,0,1.714-1.714V14.857h2.571v6.857a1.714,1.714,0,0,0,1.714,1.714h2.571v.857A1.714,1.714,0,0,0,20.857,26h3.429A1.714,1.714,0,0,0,26,24.286V20.857a1.714,1.714,0,0,0-1.714-1.714H20.857a1.714,1.714,0,0,0-1.714,1.714v.857H16.571V14.857h2.571v.857a1.714,1.714,0,0,0,1.714,1.714h3.429A1.714,1.714,0,0,0,26,15.714V12.286a1.714,1.714,0,0,0-1.714-1.714H20.857a1.714,1.714,0,0,0-1.714,1.714v.857H16.571V6.286h2.571v.857A1.714,1.714,0,0,0,20.857,8.857ZM10.571,17.429H3.714V10.571h6.857Zm10.286,3.429h3.429v3.429H20.857Zm0-8.571h3.429v3.429H20.857Zm0-8.571h3.429V7.143H20.857Z" transform="translate(-2 -2)" fill={fill} />
    </svg>
)

export default Network