import { useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { drawVerticalLine } from '../../../../constant/helpers';
import './index.scss';

ChartJS.register(
    zoomPlugin,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    drawVerticalLine
);


const colorCodesArr = [
    { borderColor: 'rgb(255,0,0)', backgroundColor: 'rgba(255,0,0, 0.5)', },
    { borderColor: 'rgb(255,128,0)', backgroundColor: 'rgba(255,128,0, 0.5)', },
    { borderColor: 'rgb(252, 149, 50)', backgroundColor: 'rgba(252, 149, 50, 0.5)', },
    { borderColor: 'rgb(255,255,0)', backgroundColor: 'rgba(255,255,0, 0.5)', },
    { borderColor: 'rgb(128,255,0)', backgroundColor: 'rgba(128,255,0, 0.5)', },
    { borderColor: 'rgb(0,255,0)', backgroundColor: 'rgba(0,255,0, 0.5)', },
    { borderColor: 'rgb(0,255,128)', backgroundColor: 'rgba(0,255,128, 0.5)', },
    { borderColor: 'rgb(0,255,255)', backgroundColor: 'rgba(0,255,255, 0.5)', },
    { borderColor: 'rgb(0,128,255)', backgroundColor: 'rgba(0,128,255, 0.5)', },
    { borderColor: 'rgb((0,0,255)', backgroundColor: 'rgba((0,0,255, 0.5)', },
    { borderColor: 'rgb(128,0,255)', backgroundColor: 'rgba(128,0,255, 0.5)', },
    { borderColor: 'rgb(255,0,255)', backgroundColor: 'rgba(255,0,255, 0.5)', },
    { borderColor: 'rgb(255,0,128)', backgroundColor: 'rgba(255,0,128, 0.5)', },
    { borderColor: 'rgb(0, 111, 189)', backgroundColor: 'rgba(0, 111, 189, 0.5)', },
    { borderColor: 'rgb(242, 162, 235)', backgroundColor: 'rgba(242, 162, 235, 0.5)', }
]

const RawDataChart = ({
    options, gData = [], resetZoomBtn = true, lineAtIndex = false
}) => {

    let stationKmArr = []
    let d0Arr = []
    let d100Arr = []
    let d200Arr = []
    let d300Arr = []
    let d450Arr = []
    let d600Arr = []
    let d900Arr = []
    let d1200Arr = []
    let d1800Arr = []
    let sciArr = []
    let bciArr = []
    let bdiArr = []

    for (var i = 1; i < gData.length; i++) {
        stationKmArr.push(gData[i].Station)
        d0Arr.push(gData[i].D0)
        d100Arr.push(gData[i].D100)
        d200Arr.push(gData[i].D200)
        d300Arr.push(gData[i].D300)
        d450Arr.push(gData[i].D450)
        d600Arr.push(gData[i].D600)
        d900Arr.push(gData[i].D900)
        d1200Arr.push(gData[i].D1200)
        d1800Arr.push(gData[i].D1800)
        sciArr.push(gData[i].SCI)
        bciArr.push(gData[i].BCI)
        bdiArr.push(gData[i].BDI)
    }

    const data = {
        labels: stationKmArr,
        datasets: [
            {
                label: "D0",
                data: d0Arr,
                borderColor: colorCodesArr[0].borderColor,
                backgroundColor: colorCodesArr[0].backgroundColor,
                hidden: false
            },
            {
                label: "D100",
                data: d100Arr,
                borderColor: colorCodesArr[1].borderColor,
                backgroundColor: colorCodesArr[1].backgroundColor,
                hidden: true
            },
            {
                label: "D200",
                data: d200Arr,
                borderColor: colorCodesArr[2].borderColor,
                backgroundColor: colorCodesArr[2].backgroundColor,
                hidden: true
            },
            {
                label: "D300",
                data: d300Arr,
                borderColor: colorCodesArr[4].borderColor,
                backgroundColor: colorCodesArr[4].backgroundColor,
                hidden: true
            },
            {
                label: "D450",
                data: d450Arr,
                borderColor: colorCodesArr[5].borderColor,
                backgroundColor: colorCodesArr[5].backgroundColor,
                hidden: true
            },
            {
                label: "D600",
                data: d600Arr,
                borderColor: colorCodesArr[6].borderColor,
                backgroundColor: colorCodesArr[6].backgroundColor,
                hidden: true
            },
            {
                label: "D900",
                data: d900Arr,
                borderColor: colorCodesArr[7].borderColor,
                backgroundColor: colorCodesArr[7].backgroundColor,
                hidden: true
            },
            {
                label: "D1200",
                data: d1200Arr,
                borderColor: colorCodesArr[8].borderColor,
                backgroundColor: colorCodesArr[8].backgroundColor,
                hidden: true
            },
            {
                label: "D1800",
                data: d1800Arr,
                borderColor: colorCodesArr[10].borderColor,
                backgroundColor: colorCodesArr[10].backgroundColor,
                hidden: true
            },
            {
                label: "SCI",
                data: sciArr,
                borderColor: colorCodesArr[11].borderColor,
                backgroundColor: colorCodesArr[11].backgroundColor,
                hidden: true
            },
            {
                label: "BCI",
                data: bciArr,
                borderColor: colorCodesArr[12].borderColor,
                backgroundColor: colorCodesArr[12].backgroundColor,
                hidden: true
            },
            {
                label: "BDI",
                data: bdiArr,
                borderColor: colorCodesArr[13].borderColor,
                backgroundColor: colorCodesArr[13].backgroundColor,
                hidden: true
            }
        ],
    };

    const defaultOptions = {
        zoom: {
            enabled: true,
            mode: 'x',
            drag: {
                enabled: true,
                borderColor: 'rgb(54, 162, 235)',
                borderWidth: 1,
                backgroundColor: 'rgba(54, 162, 235, 0.3)'
            }
        },
        pan: {
            enabled: true,
            mode: 'xy',
            modifierKey: 'ctrl'
        },
        responsive: true,
        plugins: {
            legend: {
                position: "top"
            },
            title: {
                display: true,
                text: "Chart.js Line Chart"
            }
        },
        tooltips: {
            titleFontSize: 20,
            bodyFontSize: 20,
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString();
                    return label;
                }
            }
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        scales: {
            x: {
                ticks: {
                    display: true,
                    // beginAtZero: true,
                    // maxTicksLimit: 20,
                    stepSize: 0.1,
                    min: 0,
                    max: 10,
                },
                afterBuildTicks: function (mychart) {
                    mychart.chart.config.lineAtIndex = !!lineAtIndex ? [lineAtIndex] : []
                }
            },
            // to remove the y-axis labels
            y: {
                ticks: {
                    display: true,
                    beginAtZero: true,
                },
                // to remove the y-axis grid
                grid: {
                    drawBorder: true,
                    display: true,
                },
            },
            // y: {
            //     type: 'linear',
            //     display: true,
            //     position: 'left',
            // },
            // y1: {
            //     type: 'linear',
            //     display: true,
            //     position: 'right',
            //     grid: {
            //         drawOnChartArea: false,
            //     },
            // },
        },
    };

    const chartRef = useRef();

    const handleResetZoom = () => {
        if (!!chartRef && !!chartRef.current && !!chartRef.current.resetZoom) {
            chartRef.current.resetZoom();
        }
    }

    return (
        <>
            <Line id="raw-data-line-chart" options={{ ...defaultOptions, ...options }} ref={chartRef} data={data} />
            {!!resetZoomBtn && <button className='def-reset-chart-btn' onClick={handleResetZoom}>Reset Zoom</button>}
        </>
    )
}

export default RawDataChart