import { useEffect, useRef, useState, Fragment } from "react"
import { Field, Form } from "react-final-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import moment from "moment"
import { BlobServiceClient } from '@azure/storage-blob'
import { ZipReader, BlobReader, BlobWriter } from '@zip.js/zip.js'
import { fetchProjectInfo, unzipUploadedMeasurmentInputFile, uploadMeasurmentInputFile } from "../../../actions/project"
import Icon from "../../../components/common/Icons"
import RaptorButton from "../../../components/common/RaptorButton"
import SpinLoader from "../../../components/common/SpinLoader"
import ReduxLabelInput from "../../../components/redux-form/ReduxLabelInput"
import ReduxSelectField from "../../../components/redux-form/ReduxSelectField"
import ReduxTextArea from "../../../components/redux-form/ReduxTextArea"
import { required } from "../../../components/redux-form/validations"
import { validInputFileExt } from "../../../constant"
import FileProgressCard from "../FileProgressCard"
import SpinLoader2 from "../../../components/common/SpinLoader2"
import SuccessDialog from "../../../components/common/SuccessDialog"
import "./index.scss"

const UPLOAD_IN_SINGLE_CALL = 4
const AddMeasurment = () => {
  const [hasError, setHasError] = useState(false)
  const [hasAllFiles, setHasAllFiles] = useState(false)
  const [xmlLoader, setXmlLoader] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const projectDetails = useSelector(state => state.projects)
  const projectInfo = projectDetails.projectInfo
  const [progressVal, setProgressVal] = useState({
    key1: 'uploading-progress-bar-selector',
    key2: 'uploading-progress-val-selector',
    progress: 0,
    index: 0,
    completed: false,
  })
  const [mFiles, setMFiles] = useState([])
  const fileInputRef = useRef()
  const fileInput2Ref = useRef()
  const defaultValues = {
    fileName: "", measurmentDatetime: "", measurementLength: 1, averageSpeed: 1
  }
  const [autoFillVal, setAutoFillVal] = useState([defaultValues])
  const [initValueInfo, setInitValueInfo] = useState({})
  const [mForm, setMForm] = useState(0)
  const [currentUpldIndx, setCurrentUpldIndx] = useState(1)

  useEffect(() => {
    if (!!id) {
      dispatch(fetchProjectInfo(id))
    }

    // eslint-disable-next-line
  }, [id])

  const handleFileChoose = async (data, idx) => {
    const { files } = data.target

    if (!files.length) {
      return false
    }

    setHasError(false)
    for (var j = 0; j < files.length; j++) {
      if (!validInputFileExt.includes(files[j].type)) {
        toast.error(t("message.error.file_format_invalid"))
        return false
      }
    }

    let filesArray = mFiles
    for (var i = 0; i < files.length; i++) {
      filesArray[idx] = files[i]
    }

    setMFiles(filesArray)

    for (var k = 0; k < filesArray.length; k++) {
      if (!filesArray[k] || (typeof filesArray[k] === 'undefined') || (typeof filesArray[k] === typeof undefined)) {
        break;
      }
      setHasAllFiles(true)
    }

    // Extract general params (avg speed, etc.)
    const blobReader = new BlobReader(filesArray[idx]);
    const zipReader = new ZipReader(blobReader);
    const blobWriter = new BlobWriter('application/json');
    // Get names of files inside zip
    const entries = await zipReader.getEntries();

    // Find general params file
    const generalParamsFile = entries.find((entry) => entry.filename.match(/general_dict\.json/))

    if (!!generalParamsFile) {
      const fileData = await generalParamsFile.getData(blobWriter);
      if (!!fileData) {
        const generalParams = JSON.parse(await fileData.text());
        const isDate = function (date) {
          return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
        }
        if (!!generalParams) {
          autoFillVal[idx] = {
            ...autoFillVal[idx],
            fileName: filesArray[idx].name,
            ...(!!generalParams.record_date && { measurmentDatetime: !!isDate(generalParams.record_date) ? generalParams.record_date : moment(new Date()).format() }),
            ...(!!generalParams.measurement_length && { measurementLength: generalParams.measurement_length }),
            ...(!!generalParams.avg_speed && { averageSpeed: generalParams.avg_speed })
          }
          setAutoFillVal([...autoFillVal])
          return true
        }
      }
    }

    // HINT: Set default response in case of json file not found
    autoFillVal[idx] = {
      ...autoFillVal[idx],
      fileName: filesArray[0].name,
      measurmentDatetime: new Date(),
      measurementLength: 0,
      averageSpeed: 0
    }
    setAutoFillVal(autoFillVal)
  }

  const uploadChoosedFile = async (file, sasTokenObj, count) => {
    setHasError(false)
    try {
      const uploadOptions = {
        maxSingleShotSize: 4 * 1000 * 1000, // 4 MB chunks
        onProgress: (p) => setProgressVal({
          index: count,
          key1: `uploading-progress-bar-${count}`,
          key2: `uploading-progress-val-${count}`,
          progress: Math.round(p.loadedBytes / file.size * 1000) / 10,
          completed: Math.round(p.loadedBytes / file.size * 1000) / 10 === 100
        }) // progress percentage with one decimal
      }
      setXmlLoader(true)
      const blobServiceClient = new BlobServiceClient(`${sasTokenObj.storageAccountUri}?${sasTokenObj.sasToken}`);
      const containerClient = blobServiceClient.getContainerClient(sasTokenObj.containerName)
      const blockBlobClient = containerClient.getBlockBlobClient(sasTokenObj.blobName)

      const uploadResponse = await blockBlobClient.uploadData(file, uploadOptions)
      setXmlLoader(false)
      setProgressVal({
        index: count,
        key1: `uploading-progress-bar-${count}`,
        key2: `uploading-progress-val-${count}`,
        progress: 0,
        completed: false
      })
      return uploadResponse
    } catch (e) {
      setXmlLoader(false)
      console.log('Upload Input File Error:', e)
      setHasError('Something went wrong, Please upload again.')
      return false
    }
  }

  useEffect(() => {
    if (!!fileInputRef && !!fileInputRef.current) {
      fileInputRef.current.value = ""
    }
    if (!!fileInput2Ref && !!fileInput2Ref.current) {
      fileInput2Ref.current.value = ""
    }
  }, [mFiles])

  const onSubmit = async (data) => {
    try {
      const arrLength = Array.from(Array(mForm + 1).keys())
      var hasAnyEmptyElement = mFiles.filter(function (val) { return (typeof val) !== "undefined" }).length !== mFiles.length

      if (!mFiles.length) {
        toast.error("Please choose measurment file")
        return false
      }

      if (!!hasAnyEmptyElement || (arrLength.length !== mFiles.length)) {
        toast.error("Please choose measurment file for each section")
        return false
      }

      window.open(`/raptor/projects/view/${id}?flagMsg=1`, '_blank', 'noopener,noreferrer');

      let executedRec = false
      let blobArr = []
      for (const count of arrLength) {
        const resp = await dispatch(uploadMeasurmentInputFile(id, {
          "fileName": data.fileName[count],
          "roadName": data.roadName[count],
          "roadType": data.roadType[count],
          "direction": Number(data.direction[count]),
          "comment": !!data.comment && !!data.comment[count] ? data.comment[count] : "",
          "measurementDateTime": new Date(moment(new Date(data.measurementDateTime[count])).format('lll')).getTime(),
          "measurementLength": Number(data.measurementLength[count]),
          "averageSpeed": Number(data.averageSpeed[count])
        }))
        if (!!resp && !!resp.newBlob && !!resp.newBlob.blobName) {
          blobArr.push(resp.newBlob.blobName)
        }
        if (!!resp && !!resp.sasTokenObj) {
          setCurrentUpldIndx(count + 1)
          await uploadChoosedFile(mFiles[count], resp.sasTokenObj, count)
          executedRec = count
        }
      }

      if (executedRec === (arrLength.length - 1)) {
        const unzipResp = await dispatch(unzipUploadedMeasurmentInputFile(id, blobArr))
        toast.success(<SuccessDialog message={!!unzipResp ? 'Saved Successfully' : 'Saved Successfully, but issue with unzip'} />)
        navigate(`/raptor/projects/view/${id}`)
      }
      return false
    } catch (e) {
      console.log('Add Measurment Form Submit Error:', e)
    }
  }

  // As of now, we're redirecting on cancel instead of form reset
  // const onFormReset = form => {
  //   setMFiles([])
  //   form.reset()
  // }

  useEffect(() => {
    let setInitValues = {}
    for (let k = 0; k < autoFillVal.length; k++) {
      const valueOnKey = !!autoFillVal && !!autoFillVal[k] ? autoFillVal[k] : false
      setInitValues = {
        measurementDateTime: {
          ...setInitValues.measurementDateTime,
          [k]: !!valueOnKey && !!valueOnKey.measurmentDatetime ? valueOnKey.measurmentDatetime : ''
        },
        measurementLength: {
          ...setInitValues.measurementLength,
          [k]: !!valueOnKey && !!valueOnKey.measurementLength ? valueOnKey.measurementLength : 1
        },
        averageSpeed: {
          ...setInitValues.averageSpeed,
          [k]: !!valueOnKey && !!valueOnKey.averageSpeed ? valueOnKey.averageSpeed : 1
        },
        fileName: {
          ...setInitValues.fileName,
          [k]: !!valueOnKey && !!valueOnKey.fileName ? valueOnKey.fileName : ''
        }
      }
    }

    setInitValueInfo(setInitValues)
  }, [autoFillVal])

  const handleCrossClick = (idx) => {
    let filesArray = mFiles
    delete filesArray[idx]
    setMFiles([...filesArray])

    autoFillVal[idx] = {
      fileName: "",
      measurementLength: 1,
      averageSpeed: 1,
      measurmentDatetime: ""
    }
    setAutoFillVal([...autoFillVal])
    setHasAllFiles(false)
  }


  const handleAddMoreOrRemove = (addMore = true) => {
    if (addMore) {
      setMForm((mForm < UPLOAD_IN_SINGLE_CALL) ? (mForm + 1) : mForm)
      setHasAllFiles(false)
    } else {
      const formLength = mForm > 0 ? mForm - 1 : mForm
      setMForm(formLength)

      for (var k = 0; k < (formLength + 1); k++) {
        if (!mFiles[k] || (typeof mFiles[k] === 'undefined') || (typeof mFiles[k] === typeof undefined)) {
          setHasAllFiles(false)
          break;
        }
        setHasAllFiles(true)
      }
    }
  }

  const childHtml = () => {
    const formSections = [];
    for (let i = 0; i <= mForm; i++) {
      formSections.push(<Fragment key={i}>
        <div className="input-holder-d-flex">
          <Field name={`roadName[${i}]`} label={t('project.form.rd_name') + "*"} component={ReduxLabelInput} placeholder={t('project.form.enter_rd_name')} validate={required} />
          <Field name={`roadType[${i}]`} label={t('project.form.type') + "*"} defaultValue="Highway" component={ReduxLabelInput} placeholder={t("project.form.enter_type")} validate={required} />
          <Field name={`direction[${i}]`} label={t('project.form.direction') + "*"} defaultValue="1"  placeholder={t('project.form.direction')} component={ReduxSelectField} validate={required} options={[1, 2, 3, 4].map(s => ({ label: `${s}`, value: s }))} />
        </div>

        <div className="input-holder-d-flex upld-msrmnt-main-div">
          <label className="upld-msrmnt-main-label">{`${t("project.form.upld_measur_file")}*`}</label>
          {!!mFiles && !!mFiles[i] ? <div className="d-flex mt-14 mb-10">
            <FileProgressCard
              progressBarSelector={`uploading-progress-bar-${i}`}
              progressValSelector={`uploading-progress-val-${i}`}
              progressObj={{
                key1: `uploading-progress-bar-${i}`,
                key2: `uploading-progress-val-${i}`,
                progress: (parseInt(progressVal.index) === i) ? progressVal.progress : ((progressVal.index > i) ? 100 : 0),
                completed: (parseInt(progressVal.index) === i) ? progressVal.completed : ((progressVal.index > i) ? true : progressVal.completed),
              }}
              onCrossClick={() => handleCrossClick(i)} showProgress={xmlLoader} progress={progressVal} fileInfo={mFiles[i]} />
          </div> : <>
            <div className="d-flex mt-14 mb-10">
              <label className="input-type-label-tag" htmlFor={`upload-measurment-file-${i}`}>{t("project.form.browse_measur_file")}</label>
              <input type="file" name={`file1[${i}]`} id={`upload-measurment-file-${i}`} ref={fileInput2Ref} onChange={param => { handleFileChoose(param, i) }} />
            </div>
            <div className="heading-title-content">{t("project.form.nt_file_allwoed_zip")}</div>
          </>}

        </div>

        <div className="input-holder-d-flex">
          <Field name={`measurementDateTime[${i}]`} label={t('project.form.m_date_time') + "*"} component={ReduxLabelInput} placeholder={t("project.form.date_time")} readOnly disabled />
          <Field name={`measurementLength[${i}]`} type="number" label={t('project.form.length') + "*"} component={ReduxLabelInput} placeholder={t("project.form.length")} readOnly disabled />
          <Field name={`averageSpeed[${i}]`} type="number" label={t('project.form.av_speed') + "*"} component={ReduxLabelInput} placeholder={t("project.form.av_speed")} readOnly disabled />
        </div>

        <div className="input-holder-d-flex">
          <Field name={`comment[${i}]`} label={t('project.form.comment')} rows={3} component={ReduxTextArea} placeholder={t("project.form.enter_comment")} />
        </div>
      </Fragment>);
    }
    return formSections
  }

  return (
    <div className="add-measurment-form-holder">
      {(!!projectDetails.isProcessing) && <SpinLoader />}
      {!!xmlLoader && <SpinLoader2 outOf={currentUpldIndx + '/' + mFiles.length} progress={progressVal.progress} />}
      <div className="d-flex">
        <div className="project-view-form-container d-flex">
          <div className="title-and-back-btn">
            <img src={require('../../../assets/images/back.svg').default} alt="Back" onClick={() => navigate(-1)} />
            <div className="title-value">{!!projectInfo && !!projectInfo.projectName ? projectInfo.projectName : '-'}</div>
            <Icon name="pencil" onClick={() => navigate(`/raptor/projects/edit/${id}`)} />
          </div>
          <div className="page-desc-sec">{!!projectInfo && !!projectInfo.client ? projectInfo.client : '-'}</div>
        </div>
      </div>

      <div className="project-add-measurment-section">
        <div className="left-section-holder">

          <div className='measurment-context'>
            <div className="heading-holder d-flex">
              <div className="heading-title">{t("project.add_measurment")}</div>
              <div className="heading-title-content">({t('project.form.add_measur_desc')})</div>
            </div>
            <Form
              subscription={{ submitting: true, pristine: true }}
              enableReinitialize={true}
              keepDirtyOnReinitialize={true}
              forceUnregisterOnUnmount={false}
              onSubmit={onSubmit}
              initialValues={initValueInfo}
              FormState
              render={({
                handleSubmit, submitting, pristine, form
              }) => (
                <form onSubmit={handleSubmit} className='add-measurment-form-div'>
                  {childHtml()}
                  <div className="between-flex">
                    <div className='add-msr-act-btn-holder'>
                      <RaptorButton title={t("button.cancel")} className='cancel-prj-btn' onClick={() => navigate(`/raptor/projects/view/${id}`)} />
                      <RaptorButton type="submit" title={!!submitting ? `${t('button.loading')}...` : (t('project.add_measurment'))} disabled={submitting || pristine || !!hasError || !hasAllFiles} />
                    </div>
                    <div className='add-msr-act-btn-holder'>
                      {mForm > 0 && <RaptorButton title={t("button.remove")} className='cancel-prj-btn' onClick={() => handleAddMoreOrRemove(false)} />}
                      {mForm < UPLOAD_IN_SINGLE_CALL && <RaptorButton title={`+ ${t('button.add_more')}`} onClick={() => handleAddMoreOrRemove()} />}
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
        <div className="measurment-map-card" />
      </div>
    </div>
  )
}

export default AddMeasurment