import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import Overlay from "ol/Overlay";
import RaptorContext from "../../context/RaptorContext";
import { setDFormView, setRawDataObj } from "../../actions/visualize";
import "./Overlay.scss";

const OverlayDiv = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { map } = useContext(RaptorContext)
  const { globalActiveTab, defaultProjID } = useSelector(state => state.global)
  const { selectedCalc, visualizeCalcInfo } = useSelector(state => state.visualizes)
  const defaultState = {
    station: null, events: null, speed: null, airTemp: null, surfTemp: null,
    d0: null, d100: null, d200: null, d300: null, d450: null,
    d600: null, d900: null, d1200: null, d1800: null
  }
  const [hoverProp, setHoverProp] = useState(defaultState)

  useEffect(() => {
    if (!map) return

    map.on("pointermove", (event) => {
      let clickedFeature2 = {}

      const clickedFeature = event.coordinate;
      map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        clickedFeature2 = event.coordinate;
        let newHoverProp = hoverProp

        const featureArr = !!feature && !!feature.values_ && !!feature.values_.features ? feature.values_.features : []
        if (!!featureArr && (featureArr.length === 1)) {
          const featureProp = !!featureArr[0] && !!featureArr[0].values_ && !!featureArr[0].values_.properties ? featureArr[0].values_.properties : {}
          if (featureProp.Station) {
            newHoverProp.station = featureProp.Station
          }
          if (featureProp.Speed) {
            newHoverProp.speed = featureProp.Speed
          }
          if (featureProp.AirTemp) {
            newHoverProp.airTemp = featureProp.AirTemp
          }
          if (featureProp.SurfTemp) {
            newHoverProp.surfTemp = featureProp.SurfTemp
          }
          if (featureProp.D0) {
            newHoverProp.d0 = featureProp.D0
          }
          if (featureProp.D100) {
            newHoverProp.d100 = featureProp.D100
          }
          if (featureProp.D200) {
            newHoverProp.d200 = featureProp.D200
          }
          if (featureProp.D300) {
            newHoverProp.d300 = featureProp.D300
          }
          if (featureProp.D450) {
            newHoverProp.d450 = featureProp.D450
          }
          if (featureProp.D600) {
            newHoverProp.d600 = featureProp.D600
          }
          if (featureProp.D900) {
            newHoverProp.d900 = featureProp.D900
          }
          if (featureProp.D1200) {
            newHoverProp.d1200 = featureProp.D1200
          }
          if (featureProp.D1800) {
            newHoverProp.d1800 = featureProp.D1800
          }
          if (featureProp.Events) {
            newHoverProp.events = featureProp.Events
          }
          // HINT: Only for the testing purpose
          if (featureProp.mId) {
            newHoverProp.mId = featureProp.mId
          }
          if (featureProp.percentage) {
            newHoverProp.percentage = featureProp.percentage
          }
          if (featureProp.color) {
            newHoverProp.color = featureProp.color
          }
        }
        setHoverProp(newHoverProp)
      })

      if (clickedFeature2 !== clickedFeature) {
        setHoverProp(defaultState)
      }
      //setClickCoordinate(event.coordinate)

      var popup = new Overlay({
        element: document.getElementById("overlapExample"),
        position: event.coordinate
      });
      map.addOverlay(popup);

      const overlayElem = document.querySelector('#overlapExample.overlay-cotainer')
      if (!!overlayElem && !!overlayElem.offsetWidth) {
        overlayElem.style.marginLeft = `-${overlayElem.offsetWidth / 2}px`
      }
      /*map.on("moveend", () => {
      zoom=map.getView().getZoom();
    });
	
      map.on('zoomend', function () {
      zoom=map.getView().getZoom();
    });*/
    })

    map.on("click", (event) => {
      setHoverProp(defaultState)
      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        const featureArr = !!feature && !!feature.values_ && !!feature.values_.features ? feature.values_.features : []
        if (!!featureArr && (featureArr.length === 1)) {
          const featureProp = !!featureArr[0] && !!featureArr[0].values_ && !!featureArr[0].values_.properties ? featureArr[0].values_.properties : {}
          if ((featureProp.D0 || featureProp.D100 || featureProp.D200 || featureProp.D450) &&
            !!defaultProjID && !!selectedCalc && !!selectedCalc._id && !!featureProp.mId) {
            dispatch(setDFormView(true))
            if (!!visualizeCalcInfo && !!visualizeCalcInfo[0] && !!visualizeCalcInfo[0]['measurementFileInfo']) {
              const measurmentArr = visualizeCalcInfo[0]['measurementFileInfo']
              for (var a = 0; a <= measurmentArr.length; a++) {
                if (!!measurmentArr[a] && !!measurmentArr[a]['measurementFileIds'] && (measurmentArr[a]['measurementFileIds'] === featureProp.mId)) {
                  dispatch(setRawDataObj({
                    roadName: measurmentArr[a]['roadName'],
                    mapData: measurmentArr[a]['mapData'],
                    lineAtIndex: featureProp.Station
                  }))
                  break
                }
              }

            }

          }
        }
      })
    })

    // eslint-disable-next-line
  }, [map, visualizeCalcInfo])

  return (
    <div id="overlapExample" className={!!Object.values(hoverProp).some(val => !!val) ? "overlay-cotainer" : ""}>
      {!!hoverProp.station && <p>Station - {hoverProp.station}</p>}
      {!!hoverProp.events && <p>Events - {hoverProp.events}</p>}
      {/* HINT: Only for the testing purpose */}
      {/* {!!hoverProp.mId && <p>ID - {hoverProp.mId}</p>}
      {!!hoverProp.percentage && <p>% - {hoverProp.percentage}</p>}
      {!!hoverProp.color && <div style={{ minHeight: 10, minWidth: 10, backgroundColor: hoverProp.color }}>% - {hoverProp.color}</div>} */}
      {
        (globalActiveTab === "measur") && (!!hoverProp.speed || !!hoverProp.airTemp || !!hoverProp.surfTemp) &&
        <>
          {!!hoverProp.speed && <p>{t('map_overlay.speed')} - {hoverProp.speed}</p>}
          {!!hoverProp.airTemp && <p>{t('map_overlay.air_temp')} - {hoverProp.airTemp}</p>}
          {!!hoverProp.surfTemp && <p>{t('map_overlay.surf_temp')} - {hoverProp.surfTemp}</p>}
        </>
      }
      {
        (globalActiveTab === "ip_file") &&
        (!!hoverProp.d0 || !!hoverProp.d100 || !!hoverProp.d200 ||
          !!hoverProp.d300 || !!hoverProp.d450 || !!hoverProp.d600 ||
          !!hoverProp.d900 || !!hoverProp.d1200 || !!hoverProp.d1800) &&
        <>
          {!!hoverProp.d0 && <p>D0 - {hoverProp.d0}</p>}
          {!!hoverProp.d100 && <p>D100 - {hoverProp.d100}</p>}
          {!!hoverProp.d200 && <p>D200 - {hoverProp.d200}</p>}
          {!!hoverProp.d300 && <p>D300 - {hoverProp.d300}</p>}
          {!!hoverProp.d450 && <p>D450 - {hoverProp.d450}</p>}
          {!!hoverProp.d600 && <p>D600 - {hoverProp.d600}</p>}
          {!!hoverProp.d900 && <p>D900 - {hoverProp.d900}</p>}
          {!!hoverProp.d1200 && <p>D1200 - {hoverProp.d1200}</p>}
          {!!hoverProp.d1800 && <p>D1800 - {hoverProp.d1800}</p>}
        </>
      }
    </div>
  )
}

export default OverlayDiv
