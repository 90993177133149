import { useEffect, useMemo, useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import './index.scss'

ChartJS.register(
    zoomPlugin,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const colorCodesArr = [
    { borderColor: 'rgb(223, 35, 35)', backgroundColor: 'rgba(223, 35, 35, 0.5)', },
    { borderColor: 'rgb(30, 133, 66)', backgroundColor: 'rgba(30, 133, 66, 0.5)', },
    { borderColor: 'rgb(252, 149, 50)', backgroundColor: 'rgba(252, 149, 50, 0.5)', },
    { borderColor: 'rgb(0, 111, 189)', backgroundColor: 'rgba(0, 111, 189, 0.5)', },
    { borderColor: 'rgb(242, 162, 235)', backgroundColor: 'rgba(242, 162, 235, 0.5)', }
]

const DeflectionChart = ({
    options, gData = [], resetZoomBtn = true
}) => {

    useEffect(() => {
        const contentHolderDiv = document.getElementsByClassName('content-holder')
        if (!!contentHolderDiv && !!contentHolderDiv[0]) {
            contentHolderDiv[0].style.zoom = '1'
        }
        return () => {
            if (!!contentHolderDiv && !!contentHolderDiv[0]) {
                contentHolderDiv[0].style.zoom = '0.8'
            }
        }
    }, [])

    const memoizeMultiIDs = records => {
        let stationKmArr = []
        let fifteenHundredUmArr = []
        let nineHundredUmArr = []
        let sixHundredUmArr = []
        let threeHundredUmArr = []

        for (var i = 1; i < records.length; i++) {
            stationKmArr.push(records[i].stationKm)
            fifteenHundredUmArr.push(records[i].fifteenHundredUm)
            nineHundredUmArr.push(records[i].nineHundredUm)
            sixHundredUmArr.push(records[i].sixHundredUm)
            threeHundredUmArr.push(records[i].threeHundredUm)
        }

        return {
            stationKmArr,
            fifteenHundredUmArr,
            nineHundredUmArr,
            sixHundredUmArr,
            threeHundredUmArr
        }
    }

    const memoizeData = useMemo(() => memoizeMultiIDs(gData), [gData])

    const data = {
        labels: memoizeData.stationKmArr,
        datasets: [
            {
                label: "-1500_0_1500_um",
                data: memoizeData.fifteenHundredUmArr,
                borderColor: colorCodesArr[0].borderColor,
                backgroundColor: colorCodesArr[0].backgroundColor,
            },
            {
                label: "900_0_900_um",
                data: memoizeData.nineHundredUmArr,
                borderColor: colorCodesArr[1].borderColor,
                backgroundColor: colorCodesArr[1].backgroundColor,
            },
            {
                label: "-600_0_600_um",
                data: memoizeData.sixHundredUmArr,
                borderColor: colorCodesArr[2].borderColor,
                backgroundColor: colorCodesArr[2].backgroundColor,
            },
            {
                label: "-300_0_300_um",
                data: memoizeData.threeHundredUmArr,
                borderColor: colorCodesArr[4].borderColor,
                backgroundColor: colorCodesArr[4].backgroundColor,
            }
        ],
    };

    const defaultOptions = {
        zoom: {
            enabled: true,
            mode: 'x',
            drag: {
                enabled: true,
                borderColor: 'rgb(54, 162, 235)',
                borderWidth: 1,
                backgroundColor: 'rgba(54, 162, 235, 0.3)'
            }
        },
        pan: {
            enabled: true,
            mode: 'xy',
            modifierKey: 'ctrl'
        },
        responsive: true,
        plugins: {
            legend: {
                position: "top"
            },
            title: {
                display: true,
                text: "Chart.js Line Chart"
            }
        },
        tooltips: {
            titleFontSize: 20,
            bodyFontSize: 20,
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString();
                    return label;
                }
            }
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        scales: {
            x: {
                ticks: {
                    display: true,
                    // beginAtZero: true,
                    // maxTicksLimit: 20,
                    stepSize: 0.1,
                    min: 0,
                    max: 10,
                },
            },
            // to remove the y-axis labels
            y: {
                ticks: {
                    display: true,
                    beginAtZero: true,
                },
                // to remove the y-axis grid
                grid: {
                    drawBorder: true,
                    display: true,
                },
            },
            // y: {
            //     type: 'linear',
            //     display: true,
            //     position: 'left',
            // },
            // y1: {
            //     type: 'linear',
            //     display: true,
            //     position: 'right',
            //     grid: {
            //         drawOnChartArea: false,
            //     },
            // },
        },
    };

    const chartRef = useRef();

    const handleResetZoom = () => {
        if (!!chartRef && !!chartRef.current && !!chartRef.current.resetZoom) {
            chartRef.current.resetZoom();
        }
    }

    return (
        <>
            <Line options={{ ...defaultOptions, ...options }} ref={chartRef} data={data} />
            {!!resetZoomBtn && <button className='def-reset-chart-btn' onClick={handleResetZoom}>Reset Zoom</button>}
        </>
    )
}

export default DeflectionChart