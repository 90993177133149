import { useTranslation } from 'react-i18next'
import "./index.scss"

const LabelInput = ({
    id = Math.random(), label = 'Label', className = '', min = "0", max = "1000000000",
    errClass = '', errMsg = '', onFocus, onBlur, showLabel = true, labelComp = false,
    step = "any", type = 'text', value, onClick, onChange, required = false,
    disabled = false, readOnly = false, placeholder = '', showErrWithLabel = true,
    isValidating = false
}) => {
    const { t } = useTranslation()
    return (
        <label htmlFor={id} className={`raptor-label-input ${className} ${errClass}`}
            onClick={(e) => {
                !!onClick && onClick(e)
            }}>{showLabel ? (!!labelComp ? labelComp : (label + ((!!errMsg && !!showErrWithLabel) ? ' - ' + t(errMsg) : ''))) : null}
            {
                type === 'number' ? <input id={id} type='number' min={min} max={max} value={value || min}
                    disabled={disabled} readOnly={readOnly} placeholder={placeholder}
                    step={step} onChange={onChange} required={required} onClick={e => e.stopPropagation()} /> :
                    <input id={id} type={type} value={value || ''} onFocus={onFocus} onBlur={onBlur}
                        disabled={disabled} readOnly={readOnly} placeholder={placeholder}
                        onChange={onChange} required={required} onClick={e => e.stopPropagation()} />
            }
            {!!isValidating && <span className="validate-msg-span">{`${t('message.validating')}...`}</span>}
            {(!!errMsg && !isValidating) && <span className="err-msg-span">{t(errMsg)}</span>}
        </label>
    )
}

export default LabelInput