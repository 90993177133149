import React from 'react'
import { useNavigate } from 'react-router'
import './index.scss'

const Terms = () => {
    const navigate = useNavigate()
    return (
        <div className="terms-container">
            <div className="terms-header" onClick={() => navigate('/')}>
                <img src={require("../../assets/images/raptor-logo.svg").default} alt="Raptor" />
            </div>
            <div className="terms-content">
                <p className='p-heading'><strong><u><span>RAPTOR WEB APPLICATION<br />&nbsp;disclaimers, terms OF USE and PRIVACY LANDING PAGE</span></u></strong></p>
                <p className='p-heading2'><u><span className='date-span'>Date Last Updated</span></u><span className='date-value'>: <em><u>[31 May 2023]</u></em></span></p>
                <p className='p-div-li'><strong><em><span className='p-span-style'>IF YOU NEED OR WANT TO RECEIVE A REPRODUCTION OF THE BELOW IN AN ALTERNATE FORMAT, PLEASE CONTACT <u>MARTIN WISTR&Ouml;M</u> AT <a href="mailto:MARTIN.WISTROM@RAMBOLL.SE">MARTIN.WISTROM@RAMBOLL.SE</a><u>.</u></span></em></strong></p>
                <div className='heading-div'>
                    <ol>
                        <li><strong><u><span className='sp1'>LIMITED/RESTRICTED LICENSE TO ACCESS/USE PLATFORM, ETC.</span></u></strong><span className='sp2'>&nbsp;Subject to (a) the below definitions, (b) the remaining terms of this Platform-Terms Landing Page, (c) the Terms of Use and (d) the Platform Privacy Policy, Ramboll grants to you a limited and restricted license to access certain parts of the Platform and to access and use certain of the Platform Functionality/Services as discussed in the Platform Service Description. <strong>You may not access or use any part of the Platform or any of the Platform Functionality/Services unless and until you have read and agree to be bound by the terms of this Platform-Terms Landing Page and the Terms of Use, and unless and until you have read and acknowledge the Platform Service Description and Platform Privacy Policy</strong>.</span></li>
                    </ol>
                </div>
                <p className='p-cls'><strong><span className='p-span-style'>&nbsp;</span></strong></p>
                <div className='p-div-li'>
                    <ol className="ol-cmn">
                        <li className='p-div-li'><strong><u><span className='u-span-style'>DEFINITIONS</span></u></strong><span className="fs-13">. For purposes hereof and of the below-incorporated Terms of Use and Platform Privacy Policy, the below defined terms shall have the meanings set forth below. &nbsp; Additional defined terms and their respective definitions for purposes hereof and of the below-incorporated Terms of Use and Platform Privacy Policy may be set forth elsewhere herein, in the Terms of Use and/or in the Platform Privacy Policy.</span></li>
                    </ol>
                </div>
                <p className='p-cls'><span className='p-span-style'>&nbsp;</span></p>
                <ol className="ol-cls">
                    <li><span>&nbsp;&ldquo;<strong>Affiliate</strong>&rdquo;<strong>&nbsp;</strong>means a direct or indirect parent company of Ramboll or a direct or indirect wholly or partially owned subsidiary of any such parent company.</span></li>
                    <li><span>&ldquo;<strong>Corporate Parent</strong>&rdquo;<strong>&nbsp;</strong>means Ramboll&rsquo;s direct parent company, Ramboll Group A/S.</span></li>
                    <li><span>&ldquo;<strong>Dispute Resolution Forum/Rules</strong>&rdquo; means the laws applicable in Sweden.</span></li>
                    <li><span>&ldquo;<strong>Dispute Resolution Jurisdiction</strong>&rdquo; means the courts of Sweden.</span></li>
                    <li><span>&ldquo;<strong>Platform</strong>&rdquo;<strong>&nbsp;</strong>means the <em>RAPTOR WEB APPLICATION</em>, together with any and all other applications, websites or similar platforms that are linked to the RAPTOR WEB APPLICATION or to this Platform-Terms Landing Page, or that incorporate either by reference.</span></li>
                    <li><span>&ldquo;<strong>Platform Data Processing Agreement</strong>&rdquo;<strong>&nbsp;</strong>means the data processing agreement located at&nbsp;</span><a href="https://ramboll.com/terms-of-use-and-privacy-policy/standard-platform-data-processing-agreement"><span className="fs-13">https://ramboll.com/terms-of-use-and-privacy-policy/standard-platform-data-processing-agreement</span></a><span className="fs-13">.</span></li>
                    <li><span>&ldquo;<strong>Platform Functionality/Services</strong>&rdquo;<strong>&nbsp;</strong>means the Platform&rsquo;s functionality and the content, data, information, products and/or services available through, or otherwise related to, the Platform.</span></li>
                    <li><span>&ldquo;<strong>Platform-Terms Landing Page</strong>&rdquo;<strong>&nbsp;</strong>means this <em>RAPTOR WEB APPLICATION</em> Disclaimers, Terms of Use and Privacy Landing Page.</span></li>
                    <li><strong><span>&ldquo;Platform Service Description&rdquo;&nbsp;</span></strong><span>means the <em>RAPTOR WEB APPLICATION</em> Service Description located at&nbsp;</span><a href="https://www.raptorweb.info"><span>https://www.raptorweb.info</span></a><span className="fs-13">.</span></li>
                    <li><span>&ldquo;<strong>Platform Privacy Policy</strong>&rdquo;<strong>&nbsp;</strong>means the privacy terms, disclosures, etc., located at&nbsp;</span><a href="https://ramboll.com/terms-of-use-and-privacy-policy/standard-platform-policy"><span className="fs-13">https://ramboll.com/terms-of-use-and-privacy-policy/standard-platform-policy</span></a><span className="fs-13">, as well as those incorporated therein by reference, as may be modified by this Platform Landing Page.</span></li>
                    <li><span>&ldquo;<strong>Ramboll</strong>&rdquo;<strong>&nbsp;</strong>means Ramboll Sweden AB, a company a company registered in and formed under the laws of Sweden.</span></li>
                    <li><span>&ldquo;<strong>Retention Period</strong>&rdquo;<strong>&nbsp;</strong>means the personal data retention period(s) applicable to the Platform, as set forth in the Platform Data Processing Agreement, or, if none, six (6) months after your license to use the Platform or any of the Platform Functionality/Services expires.</span></li>
                    <li><span>&ldquo;<strong>Terms of Use</strong>&rdquo;<strong>&nbsp;</strong>means the terms of use located at&nbsp;</span><a href="https://ramboll.com/legal-information/standard-online-platform-terms-with-fee-provisions"><span className="fs-13">https://ramboll.com/legal-information/standard-online-platform-terms-with-fee-provisions</span></a><span className="fs-13">, as may be modified by this Platform Landing Page.</span></li>
                </ol>
                <p className='p-cls22'><strong><span>&nbsp;</span></strong></p>
                <div className='cmn-style'>
                    <ol className="ol-cmn">
                        <li className='cmn-style'><strong><u><span className='u-span-style'>DISCLAIMERS</span></u></strong><span className="fs-13">.</span></li>
                    </ol>
                </div>
                <p className='p-abc'><span className='p-span-style'>Any and all access to/use of the Platform and/or any Platform Functionality/Services by you or any other person or entity (other than Ramboll) is for informational purposes only. Except as and to the extent expressly and unequivocally assumed, respectively, by Ramboll and/or a Ramboll Affiliate in this Platform-Terms Landing Page, the Terms of Use or the Platform Privacy Policy, neither Ramboll, nor any of its Affiliates, assumes any duty whatsoever to you or to any other person or entity arising from or related to your or any other person&rsquo;s or entity&rsquo;s access to or use of, or otherwise related to, the Platform or Platform Functionality/Services. Ramboll and its Affiliates expressly disclaim any and all duties beyond those, if any, expressly and unequivocally assumed by them, respectively, in this Platform-Terms Landing Page, the Terms of Use or the Platform Privacy Policy.&nbsp;</span></p>
                <p className='p-abc'><span className='p-span-style' style={{ color: "black", background: "white" }}>Without limiting any disclaimer or other term or condition stated in&nbsp;</span><span className='p-span-style'>this Platform-Terms Landing Page, the Terms of Use or the Platform Privacy Policy<span style={{ color: "black", background: "white" }}>, b</span>y accessing and/or using any part/aspect of the Platform or Platform Functionality/Services, you acknowledge the following:</span></p>
                <p className='p-abc'><span className='p-span-style'>In addition to the foregoing disclaimers, any and all access to/use of the Platform and/or any Platform Functionality/Services by you or any other person or entity (other than Ramboll) also is subject to any and all disclaimers set forth elsewhere herein or in the Terms of Use or Platform Privacy Policy.</span></p>
                <div className='cmn-style'>
                    <ol style={{ marginBottom: "0cm", listStyleType: "undefined" }}>
                        <li className='cmn-style'><strong><u><span className='u-span-style'>TERMS AND CONDITIONS</span></u></strong><span className="fs-13">.</span></li>
                    </ol>
                </div>
                <p className='cmn-style' style={{ textAlign: 'justify' }}><span className='p-span-style'>By accessing and/or using any part/aspect of the Platform or Platform Functionality/Services,&nbsp;</span><span className='p-span-style'>you affirm that you are over eighteen (18) years old, and you acknowledge and agree that, by accessing and/or using any of the foregoing, you, individually/personally, and on behalf of any and all other persons and entities on behalf of whom/which you access/use, or purport to access and/or use, the Platform or the Platform Functionality/Services, are entering into a legally binding agreement/contract with Ramboll consisting of all terms and conditions set forth in or incorporated into this Platform-Terms Landing Page (including specifically, but without limitation, the Terms of Use), and that you and any such other persons or entities understand and agree to comply with and be legally bound by those terms and conditions. The Platform and the Platform Functionality/Services are intended for business-to-business, professional or educational use only, and not for consumer use (i.e., use unrelated to the user&rsquo;s business, professional or educational activity). &nbsp;By accessing and/or using any part/aspect of the Platform or Platform Functionality/Services,&nbsp;</span><span className='p-span-style'>you affirm that you have accessed/used same and will access/use same in the future only for their intended use.</span></p>
                <p className='cmn-style'><span className='p-span-style'>As set forth more fully in the Terms of Use, access to/use of other parts of the Platform and the Platform Functionality/Services is/are subject to the non-public access and/or payment terms stated in the Terms of Use.</span></p>
                <p className='cmn-style'><span className='p-span-style'>In addition, the Platform Data Processing Agreement is hereby incorporated into the Terms of Use.</span></p>
                <div className='cmn-style'>
                    <ol style={{ marginBottom: "0cm", listStyleType: "undefined" }}>
                        <li className='cmn-style'><strong><u><span className='u-span-style'>PRIVACY</span></u></strong><span className="fs-13">.</span></li>
                    </ol>
                </div>
                <p className='cmn-style'><span className='p-span-style'>IN ADDITION, by accessing and/or using any part of&nbsp;</span><span className='p-span-style'>the Platform or Platform Functionality/Services, you, individually/personally, and on behalf of any and all other persons and entities on behalf of whom/which you access/use, or purport to access and/or use, the Platform or the Platform Functionality/Services, acknowledge that you have read and understand the Platform Privacy Policy, including, any and all policies, statements, terms, disclosures, etc., that may be incorporated therein by reference, whether related to privacy or otherwise, such as, for example, any general privacy policies, statements, terms, disclosures, etc., of Ramboll &nbsp;and its Affiliates, including its Corporate Parent (like those set forth at https://ramboll.com/legal-information), and any jurisdiction-specific privacy policies, statements, terms, disclosures, etc., (like those made pursuant to or otherwise related to the European Union General Data Protection Regulation or the California Consumer Privacy Act), whether generally applicable, applicable only to citizens/residents of the subject jurisdiction or otherwise.</span></p>
                <p className='cmn-style'><span className='p-span-style'>Please note, however, that:</span></p>
                <ol start="1" style={{ listStyleType: "upper-alpha", marginLeft: 44 }}>
                    <li><span className='li-span'>where general privacy policies, statements, terms, disclosures, etc., that are incorporated by reference into the Platform Privacy Policy conflict with information set forth elsewhere that is specific to the Platform, such as, for example, within the text of the Platform Privacy Policy page itself, the latter shall govern;</span></li>
                    <li><span className='li-span'>where jurisdiction-specific privacy policies, statements, terms, disclosures, etc., that are set forth within or incorporated by reference into the Platform Privacy Policy conflict with other information set forth within or incorporated into the Platform Privacy Policy, then, as to citizens/residents of a given jurisdiction, and those whose relevant rights otherwise are governed by the privacy laws of the subject jurisdiction, the subject jurisdiction-specific privacy policies, statements, terms, disclosures, etc., shall govern; and</span></li>
                    <li><span className='li-span'>information set forth within or incorporated by reference into the Platform Privacy Policy may not apply to other applications, websites and platforms of Ramboll and its Affiliates. &nbsp;</span></li>
                </ol>
                <p className='cmn-style'><span className='p-span-style'>In addition, please note that, to the extent the Platform or Platform Functionality/Services link to third-party applications, websites or other platforms, Ramboll and its Affiliates are not responsible for the content, privacy practices, etc., of such third-party applications, websites or other platforms. &nbsp;Ramboll encourages users to be aware when they leave the Platform or Platform Functionality/Services and to read the terms of use, privacy statements, etc., of any other application, website or other platform that collects information.</span></p>

            </div>
        </div>
    )
}

export default Terms