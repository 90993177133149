import { useState } from "react"
import { useTranslation } from "react-i18next"
import LabelSelectBox from "../../LabelSelectBox"
import "./index.scss"

const CommonMultiSelectFilter = ({ column }) => {
    const [values, setValues] = useState(!!column && !!column.filterValue ? column.filterValue : [])
    const { t } = useTranslation()
    const labelVal = <div>{t(column.render("Header"))}{!!column.isSortable && <img src={require('../../../../assets/images/up-down-arrow.svg').default} alt="Sort" className="ml-10 c-pointer" />}</div>
    return (
        <div className={`common-multi-select-filter`}>
            <label className="waywize-multi-select-input-label">{labelVal}</label>
            <LabelSelectBox
                isMulti={true}
                options={column.options}
                value={values}
                getOptionValue={option => option.label}
                handleOnChange={value => {
                    const valArr = value.map(v => v.label) || [];
                    setValues(value)
                    column.setFilter(valArr || undefined); // Set undefined to remove the filter entirely
                }}
            />
        </div>
    )
}

export default CommonMultiSelectFilter