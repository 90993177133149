import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalActiveTab } from "../../../actions/global";
import Icon from "../Icons";
import RaptorButton from "../RaptorButton";
import "./index.scss";

const Tabs = ({
    tabList = [], activeTabVal = 0
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { globalActiveTab } = useSelector(state => state.global)

    useEffect(() => {
        if (!!tabList && !!tabList.length) {
            dispatch(setGlobalActiveTab(tabList[0].id))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!!activeTabVal) {
            dispatch(setGlobalActiveTab(activeTabVal))
        }
        // eslint-disable-next-line
    }, [activeTabVal])

    return (
        <div className="Tabs">
            <div className="tab-nav-list-holder">
                {
                    !!tabList && !!tabList.length && tabList.map((op, i) =>
                        <RaptorButton key={i} type="button" title={t(op.title)} id={op.id} className={`${globalActiveTab === op.id ? 'active' : ''}`} onClick={() => dispatch(setGlobalActiveTab(op.id))}>
                            <Icon name={op.icon} /> {t(op.title)}
                        </RaptorButton>
                    )
                }
            </div>

            <div className="tab-content-holder-outlet">
                {
                    !!tabList && !!tabList.length && tabList.map((op, i) =>
                        <div key={i} id={op.id} className={`tab-content-holder ${globalActiveTab === op.id ? 'active' : ''}`}>
                            {op.component}
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Tabs;
