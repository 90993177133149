import { handleRequest } from "../constant/helpers"


/**
 * Get all projects
 * list data
 */
export const fetchCountryList = () => handleRequest('get', '/projects/country-list', 'application/json')


/**
 * Get all projects
 * list data
 */
export const fetchProjectList = () => handleRequest('get', '/projects/', 'application/json')


/**
 * Get project Details
 * @param {*} id 
 * @returns 
 */
export const fetchProjectDetails = id => handleRequest('get', `/projects/${id}`, 'application/json')



/**
 * Set project as default
 * @param {*} id 
 * @returns 
 */
export const setDefaultProject = (id, data) => handleRequest('patch', `/projects/${id}/set-default-project`, data, 'application/json')



/**
 * Check Project
 * Unique name
 */
export const checkProjectName = data => handleRequest('post', '/projects/project-exists', data, 'application/json')


/**
 * Save Project
 */
export const saveProject = data => handleRequest('post', '/projects', data, 'application/json')


/**
* Edit Project
*/
export const editProjectInfo = (id, data) => handleRequest('patch', `/projects/${id}`, data, 'application/json')


/**
* Delete Project
*/
export const deleteProjectInfo = data => handleRequest('post', '/projects/delete-multiple', data, 'application/json')


/**
* Upload Measurment input files
 * @param {*} projectId 
 * @param {*} data 
 * @returns 
 */
export const uploadMeasurmentFile = (projectId, data) => handleRequest('post', `/projects/${projectId}/input-files`, data, 'application/json')


/**
* Upload Calibration input files
 * @param {*} projectId 
 * @param {*} data 
 * @returns 
 */
export const uploadCalibrationInputFile = (projectId, data) => handleRequest('post', `/projects/${projectId}/calibration-files`, data, 'application/json')


/**
* Delete Measurment input files
 * @param {*} projectId 
 * @param {*} data 
 * @returns 
 */
export const deleteMeasurmentInputFile = (projectId, data) => handleRequest('delete', `/projects/${projectId}/input-files`, data, 'application/json')


/**
* Delete Multi Measurment input files
 * @param {*} projectId 
 * @param {*} data 
 * @returns 
 */
export const deleteMultiMeasurmentInputFile = (projectId, data) => handleRequest('delete', `/projects/${projectId}/many-input-files`, data, 'application/json')


/**
* Delete Calibration input files
 * @param {*} projectId 
 * @param {*} data 
 * @returns 
 */
export const deleteCalibrationInputFile = (projectId, data) => handleRequest('delete', `/projects/${projectId}/many-calibration-files`, data, 'application/json')


/**
 * Tag Calibration with input files
 * @param {*} projectId 
 * @param {*} data 
 * @returns 
 */
export const tagCalibrationInputFile = (projectId, data) => handleRequest('patch', `/projects/${projectId}/tag-calibration-file`, { ...data, projectId }, 'application/json')


/**
 * Update Rate Result
 * @param {*} data 
 * @returns 
 */
export const updateCalRateResult = (projectId, data) => handleRequest('patch', `/projects/${projectId}/calculations/update-rate-result`, data, 'application/json')


/**
 * Fetch Project's Calculations
 * @param {*} id 
 * @returns 
 */
export const fetchProjectCalculations = id => handleRequest('get', `/projects/${id}/calculations/calculations-by-project-id`, false, 'application/json')


/**
 * Fetch Calculation's RDI Data
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const fetchCalcRDIData = (id, data) => handleRequest('post', `/projects/${id}/get-rdi-data`, data, 'application/json')


/**
 * Create New Calculation
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const createNewCalculation = (id, data) => handleRequest('post', `/projects/${id}/add-calculation-to-measurements`, data, 'application/json')


/**
* Check New Calculation
* Unique name
* @param {*} id 
* @param {*} data 
*/
export const checkCalcName = (id, data, signal) => handleRequest('post', `/projects/${id}/calculations/calculation-exists`, data, 'application/json', false, signal)


/**
 * Delete Calculation
 * @param {*} pId 
 * @param {*} cId 
 * @returns 
 */
export const deleteCalculationInfo = (pId, cId) => handleRequest('delete', `/projects/${pId}/calculations/${cId}`, false, 'application/json')


/**
* Add associate email
* @param {*} id 
* @param {*} data 
*/
export const addAssociateMail = (id, data) => handleRequest('post', `/projects/${id}/add-project-owner`, data, 'application/json')



/**
* Remove associate email
* @param {*} id 
* @param {*} data 
*/
export const removeAssociateMail = (id, data) => handleRequest('patch', `/projects/${id}/remove-ownership-by-email`, data, 'application/json')



/**
* Fetch Visualize Map Info
* @param {*} id 
*/
export const fetchVisualizeMapInfo = id => handleRequest('get', `/projects/${id}/visualize-map`, false, 'application/json')



/**
* Unzip uploaded file
* @param {*} pId 
* @param {*} data 
*/
export const unzipUploadFile = (pId, data) => handleRequest('post', `/projects/${pId}/unzip-upload`, data, 'application/json')



/**
* Edit Measurment Info
* @param {*} id 
* @param {*} data 
*/
export const editMeasurmentInfo = (id, data) => handleRequest('patch', `/projects/${id}/update-measurement`, data, 'application/json')