import React from 'react'
import { colorCodes } from '../../../constant/colors'

const GrowthArrow = ({
    fill = colorCodes.GREEN_7,
    height = 21,
    width = 41,
    style = {},
    viewBox = '0 0 41 21',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M1.63354 19L13.7647 5.74825L27.6022 14.8803L39.4739 2" stroke={fill} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

)

export default GrowthArrow