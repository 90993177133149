import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import "./index.scss"

const CopyRight = () => {
  const { t } = useTranslation()
  const dateObj = new Date()
  return (
    <div className="raptor-copyright">
      <span>{t("copyright.copyright")} © {dateObj.getFullYear()} Raptor {t("copyright.all_right_res")} <Link to={"/terms"} className="term-service">{t("copyright.terms_of_ser")}</Link></span>
    </div>
  )
}

export default CopyRight