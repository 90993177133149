import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './index.scss'

const SpinLoader2 = ({
    progress = 0,
    outOf = false,
    showProgress = true,
    title = "progress_dialog.uploading_file",
    subTitle = "progress_dialog.we_are_uploading"
}) => {
    const { t } = useTranslation()
    useEffect(() => {
        const elem = document.getElementById("spin-loader2-progress-bar");
        if (!!elem && !!elem.style) {
            elem.style.maxWidth = progress + "%";
        }
    }, [progress])

    return (
        <div className="spin-loader2-container center-flex">
            <div className='spin-loader2-progress-container'>
                <div className='spin-loader2-title'>{t(title)} {!!outOf ? outOf : ''}</div>
                <div className='spin-loader2-progress-bar-text'>{t(subTitle)}</div>
                {!!showProgress && <div id="spin-loader2-progress-holder"><div id="spin-loader2-progress-bar">{progress}%</div></div>}
            </div>
        </div>
    )
}

export default SpinLoader2