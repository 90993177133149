import axios from "axios";
import { BrowserAuthError } from '@azure/msal-browser';
import { eventIcon, defaultColorDot, defaultColorSquare } from "../common/Features/Style";
import B2CConfig from "../services/B2CConfig";
import msalObj from '../services/MsalService';
import { colorCodes } from "./colors";
import { toast } from "react-toastify";
import { setAutoOut, setUserInfo } from "../actions/global";
import store from "../common/Store";

const { GREY_1 } = colorCodes;

const getApiToken = async () => {
    const tokenRequest = {
        scopes: B2CConfig.apiScopes,
        forceRefresh: false
    };
    try {
        return await msalObj.acquireTokenSilent(tokenRequest);
    } catch (err) {
        if (
            err instanceof BrowserAuthError &&
            err.errorCode === 'user_cancelled'
        ) {
            // Do nothing
        } else {
            console.error(err);
            return null
        }
    }
};


/**
 * Handle request
 * @param {*} method 
 * @param {*} serverUrl 
 * @param {*} data 
 * @param {*} contentType 
 * @param {*} acceptType 
 * @param {*} signal 
 * @returns 
 */
export const handleRequest = async (method, serverUrl = false, data, contentType = false, acceptType = false, signal) => {
    try {
        const tokenObj = await getApiToken();
        return new Promise((resolve, reject) => {
            axios({
                ...(!!signal && {
                    beforeSend: function (jqXhr) {
                        signal.addEventListener("abort", () => {
                            jqXhr.abort()
                        })
                    }
                }),
                method,
                url: process.env.REACT_APP_API_URL + serverUrl,
                headers: {
                    ...(acceptType && { 'Accept': acceptType }),
                    ...(contentType && { 'Content-Type': contentType }),
                    ...(!!tokenObj && !!tokenObj.accessToken && { Authorization: 'Bearer ' + tokenObj.accessToken })
                },
                ...(!!data && { data })
            })
                .then(result => {
                    if (!!result && !!result.data && !!result.data.errors) {
                        const { errors } = result.data;
                        errors.map(e => {
                            if (!!e.message && (e.message === "Unauthorized user")) {
                                toast.error("You're not allowed to perform this action");
                            }
                            return e;
                        });
                    }
                    store.dispatch(setAutoOut(false));
                    return resolve(!!result && !!result.data ? result.data : result);
                })
                .catch(e => {
                    if (!!e && !!e.response && !!e.response.status && e.response.status === 401) {
                        store.dispatch(setAutoOut(true));
                        store.dispatch(setUserInfo({}));
                    }
                    return reject(e);
                });
        });
    } catch (e) {
        store.dispatch(setAutoOut(true));
        store.dispatch(setUserInfo({}));
        return e
    }
};


/**
 * Sleep function
 * for some delay
 * @param {*} ms 
 * @returns 
 */
export const sleep = (ms = 3000) => new Promise(resolve => setTimeout(() => resolve(true), ms));

const componentToHex2 = (c) => {
    const hex = c.toString(16).substring(0, 2);
    return hex.length === 1 ? "0" + hex : hex;
}


/**
 * Create a new color code
 * on basis of color array
 * @param {*} hexaColorCodes 
 * @returns 
 */
function createColorFromMultipleHexaCodes(hexaColorCodes) {
    // Convert each hexa color code to its RGB equivalent.
    const rgbValues = hexaColorCodes.map(hexaColorCode => {
        return {
            red: parseInt(hexaColorCode.substring(1, 3), 16),
            green: parseInt(hexaColorCode.substring(3, 5), 16),
            blue: parseInt(hexaColorCode.substring(5, 7), 16),
        };
    });

    // Calculate the average of the RGB values of all the hexa color codes.
    const averageRgbValues = {
        red: Math.floor(rgbValues.reduce((sum, rgbValue) => sum + rgbValue.red, 0) / rgbValues.length),
        green: Math.floor(rgbValues.reduce((sum, rgbValue) => sum + rgbValue.green, 0) / rgbValues.length),
        blue: Math.floor(rgbValues.reduce((sum, rgbValue) => sum + rgbValue.blue, 0) / rgbValues.length),
    };

    // Convert the average RGB values to a new hexa color code.
    return "#" + componentToHex2(averageRgbValues.red) + componentToHex2(averageRgbValues.green) + componentToHex2(averageRgbValues.blue);
}


export const mapAttributeFilter = ((
    feature, param = false, checkedMeasurmentIds = [], showEvents = false, keyName = false,
    objectWithMinMax = {}
) => {
    const size = feature.get('features').length
    const colorConditions = (feature, param, arr = [], size) => {

        if (!arr.includes(feature.values_.properties["mId"])) {

            console.log(feature.values_.properties[param], objectWithMinMax[param].min, objectWithMinMax[param].max)

            return defaultColorDot(GREY_1, size)
        }

        if (!!arr.length) {
            const numericVal = feature.values_.properties[param]
            const range = objectWithMinMax[param].max - objectWithMinMax[param].min
            const correctedStartValue = numericVal - objectWithMinMax[param].min
            const percentageVal = Number((correctedStartValue * 100) / range)
            const percVal = (percentageVal < 0) ? 0 : ((percentageVal > 100) ? 100 : percentageVal)
            feature.values_.properties['percentage'] = percVal
            feature.values_.properties['color'] = perc2color(percVal)
            return defaultColorDot(perc2color(percVal), size);
        }
    }

    const featureArr = !!feature && !!feature.values_ && !!feature.values_.features ? feature.values_.features : []
    if (!!featureArr && (featureArr.length === 1)) {
        const featureProp = !!featureArr[0] && !!featureArr[0].values_ && !!featureArr[0].values_.properties ? featureArr[0].values_.properties : {}
        if (!!featureProp['Events'] && !!showEvents) {
            return eventIcon(featureProp['Events'])
        } else if (!!param && !!featureProp[param] &&
            !!checkedMeasurmentIds.length && !!checkedMeasurmentIds.includes(featureProp["mId"])) {
            return colorConditions(featureArr[0], param, checkedMeasurmentIds, size)
        }
    } else {

        if (keyName === 'eventFeatures') {
            return defaultColorSquare(size)
        }

        let colorLists = []
        for (var c = 0; c < featureArr.length; c++) {
            const featureInst = !!featureArr[c] && !!featureArr[c].values_ && !!featureArr[c].values_.properties ? featureArr[c].values_.properties : {}
            if (!!param && !!featureInst && !!featureInst[param]) {

                if (!!checkedMeasurmentIds.length && !!checkedMeasurmentIds.includes(featureInst["mId"])) {
                    const numericVal = featureArr[c].values_.properties[param]
                    const range = objectWithMinMax[param].max - objectWithMinMax[param].min
                    const correctedStartValue = numericVal - objectWithMinMax[param].min
                    const percentageVal = Number((correctedStartValue * 100) / range)
                    const percVal = (percentageVal < 0) ? 0 : ((percentageVal > 100) ? 100 : percentageVal)
                    colorLists.push(perc2color(percVal))
                }
            }
        }

        const uniqueColorsArr = [...new Set(colorLists)]
        if (!!uniqueColorsArr.length) {
            if (keyName === 'eventFeatures') {
                return defaultColorSquare(size)
            }
            const newHexaColorCode = createColorFromMultipleHexaCodes(uniqueColorsArr);
            return defaultColorDot(newHexaColorCode, size)
        }
    }

    return defaultColorDot(GREY_1, size);
})


/**
 * Return only numeric value
 * @param {*} val 
 * @returns 
 */
export const onlyNumber = val => parseInt(val.replace(/\D/g, ''));


/**
 * Get size format
 * @param {*} bytes 
 * @param {*} decimals 
 * @returns 
 */
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


function oppositeOrder(number) {
    // Check if the number is between 0 and 100.
    if ((number < 0) || (number > 100)) {
        console.log("The number must be between 0 and 100.", number)
    }

    // Calculate the opposite order of the number.
    return 100 - number;
}

export const perc2color = (percVal) => {
    const perc = oppositeOrder(percVal)
    var r, g, b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    }
    else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}



/**
 * Draw Vertical line
 * in line chart graph
 */
export const drawVerticalLine = {
    id: 'raw-data-line-chart',
    getLinePosition: function (chart) {
        const { data } = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
        const chartData = !!chart && !!chart.config && !!chart.config.data && !!chart.config.data.labels ? chart.config.data.labels : []
        const lineIndex = !!chart && !!chart.config && !!chart.config.lineAtIndex && !!chart.config.lineAtIndex[0] ? chart.config.lineAtIndex[0] : false

        if (!!chartData && !!lineIndex) {
            const lineIndexVal = chartData.indexOf(lineIndex)
            if ((lineIndexVal > -1) && !!data && !!data[lineIndexVal]) {
                return data[lineIndexVal].x;
            }
        }
    },
    renderVerticalLine: function (chartInstance, pointIndex) {
        const lineLeftOffset = this.getLinePosition(chartInstance, pointIndex);
        const scale = chartInstance.scales['y'];
        const context = chartInstance.ctx;

        // render vertical line
        context.beginPath();
        context.strokeStyle = colorCodes.RED_1;
        context.moveTo(lineLeftOffset, scale.top);
        context.lineTo(lineLeftOffset, scale.bottom);
        context.stroke();
    },
    afterDatasetsDraw: function (chart) {
        if (chart.config.lineAtIndex) {
            chart.config.lineAtIndex.forEach(pointIndex => this.renderVerticalLine(chart, pointIndex));
        }
    }
}


export const medianofArr = (arr1) => {
    var concat = arr1;
    concat = concat.sort((a, b) => a - b);

    var length = concat.length;

    if (length % 2 == 1) {

        // If length is odd 
        return concat[(length / 2) - .5]

    }
    else {
        return (concat[length / 2]
            + concat[(length / 2) - 1]) / 2;
    }
} 