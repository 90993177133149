import color from '../assets/scss/common.module.scss'

export const colorCodes = {
  BLACK_1: color.black1,
  BLACK_2: color.black2,
  BLACK_3: color.black3,
  BLACK_4: color.black4,
  BLACK_5: color.black5,
  BLACK_6: color.black6,
  BLACK_7: color.black7,
  BLACK_8: color.black8,
  WHITE_1: color.white1,
  WHITE_2: color.white2,
  WHITE_3: color.white3,
  WHITE_4: color.white4,
  WHITE_5: color.white5,
  WHITE_6: color.white6,
  BLUE_1: color.blue1,
  BLUE_2: color.blue2,
  BLUE_3: color.blue3,
  BLUE_4: color.blue4,
  BLUE_5: color.blue5,
  BLUE_6: color.blue6,
  BLUE_7: color.blue7,
  GREEN_1: color.green1,
  GREEN_2: color.green2,
  GREEN_4: color.green4,
  GREEN_6: color.green6,
  GREEN_7: color.green7,
  RED_1: color.red1,
  RED_2: color.red2,
  RED_3: color.red3,
  RED_4: color.red4,
  GREY_1: color.grey1,
  GREY_2: color.grey2,
  GREY_3: color.grey3,
  GREY_4: color.grey4,
  GREY_5: color.grey5,
  GREY_6: color.grey6,
  ORANGE_1: color.orange1,
  ORANGE_2: color.orange2,
  ORANGE_3: color.orange3,
  ORANGE_4: color.orange4,
}
