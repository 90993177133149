import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table"
import BTable from "react-bootstrap/Table"
import { useTranslation } from 'react-i18next'
import moment from "moment"
import { confirmAlert } from 'react-confirm-alert'
import TablePagination from "../TablePagination"
import RaptorButton from "../RaptorButton"
import Icon from '../Icons'
import ConfirmAlertPopup from "../ConfirmAlertPopup"
import CommonInputFilter from "./CommonInputFilter"
import { DATE_FORMAT_1, ID_SELECTOR } from "../../../constant"
import RaptorCheckbox from "../RaptorCheckbox"
import LabelInput from "../LabelInput"
import "./index.scss"

const CommonTable = ({
    customFnIcon = "round-select", mIdSelector = ID_SELECTOR,
    customFnTxt = false, trTitle = "", showActiveRow = false,
    className = '', deleteMsg = '', customFn = false,
    columns, data, onRowClick, selectedRows = [],
    actionBtn = false, editFn = false, deleteFn = false, selectFn = false,
    showMultiCheckbox = false, onCheckboxChange = false, extraCheckBoxCondi = false,
    handleCheckAll = false, checkAll = false, multiIDs = [], showPagination = true,
    deleteIcon = 'trash', childrenColumns = [], childKeyName = false, childDeleteFn = false,
    deleteBtnTxt = '', actionBtnType = 1, childRowClick = false, childPagination = true,
    childActionBtnType = 1
}) => {
    const [activeRow, setActiveRow] = useState(false)
    const [expendRow, setExpendRow] = useState(false)

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
            multiSelect: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return (filterValue !== undefined) && !!filterValue.length
                        ? filterValue.includes(rowValue.toString())
                        : true
                })
            },
            date: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? (String(moment(rowValue).format(DATE_FORMAT_1))).includes(filterValue)
                        : true
                })
            },
            bool: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return (rowValue !== undefined)
                        ? ((((String(filterValue).toLowerCase() === 'yes') && (rowValue === true))) ||
                            (((String(filterValue).toLowerCase()) === 'no') && (rowValue === false)))
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(() => ({ Filter: CommonInputFilter }), [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns, data,
            defaultColumn,
            filterTypes,
            initialState: {
                pageSize: !!showPagination ? 10 : 1000
            }
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    );

    const [isEdit, setIsEdit] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        selectedRows.forEach(index => rows[index].toggleRowSelected(true));
    }, [selectedRows, rows]);

    const handleValueType = cell => {
        switch (cell.column.type) {
            case 'date':
                return !!cell.value ? moment(cell.value).format(DATE_FORMAT_1) : 'N/A'
            case 'bool':
                return !!cell.value ? 'Yes' : 'No'
            case 'undefined':
            case undefined:
            default:
                return cell.render("Cell")
        }
    }

    const memoizeMultiIDs = records => records.map(rec => rec.original[mIdSelector])
    const allMultiIDs = useMemo(() => memoizeMultiIDs(page), [page])

    const [activeRowBtnList, setActiveRowBtnList] = useState(false)
    const wrapperRef = useRef()

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (!!wrapperRef.current) &&
            !wrapperRef.current.contains(event.target)) {
            setActiveRowBtnList(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className={`d-flex f-column ${className} w-100`}>
            <BTable striped bordered hover size="sm" {...getTableProps()}>
                <thead>
                    {// Loop over the header rows
                        headerGroups.map(headerGroup => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {!!showMultiCheckbox && !!handleCheckAll && <th className="center-flex multi-check-th">
                                    <RaptorCheckbox checked={checkAll} onChange={e => handleCheckAll(e.target.checked, allMultiIDs)} />
                                </th>}
                                {// Loop over the headers in each row
                                    headerGroup.headers.map(column => (
                                        // Apply the header cell props
                                        <th {...column.getHeaderProps(!!column.isSortable && column.getSortByToggleProps())}>
                                            <div className="d-flex">
                                                {// Render the header
                                                    !!column.Filter ? column.render("Filter") : (typeof column.Header === "function") ? (column.render("Header")) : t(column.render("Header"))}
                                                {!!column.isSortable && !column.Filter && <img src={require('../../../assets/images/up-down-arrow.svg').default} alt="Sort" className="ml-10" />}
                                            </div>
                                        </th>
                                    ))}
                                {!!actionBtn && <th>{t('table.actions')}</th>}
                            </tr>
                        ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                    {// Loop over the table rows
                        page.map((row, index) => {
                            // Prepare the row for display
                            const childrenTrData = !!row && !!row.original && !!row.original[childKeyName] ? row.original[childKeyName] : []
                            prepareRow(row)

                            const rowClassName = row.isSelected ? "selected" : "";
                            return (
                                <Fragment key={index}>
                                    <tr {...row.getRowProps()} onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveRow(index)
                                        setIsEdit(row.original[ID_SELECTOR])
                                        !!onRowClick && onRowClick(row)
                                    }} className={`${rowClassName} ${(!!showActiveRow && (Number(activeRow) === Number(index))) ? 'active-rw' : ''} ${!!childKeyName ? 'table-in-table' : ''}`} title={trTitle}>
                                        {!!showMultiCheckbox && ((!!extraCheckBoxCondi && !!row.original[extraCheckBoxCondi]) || !extraCheckBoxCondi) ? <td className="multi-check-td">
                                            <div className="center-flex" onClick={e => { e.stopPropagation() }}>
                                                <RaptorCheckbox checked={multiIDs.includes(row.original[mIdSelector])} onChange={e => onCheckboxChange(e, row.original)}></RaptorCheckbox>
                                            </div>
                                            {
                                                !!row && !!row.original && !!row.original[childKeyName] && !!row.original[childKeyName].length &&
                                                <div className={`expend-icon-holder ${childrenColumns.length} center-flex ${expendRow === row.original[ID_SELECTOR] ? 'expended-div' : ''}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setExpendRow(expendRow === row.original[ID_SELECTOR] ? false : row.original[ID_SELECTOR])
                                                    }}
                                                >
                                                    <img src={require('../../../assets/images/caret-black.svg').default} alt={"Click"} />
                                                </div>
                                            }
                                        </td> : (!!showMultiCheckbox ? <td className="multi-check-td"></td> : null)}

                                        {// Loop over the rows cells
                                            row.cells.map(cell => {
                                                // Apply the cell props
                                                return (
                                                    <td {...cell.getCellProps()} className={cell.column.className || ''} title={row.original[t(cell.column.toolTip)]}>
                                                        { // Render the cell contents
                                                            (!!cell.column.inlineEdit && (Number(isEdit) === Number(row.original[ID_SELECTOR]))) ?
                                                                <LabelInput value={cell.value} showLabel={false}
                                                                    onChange={(e) => console.log(e.target.value)}
                                                                    onBlur={() => setIsEdit(false)} /> :
                                                                handleValueType(cell)
                                                        }
                                                    </td>
                                                )
                                            })}
                                        {!!actionBtn && <td className="action-btn-td">
                                            <div className="d-flex">
                                                {(actionBtnType === 2) && <div onClick={e => { e.stopPropagation() }}>
                                                    {!!customFn && <RaptorButton title={t(customFnTxt || 'table.select')} className="action-btn-view" onClick={() => !!customFn && customFn(row)}><Icon name={customFnIcon} /></RaptorButton>}
                                                    {!!selectFn && <RaptorButton title={t('table.view')} className="action-btn-view" onClick={() => !!selectFn && selectFn(row)}><img src={require('./eye.svg').default} alt="View" /></RaptorButton>}
                                                    {!!editFn && <RaptorButton title={t('table.edit')} className="action-btn-edit" onClick={() => !!editFn && editFn(row)}><Icon name="edit" /></RaptorButton>}
                                                    {!!deleteFn && <RaptorButton title={t('table.delete')} className="action-btn-delete" onClick={() => {
                                                        !!deleteFn && confirmAlert({
                                                            customUI: values => <ConfirmAlertPopup {...values} onConfirm={() => deleteFn(row)} message={deleteMsg} />
                                                        })
                                                    }}><Icon name={deleteIcon} /></RaptorButton>}
                                                </div>}
                                                {(actionBtnType === 1) && (!!customFn || !!selectFn || !!editFn || !!deleteFn) && <div className="action-btn-list" ref={wrapperRef}>
                                                    <RaptorButton title={t('table.action')} className="action-btn-edit" onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveRowBtnList(row.original[ID_SELECTOR])
                                                    }}><Icon name="vertical-three-dot" /></RaptorButton>
                                                    <div className={`action-list-holder ${!!activeRowBtnList && (row.original[ID_SELECTOR] === activeRowBtnList) ? 'is-active' : ''}`}
                                                        onClick={e => { e.stopPropagation() }}>
                                                        {!!customFn && <RaptorButton title={t(customFnTxt || 'table.select')} className="action-btn-view" onClick={() => !!customFn && customFn(row)}><Icon name="round-select" /></RaptorButton>}
                                                        {!!selectFn && <RaptorButton title={t('table.view')} className="action-btn-view" onClick={() => !!selectFn && selectFn(row)}><img src={require('./eye.svg').default} alt="View" /></RaptorButton>}
                                                        {!!editFn && <RaptorButton title={t('table.edit')} className="action-btn-edit" onClick={() => !!editFn && editFn(row)}><Icon name="edit" /></RaptorButton>}
                                                        {!!deleteFn && <RaptorButton title={t(deleteBtnTxt || 'table.delete')} onClick={(e) => {
                                                            e.stopPropagation()
                                                            !!deleteFn && confirmAlert({
                                                                customUI: values => <ConfirmAlertPopup {...values} onConfirm={() => deleteFn(row)} message={deleteMsg} />
                                                            })
                                                        }} />}
                                                    </div>
                                                </div>
                                                }

                                            </div>
                                        </td>}
                                    </tr>
                                    {
                                        !!childrenTrData.length && <tr style={{
                                            position: 'relative',
                                            transition: 'all 0.4s ease-in-out',
                                            minHeight: (expendRow === row.original[ID_SELECTOR]) ? 151 : 0,
                                            height: (expendRow === row.original[ID_SELECTOR]) ? 300 : 0,
                                            opacity: (expendRow === row.original[ID_SELECTOR]) ? 1 : 0
                                        }} onClick={() => {
                                            setActiveRow(index)
                                            setIsEdit(row.original[ID_SELECTOR])
                                            !!onRowClick && onRowClick(row)
                                        }} className={`tr-for-dd-content ${rowClassName} ${(!!showActiveRow && (Number(activeRow) === Number(index))) ? 'active-rw' : ''}`} title={trTitle}>

                                            {// Loop over the rows cells
                                                row.cells.map((cell, ind) => {
                                                    if (ind > 0) return false
                                                    // Apply the cell props
                                                    return (<td className="childrenTable" style={{
                                                        position: "absolute", width: '98%',
                                                        background: 'rgba(214, 214, 214, 0.2)',
                                                        overflowY: 'scroll',
                                                        height: '100%'
                                                    }} key={ind}>
                                                        <CommonTable childrenColumns={false} childKeyName={false} columns={childrenColumns}
                                                            data={!!childKeyName && (cell.row.original[childKeyName]) ? cell.row.original[childKeyName] : []}
                                                            onRowClick={(row) => {
                                                                !!childRowClick && childRowClick(row)
                                                            }}
                                                            showPagination={childPagination}
                                                            deleteIcon="trash2"
                                                            actionBtnType={childActionBtnType}
                                                            deleteFn={(info) => !!childDeleteFn && childDeleteFn(info)}
                                                            actionBtn={true} />
                                                    </td>)
                                                })}
                                        </tr>
                                    }
                                </Fragment>
                            )
                        })}
                </tbody>
            </BTable>
            {
                pageOptions.length > 1 && <TablePagination pageCount={pageCount} pageOptions={pageOptions} pageIndex={pageIndex}
                    canNextPage={canNextPage} canPreviousPage={canPreviousPage} pageSize={pageSize} setPageSize={setPageSize}
                    gotoPage={gotoPage} previousPage={previousPage} nextPage={nextPage} recordsOnPage={page.length} total={data.length} />
            }
        </div>
    )
}

export default CommonTable