import Icon from '../../../../components/common/Icons'
import { colorCodes } from '../../../../constant/colors'
import './index.scss'

const TileCard = ({
    count = 0,
    className = '',
    icon = false,
    iconColor = colorCodes.GREEN_7
}) => {
    return (
        <div className={`tile-card-container ${className}`}>
            <div className='icon-count-holder'>
                <div className='icon-holder'>
                    {!!icon && <Icon name={icon} fill={iconColor} />}
                </div>
                <div className='count-holder'>
                    {count}
                </div>
            </div>
        </div>
    )
}

export default TileCard