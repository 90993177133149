import React from 'react'
import { useNavigate } from 'react-router-dom'
import RaptorButton from '../../components/common/RaptorButton'
import { useTranslation } from 'react-i18next'
import "./index.scss"

const NotFound = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <div className="center-flex not-found-container">
            <span>{t("no_route")}</span>
            <RaptorButton
                onClick={() => navigate("/raptor/projects")}
                title={t('button.take_me_home')}
            />
        </div>
    )
}

export default NotFound