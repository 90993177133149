import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import Icon from '../../components/common/Icons'
import { menuList } from '../../constant'
import './index.scss'

export function SideBar() {
    const { t } = useTranslation()
    const location = useLocation()
    const [activeIndex, setActiveIndex] = useState(0)
    const { defaultProjID } = useSelector(state => state.global)

    const getActiveIndex = val => {
        switch (val) {
            case 'projects':
                return 0
            case 'visualize_map':
                return 1
            case 'calibration_files':
                return 2
            default:
                return 0
        }
    }

    useEffect(() => {
        const { pathname } = location
        if (!!pathname) {
            const splittedPath = (pathname.split("/")).filter(e => String(e).trim())
            if (!!splittedPath && !!splittedPath[1]) {
                setActiveIndex(getActiveIndex(splittedPath[1]))
            }
        }
        // eslint-disable-next-line
    }, [location.pathname])

    return (
        <div className='sidebar-container'>
            {
                menuList.map((menu, i) => {
                    return ((menu.id !== '/raptor/visualize_map') || !!defaultProjID) && <div key={i} className={`sidebar-menu-item center-flex ${(i === activeIndex) ? 'active' : ''}`}>
                        <Link to={menu.id}>
                            <Icon name={menu.icon} />
                            <div className='menu-item-title'>{t(menu.title)}</div>
                        </Link></div>
                })
            }
        </div>
    )
}
