import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Trash = ({
    fill = colorCodes.RED_1,
    height = 32,
    width = 32,
    style = {},
    viewBox = '0 0 32 32',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g transform="translate(-439 -281)">
            <circle cx="16" cy="16" r="16" transform="translate(439 281)" fill={colorCodes.RED_2} />
            <g transform="translate(448.362 288.708)">
                <path d="M222.78,154.7a.382.382,0,0,0-.382.382v7.221a.382.382,0,1,0,.764,0v-7.221A.382.382,0,0,0,222.78,154.7Zm0,0" transform="translate(-213.901 -148.792)" fill={fill} />
                <path d="M104.78,154.7a.382.382,0,0,0-.382.382v7.221a.382.382,0,1,0,.764,0v-7.221A.382.382,0,0,0,104.78,154.7Zm0,0" transform="translate(-100.409 -148.792)" fill={fill} />
                <path d="M1.082,4.855v9.413a2.108,2.108,0,0,0,.56,1.454,1.882,1.882,0,0,0,1.365.59h7.229a1.881,1.881,0,0,0,1.365-.59,2.108,2.108,0,0,0,.56-1.454V4.855a1.46,1.46,0,0,0-.374-2.87H9.831V1.508A1.5,1.5,0,0,0,8.318,0H4.926A1.5,1.5,0,0,0,3.412,1.508v.478H1.456a1.46,1.46,0,0,0-.374,2.87Zm9.154,10.693H3.007a1.21,1.21,0,0,1-1.161-1.28V4.889H11.4v9.38a1.21,1.21,0,0,1-1.161,1.28ZM4.177,1.508A.736.736,0,0,1,4.926.763H8.318a.736.736,0,0,1,.749.745v.478H4.177ZM1.456,2.749H11.787a.688.688,0,0,1,0,1.375H1.456a.688.688,0,1,1,0-1.375Zm0,0" transform="translate(0.003 0.002)" fill={fill} />
                <path d="M163.78,154.7a.382.382,0,0,0-.382.382v7.221a.382.382,0,1,0,.764,0v-7.221A.382.382,0,0,0,163.78,154.7Zm0,0" transform="translate(-157.155 -148.792)" fill={fill} />
            </g>
        </g>
    </svg>
)

export default Trash