import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactFlagsSelect from 'react-flags-select'
import { useDispatch, useSelector } from 'react-redux'
import msalObj from '../../../services/MsalService'
import { setLanguage } from '../../../actions/global'
import './index.scss'

export function ProfileSection() {
    const wrapperRef = useRef()
    const dispatch = useDispatch()
    const { lang, userInfo } = useSelector(state => state.global);
    const { t, i18n } = useTranslation()

    const [isActive, setIsActive] = useState(false)

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null) &&
            !wrapperRef.current.contains(event.target)) {
            setIsActive(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        i18n.changeLanguage(lang === 'US' ? 'en' : 'fr');
    }, [i18n, lang])


    const handleLogout = () => {
        msalObj.logoutRedirect()
        localStorage.clear()
    }

    const userName = userInfo.name || "-"
    return (
        <div className='profile-section-container' ref={wrapperRef}>
            <div>
                <ReactFlagsSelect
                    selected={lang}
                    onSelect={code => dispatch(setLanguage(code))}
                    showSelectedLabel={true}
                    showSecondarySelectedLabel={true}
                    showOptionLabel={true}
                    showSecondaryOptionLabel={true}
                    placeholder={"Language"}
                    searchable={false}
                    customLabels={{ "US": { secondary: "English" }, "FR": { secondary: "French" } }}
                    countries={["US", "FR"]}
                    className='language-selector'
                />
            </div>
            <div id="profile-section" className='center-flex' onClick={() => setIsActive(!isActive)}>
                <img src={require('../../../assets/images/avtar.svg').default} alt={userName} />
                <div className='user-name-div' title={userName}>{userName}</div>
                <img src={require('../../../assets/images/caret.svg').default} alt={userName} />
            </div>
            <div className={`profile-menu-item-holder ${isActive ? 'is-active' : ''}`}>
                <div className='profile-menu-item' onClick={() => handleLogout()}>{t("navbar.profile.logout")}</div>
            </div>
        </div>
    )
}
