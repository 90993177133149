import React from 'react'
import { colorCodes } from '../../../constant/colors'

const RoundSquareGrowth = ({
    fill = colorCodes.BLACK_1,
    height = 27,
    width = 27,
    style = {},
    viewBox = '0 0 27 27',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M6.83472 17.334L10.7443 12.254L15.2038 15.7547L19.0298 10.8171" stroke={fill} strokeOpacity="0.5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.4894 6.02118C24.8759 6.02118 25.9999 4.89715 25.9999 3.51059C25.9999 2.12403 24.8759 1 23.4894 1C22.1028 1 20.9788 2.12403 20.9788 3.51059C20.9788 4.89715 22.1028 6.02118 23.4894 6.02118Z" stroke={fill} strokeOpacity="0.5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.8656 2.09705H7.37314C3.43875 2.09705 1 4.88325 1 8.81634V19.3734C1 23.3064 3.39172 26.0809 7.37314 26.0809H18.6068C22.5399 26.0809 24.9799 23.3064 24.9799 19.3734V10.1801" stroke={fill} strokeOpacity="0.5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

)

export default RoundSquareGrowth