import { Fill, Style, Icon, Stroke, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { colorCodes } from "../../constant/colors";

export const defaultColorDot = (color, size) => {
  return new Style({
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: colorCodes.WHITE_1
      }),
      fill: new Fill({ color }),
      points: 3 
    }),
    text: new Text({
      text: size.toString(),
      fill: new Fill({
        color: colorCodes.WHITE_1
      })
    })
  })
}

export const defaultColorSquare = (size) => {
  return new Style({
    image: new Icon({
      src: require('../../assets/images/map/icons/event-cluster.svg').default
    }),
    text: new Text({
      text: size.toString(),
      font: '14px Calibri,sans-serif',
      offsetY: 6,
      fill: new Fill({
        color: colorCodes.BLACK_1
      })
    })
  })
}

export const eventIcon = (icon) => new Style({
  image: new Icon({
    src: icon === 'Bridge' ? require('../../assets/images/map/icons/bridge.svg').default : require('../../assets/images/map/icons/roadwork.svg').default
  })
})