import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../../components/common/Icons'
import LabelSelectBox from '../../../components/common/LabelSelectBox'
import { colorCodes } from '../../../constant/colors'
import BasicStaticsCard from '../../Projects/BasicStaticsCard'
import MeasurmentList from '../../Projects/MeasurmentList'
import { setDefMinMaxObj, setSelectedCalc, setShowEvents, setVisualizeCalculation, setVisualizeParam } from '../../../actions/visualize'
import RaptorCheckbox from '../../../components/common/RaptorCheckbox'
import SpinLoader from '../../../components/common/SpinLoader'
import { medianofArr } from '../../../constant/helpers'
import './index.scss'

const DeflectionTab = ({ mIdSelector }) => {
    const { t } = useTranslation()
    const { projectInfo, calculations } = useSelector(state => state.projects)
    const { visualizeMapInfo, visualizeParam, showEvents, selectedCalc, isProcessing, isProcessing2, visualizeCalcInfo } = useSelector(state => state.visualizes)
    const { globalActiveTab, defaultProjID } = useSelector(state => state.global)
    const [fullView, setFullView] = useState(false)
    const [staticForm, setStaticForm] = useState(false)
    const dispatch = useDispatch()

    const isActive = true

    const calbList = !!projectInfo && !!projectInfo.calibrationFiles ? projectInfo.calibrationFiles : []
    for (var i = 0; i < calbList.length; i++) {
        calbList[i]['fileName'] = (calbList[i]['fileName']).replace('_calibration_ready_for_upload.zip', '')
    }

    const findAndSetCalculation = (_id) => {
        if (!!defaultProjID && !!calculations && !!calculations[defaultProjID] && !!calculations[defaultProjID].length) {
            const resp = calculations[defaultProjID].find(a => a._id === _id)
            if (!!resp) {
                dispatch(setSelectedCalc(resp))

                if (!!visualizeMapInfo && !!visualizeMapInfo.length && !!resp._id) {
                    const filteredCalc = visualizeMapInfo.filter(a => a._id === resp._id)

                    let deflectionObjMinMax = {
                        'D0': { min: 100, max: 1200 },
                        'D100': { min: 90, max: 1100 },
                        'D200': { min: 80, max: 950 },
                        'D300': { min: 70, max: 850 },
                        'D450': { min: 60, max: 650 },
                        'D600': { min: 50, max: 450 },
                        'D900': { min: 30, max: 300 },
                        'D1200': { min: 20, max: 150 },
                        'D1800': { min: 10, max: 50 },
                        'SCI': { min: 50, max: 450 },
                        'BCI': { min: 30, max: 200 },
                        'BDI': { min: 10, max: 80 }
                    }

                    const defParams = {
                        'D0': [],
                        'D100': [],
                        'D200': [],
                        'D300': [],
                        'D450': [],
                        'D600': [],
                        'D900': [],
                        'D1200': [],
                        'D1800': [],
                        'SCI': [],
                        'BCI': [],
                        'BDI': []
                    }


                    if (!!filteredCalc && !!filteredCalc[0]) {
                        for (var i = 0; i < filteredCalc.length; i++) {

                            if (!!filteredCalc[i] &&
                                !!filteredCalc[i]['measurementFileInfo'] &&
                                !!filteredCalc[i]['measurementFileInfo'].length) {

                                for (var j = 0; j < filteredCalc[i]['measurementFileInfo'].length; j++) {
                                    const mFile = filteredCalc[i]['measurementFileInfo']
                                    if (!!mFile[j] && mFile[j]['mapData'] && mFile[j]['mapData'].length) {
                                        for (var k = 0; k < mFile[j]['mapData'].length; k++) {
                                            const mapDataInst = mFile[j]['mapData'][k]

                                            defParams.D0.push(Number(mapDataInst.D0))
                                            defParams.D100.push(Number(mapDataInst.D100))
                                            defParams.D200.push(Number(mapDataInst.D200))
                                            defParams.D300.push(Number(mapDataInst.D300))
                                            defParams.D450.push(Number(mapDataInst.D450))
                                            defParams.D600.push(Number(mapDataInst.D600))
                                            defParams.D900.push(Number(mapDataInst.D900))
                                            defParams.D1200.push(Number(mapDataInst.D1200))
                                            defParams.D1800.push(Number(mapDataInst.D1800))
                                            defParams.SCI.push(Number(mapDataInst.SCI))
                                            defParams.BCI.push(Number(mapDataInst.BCI))
                                            defParams.BDI.push(Number(mapDataInst.BDI))

                                            // deflectionObjMinMax = {
                                            //     ...(!!mapDataInst['D0'] && {
                                            //         'D0': {
                                            //             min: mapDataInst['D0'] < deflectionObjMinMax['D0'].min ? Number(mapDataInst['D0']) : deflectionObjMinMax['D0'].min,
                                            //             max: mapDataInst['D0'] > deflectionObjMinMax['D0'].max ? Number(mapDataInst['D0']) : deflectionObjMinMax['D0'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['D100'] && {
                                            //         'D100': {
                                            //             min: mapDataInst['D100'] < deflectionObjMinMax['D100'].min ? Number(mapDataInst['D100']) : deflectionObjMinMax['D100'].min,
                                            //             max: mapDataInst['D100'] > deflectionObjMinMax['D100'].max ? Number(mapDataInst['D100']) : deflectionObjMinMax['D100'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['D200'] && {
                                            //         'D200': {
                                            //             min: mapDataInst['D200'] < deflectionObjMinMax['D200'].min ? Number(mapDataInst['D200']) : deflectionObjMinMax['D200'].min,
                                            //             max: mapDataInst['D200'] > deflectionObjMinMax['D200'].max ? Number(mapDataInst['D200']) : deflectionObjMinMax['D200'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['D300'] && {
                                            //         'D300': {
                                            //             min: mapDataInst['D300'] < deflectionObjMinMax['D300'].min ? Number(mapDataInst['D300']) : deflectionObjMinMax['D300'].min,
                                            //             max: mapDataInst['D300'] > deflectionObjMinMax['D300'].max ? Number(mapDataInst['D300']) : deflectionObjMinMax['D300'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['D450'] && {
                                            //         'D450': {
                                            //             min: mapDataInst['D450'] < deflectionObjMinMax['D450'].min ? Number(mapDataInst['D450']) : deflectionObjMinMax['D450'].min,
                                            //             max: mapDataInst['D450'] > deflectionObjMinMax['D450'].max ? Number(mapDataInst['D450']) : deflectionObjMinMax['D450'].max,
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['D600'] && {
                                            //         'D600': {
                                            //             min: mapDataInst['D600'] < deflectionObjMinMax['D600'].min ? Number(mapDataInst['D600']) : deflectionObjMinMax['D600'].min,
                                            //             max: mapDataInst['D600'] > deflectionObjMinMax['D600'].max ? Number(mapDataInst['D600']) : deflectionObjMinMax['D600'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['D900'] && {
                                            //         'D900': {
                                            //             min: mapDataInst['D900'] < deflectionObjMinMax['D900'].min ? Number(mapDataInst['D900']) : deflectionObjMinMax['D900'].min,
                                            //             max: mapDataInst['D900'] > deflectionObjMinMax['D900'].max ? Number(mapDataInst['D900']) : deflectionObjMinMax['D900'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['D1200'] && {
                                            //         'D1200': {
                                            //             min: mapDataInst['D1200'] < deflectionObjMinMax['D1200'].min ? Number(mapDataInst['D1200']) : deflectionObjMinMax['D1200'].min,
                                            //             max: mapDataInst['D1200'] > deflectionObjMinMax['D1200'].max ? Number(mapDataInst['D1200']) : deflectionObjMinMax['D1200'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['D1800'] && {
                                            //         'D1800': {
                                            //             min: mapDataInst['D1800'] < deflectionObjMinMax['D1800'].min ? Number(mapDataInst['D1800']) : deflectionObjMinMax['D1800'].min,
                                            //             max: mapDataInst['D1800'] > deflectionObjMinMax['D1800'].max ? Number(mapDataInst['D1800']) : deflectionObjMinMax['D1800'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['SCI'] && {
                                            //         'SCI': {
                                            //             min: mapDataInst['SCI'] < deflectionObjMinMax['SCI'].min ? Number(mapDataInst['SCI']) : deflectionObjMinMax['SCI'].min,
                                            //             max: mapDataInst['SCI'] > deflectionObjMinMax['SCI'].max ? Number(mapDataInst['SCI']) : deflectionObjMinMax['SCI'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['BCI'] && {
                                            //         'BCI': {
                                            //             min: mapDataInst['BCI'] < deflectionObjMinMax['BCI'].min ? Number(mapDataInst['BCI']) : deflectionObjMinMax['BCI'].min,
                                            //             max: mapDataInst['BCI'] > deflectionObjMinMax['BCI'].max ? Number(mapDataInst['BCI']) : deflectionObjMinMax['BCI'].max
                                            //         }
                                            //     }),
                                            //     ...(!!mapDataInst['BDI'] && {
                                            //         'BDI': {
                                            //             min: mapDataInst['BDI'] < deflectionObjMinMax['BDI'].min ? Number(mapDataInst['BDI']) : deflectionObjMinMax['BDI'].min,
                                            //             max: mapDataInst['BDI'] > deflectionObjMinMax['BDI'].max ? Number(mapDataInst['BDI']) : deflectionObjMinMax['BDI'].max
                                            //         }
                                            //     })
                                            // }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    Object.keys(defParams).map((oneKey, i) => {
                        const sortedArr = defParams[oneKey].sort((a, b) => a - b)
                        const percentageRec = Math.round((sortedArr.length * 10) / 100)
                        deflectionObjMinMax[oneKey].min = medianofArr(sortedArr.slice(0, percentageRec))
                        deflectionObjMinMax[oneKey].max = medianofArr(sortedArr.slice(-percentageRec))
                    })
                    dispatch(setDefMinMaxObj(deflectionObjMinMax))
                    dispatch(setVisualizeCalculation(filteredCalc))
                }
                return
            }
        }
        dispatch(setSelectedCalc(false))
    }

    let inputFilesArr = !!selectedCalc && !!selectedCalc.measurementFileInfo ? selectedCalc.measurementFileInfo :
        (!!selectedCalc && !!selectedCalc.inputFiles ? selectedCalc.inputFiles : [])

    return (
        <div className="measurment-tab-container">
            {!isProcessing && !!isProcessing2 && (globalActiveTab === "ip_file") && <SpinLoader />}
            <div className='select-visual-parameter'>
                <LabelSelectBox value={selectedCalc} placeholder={t("visualize_map.analysis_ip_file")}
                    handleOnChange={e => findAndSetCalculation(e.value)}
                    options={!!defaultProjID && !!calculations && !!calculations[defaultProjID] ? calculations[defaultProjID].map(s => ({ label: `${s.calculationName}`, value: s._id })) : []}
                />
            </div>
            <div className='image-holder center-flex'>
                <img src={require('./dummy-image.png')} alt="Road" />
            </div>
            {!!staticForm && <BasicStaticsCard handleClose={() => setStaticForm(false)} data={visualizeCalcInfo} deflectionTab={true} />}
            {!!selectedCalc && <>
                <div className='calculation-info-sec'>
                    <div className='info-title'>{t("visualize_map.form.calc_inputs")}</div>
                    <div className='info-value'>{t("visualize_map.form.repo_length")} <span>{!!selectedCalc.ReportLengthM ? `${selectedCalc.ReportLengthM} M` : '-'}</span></div>
                    <div className='info-value'>{t("visualize_map.form.temp")} <span>{!!selectedCalc.NormalizationTemperatureC ? selectedCalc.NormalizationTemperatureC : '-'}</span></div>
                    <div className='info-value'>{t("visualize_map.form.load")} <span>{!!selectedCalc.NormalizationLoadN ? `${selectedCalc.NormalizationLoadN / 1000}kN` : '-'}</span></div>
                </div>
            </>}
            <div className='tab-btn-holder evenly-flex'>
                <div className='btn-holder center-flex' onClick={() => setStaticForm(true)}>
                    <button className={`tab-icon ${isActive ? 'active' : ''}`}>
                        <Icon fill={isActive ? colorCodes.WHITE_1 : colorCodes.BLUE_1} name="statics" />
                    </button>
                    <div className='btn-title'>{t("button.statistics")}</div>
                </div>
            </div>

            <div className='select-visual-parameter'>
                <LabelSelectBox label={t("visualize_map.form.visual_param")}
                    handleOnChange={e => dispatch(setVisualizeParam(e.value))}
                    value={visualizeParam}
                    options={[
                        { label: 'D0', value: 'D0' },
                        { label: 'D100', value: 'D100' },
                        { label: 'D200', value: 'D200' },
                        { label: 'D300', value: 'D300' },
                        { label: 'D450', value: 'D450' },
                        { label: 'D600', value: 'D600' },
                        { label: 'D900', value: 'D900' },
                        { label: 'D1200', value: 'D1200' },
                        { label: 'D1800', value: 'D1800' },
                        { label: 'SCI', value: 'SCI' },
                        { label: 'BCI', value: 'BCI' },
                        { label: 'BDI', value: 'BDI' }
                    ]} />
            </div>

            <div className="show-events-checkbox-container" onClick={e => { e.stopPropagation() }}>
                <RaptorCheckbox checked={showEvents} onChange={e => dispatch(setShowEvents(e.target.checked))}></RaptorCheckbox>
                <span>{t("visualize_map.show_events")}</span>
            </div>

            <div className='select-visual-hr'></div>
            <div className='select-multi-measur'>
                <div className='select-multi-measur-title'>{t("visualize_map.form.multi_title")}</div>
                <div className={`toogle-full-view-btn ${fullView ? 'active' : ''}`} onClick={() => setFullView(!fullView)}>
                    <Icon name="double-arrow" />
                </div>
            </div>
            <div className='multi-options' id='visualize-table-selector-def-div'>
                <MeasurmentList onlyTable={true} actionBtn={false} fullView={fullView}
                    setGlobalState={true}
                    mIdSelector={mIdSelector}
                    measurmentInstance={{
                        ...projectInfo,
                        inputFiles: inputFilesArr
                    }}
                />
            </div>
        </div>
    )
}

export default DeflectionTab