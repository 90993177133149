import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./index.scss"

const UploadStatus = ({ progress = 0, text = "project.card.uploading", className = "", uniqueSelector = "" }) => {
  const { t } = useTranslation()

  useEffect(() => {
    const elem = document.getElementById(`uploading-progress-bar${uniqueSelector}`);
    elem.style.maxWidth = (progress === 60 ? 100 : progress) + "%";
    const valElem = document.getElementById(`uploading-progress-val${uniqueSelector}`);
    if (!!valElem) {
      valElem.innerHTML = progress + "%";
    }
    // eslint-disable-next-line 
  }, [progress])

  return (
    <div className="upload-status-card-container">
      <div className={`progress-info-holder ${className}`}>
        <div className="uploading-progress-bar-holder">
          <div id={`uploading-progress-holder${uniqueSelector}`}><div className={className} id={`uploading-progress-bar${uniqueSelector}`}></div></div>
          <div id={`uploading-progress-val${uniqueSelector}`}>0%</div>
        </div>
        <div className="uploading-label">{t(text)}</div>
      </div>
    </div>
  )
}

export default UploadStatus