import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Key = ({
    fill = colorCodes.GREY_2,
    height = 24,
    width = 24,
    style = {},
    viewBox = '0 0 24 24',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <path fill={fill} d="M7.53 22.02c-1.65 0-3.3-.62-4.56-1.87-2.56-2.65-2.49-6.77.09-9.26a6.463 6.463 0 0 1 8.27-.63l8.97-8.97a.996.996 0 1 1 1.41 1.41L20.41 4l2.29 2.29c.39.39.39 1.02 0 1.41l-3.5 3.5a.996.996 0 0 1-1.41 0L15.5 8.91l-2.75 2.75c1.87 2.53 1.68 6.12-.6 8.43a6.449 6.449 0 0 1-4.62 1.93zm.04-10.96c-1.13 0-2.25.42-3.13 1.27a4.51 4.51 0 0 0-.11 6.36c1.8 1.78 4.66 1.76 6.4-.01 1.74-1.76 1.72-4.62-.04-6.36-.87-.84-2-1.26-3.12-1.26zm9.34-3.56l1.59 1.59L20.59 7 19 5.41 16.91 7.5z" />
    </svg>
)

export default Key