import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import queryString from "query-string"
import { fetchProjectInfo } from "../../../actions/project"
import Icon from "../../../components/common/Icons"
import SpinLoader from "../../../components/common/SpinLoader"
import Tabs from "../../../components/common/Tabs"
import CalculationList from "../CalculationList"
import MeasurmentList from "../MeasurmentList"
import { CalibrationList } from "../../CalibrationFiles/CalibrationList"
import SpinLoader2 from "../../../components/common/SpinLoader2"
import "./index.scss"

const ViewProject = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { search } = useLocation()
  const searchParam = queryString.parse(search)
  const projectDetails = useSelector(state => state.projects)
  const projectInfo = projectDetails.projectInfo

  useEffect(() => {
    if (!!id) {
      dispatch(fetchProjectInfo(id))
    }

    // eslint-disable-next-line
  }, [id])

  const searchFlag = !!searchParam && !!searchParam.flagMsg ? searchParam.flagMsg : false
  const calcId = !!searchParam && !!searchParam.calcId ? searchParam.calcId : false

  useEffect(() => {
    if (!!searchFlag) {
      setTimeout(() => {
        navigate(`/raptor/projects/view/${id}`)
      }, 3000)
    }
    // eslint-disable-next-line
  }, [searchFlag])

  const titleVal = "calculations.dialog.background_upld_title"
  const subTitleVal = "calculations.dialog.background_upld_desc"
  return (
    <div className="prjct-map-and-form-holder">
      {(!!projectDetails.isProcessing) && <SpinLoader />}
      {!!searchFlag && <SpinLoader2 showProgress={false} title={titleVal} subTitle={subTitleVal} />}
      <div className="d-flex">
        <div className="project-view-form-container d-flex">
          <div className="title-and-back-btn">
            <img src={require('../../../assets/images/back.svg').default} alt="Back" onClick={() => navigate(`/raptor/projects`)} />
            <div className="title-value">{!!projectInfo && !!projectInfo.projectName ? projectInfo.projectName : '-'}</div>
            <Icon name="pencil" onClick={() => navigate(`/raptor/projects/edit/${id}`)} />
          </div>
          <div className="page-desc-sec">{!!projectInfo && !!projectInfo.client ? projectInfo.client : '-'}</div>
        </div>
      </div>

      <div className="project-view-section">
        <div className="left-section-holder">
          <Tabs tabList={[
            { title: 'project.measurement_files', id: 'm_files', icon: 'square-growth', component: <MeasurmentList /> },
            { title: 'project.calb_files', id: 'cal_files', icon: 'wallet', component: <CalibrationList /> },
            { title: 'project.calculation', id: 'c_files', icon: 'pie-slice', component: <CalculationList /> }
          ]} activeTabVal={!!calcId ? 'c_files' : 'm_files'} />
        </div>
      </div>
    </div>
  )
}

export default ViewProject