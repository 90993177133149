import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Download2 = ({
    fill = colorCodes.BLUE_1,
    height = 36,
    width = 39,
    style = {},
    viewBox = '0 0 39 36',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M20 25V2" stroke={fill} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25 19L19.5 25L14 19" stroke={fill} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28.2651 11H30.0303C30.9457 11 31.8522 11.1791 32.6979 11.527C33.5436 11.8748 34.3121 12.3848 34.9593 13.0276C35.6065 13.6704 36.1199 14.4335 36.47 15.2733C36.8202 16.1131 37.0002 17.0132 37 17.9222V27.0966C37 28.9275 36.2675 30.6834 34.9636 31.978C33.6597 33.2727 31.8913 34 30.0473 34H8.97162C7.12263 34 5.34937 33.2707 4.04194 31.9725C2.73451 30.6744 2 28.9137 2 27.0778V17.9015C2.0005 16.0709 2.73324 14.3155 4.03707 13.0213C5.3409 11.7271 7.10906 11 8.9527 11H10.7349" stroke={fill} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default Download2