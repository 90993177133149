import Icon from "../Icons"
import "./index.scss"

const SuccessDialog = ({ message = "" }) => (
    <div className="success-dialog-container">
        <Icon name="round-check" />
        <span>{message}</span>
    </div>
)

export default SuccessDialog