import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fromLonLat } from "ol/proj";
import * as olSource from "ol/source";
import * as ol from "ol";
import { Point } from 'ol/geom';
import { setMapZoom } from "../../actions/global";
import { Map } from "../../common/Map/Map";
import Layers from "../../common/Layers/Layers";
import TileLayer from "../../common/Layers/TileLayer";
import OverlayDiv from "../../common/Overlay/OverlayDiv";
import { setCheckedMIds } from "../../actions/visualize";
import { ClusterLayer } from "../ClusterLayer";
import './index.scss';

export function Home() {
  const { mapLatLong, mapZoom, globalActiveTab } = useSelector(state => state.global);
  const { visualizeMapInfo, visualizeMeasurInfo, visualizeCalcInfo, showEvents } = useSelector(state => state.visualizes);
  const [clusterArr, setClusterArr] = useState([])

  useEffect(() => {
    const clusterObj = []

    if ((!!visualizeMeasurInfo && !!visualizeMeasurInfo.length) &&
      (globalActiveTab === "measur")) {
      let eventFeatures = []
      for (var l = 0; l < visualizeMeasurInfo.length; l++) {
        let clusterFeatures = []
        const mIdRec = visualizeMeasurInfo[l]['measurementId']

        if (!!visualizeMeasurInfo[l].rawData) {
          const measurRawData = visualizeMeasurInfo[l].rawData
          for (var m = 0; m < measurRawData.length; m++) {
            const featureProp = new ol.Feature({
              geometry: new Point(fromLonLat([
                Number(measurRawData[m]['Longitude']), Number(measurRawData[m]['Latitude'])
              ])),
              properties: {
                ...measurRawData[m],
                mId: visualizeMeasurInfo[l]['measurementId']
              }
            })
            if (!!measurRawData[m]['Events']) {
              if (!!showEvents) {
                eventFeatures.push(featureProp)
              }
            } else {
              clusterFeatures.push(featureProp)
            }
          }
        }
        clusterObj.push({ [mIdRec]: clusterFeatures })
      }
      clusterObj.push({ 'eventFeatures': eventFeatures })
      setClusterArr(clusterObj)
    } else {
      const infoArr = (globalActiveTab === 'ip_file') ? visualizeCalcInfo : visualizeMapInfo
      for (var i = 0; i < infoArr.length; i++) {

        if (!!infoArr[i] &&
          !!infoArr[i]['measurementFileInfo'] &&
          !!infoArr[i]['measurementFileInfo'].length) {

          let eventFeatures = []
          for (var j = 0; j < infoArr[i]['measurementFileInfo'].length; j++) {
            let clusterFeatures = []
            const mFile = infoArr[i]['measurementFileInfo']

            const mIdRec = !!mFile && !!mFile[j] && !!mFile[j]['measurementFileIds'] ? mFile[j]['measurementFileIds'] : false
            if (!!mFile[j] && mFile[j]['mapData'] && mFile[j]['mapData'].length) {

              for (var k = 0; k < mFile[j]['mapData'].length; k++) {
                const mapDataInst = mFile[j]['mapData']
                const featureInst = new ol.Feature({
                  geometry: new Point(fromLonLat([
                    Number(mapDataInst[k]['Longitude']), Number(mapDataInst[k]['Latitude'])
                  ])),
                  properties: {
                    ...mapDataInst[k],
                    mId: mFile[j]['measurementFileIds']
                  }
                })
                if (!!mapDataInst[k]['Events']) {
                  if (!!showEvents) {
                    eventFeatures.push(featureInst)
                  }
                } else {
                  clusterFeatures.push(featureInst)
                }
              }
              if (!!mIdRec) {
                clusterObj.push({ [mIdRec]: clusterFeatures })
              }
            }
          }
          clusterObj.push({ 'eventFeatures': eventFeatures })
        }
      }
      setClusterArr(clusterObj)
    }

  }, [visualizeMapInfo, visualizeMeasurInfo, visualizeCalcInfo, globalActiveTab, showEvents])

  const [mapCenter, setMapCenter] = useState(fromLonLat(mapLatLong))

  const dispatch = useDispatch()

  useEffect(() => {

    if (!!clusterArr && !!clusterArr[0]) {
      const arrInst = Object.values(clusterArr[0])[0]
      if (!!arrInst && !!arrInst[0]
        && !!arrInst[0]['values_']
        && !!arrInst[0]['values_']['geometry']
        && !!arrInst[0]['values_']['geometry']['flatCoordinates']) {
        setMapCenter(arrInst[0]['values_']['geometry']['flatCoordinates'])
        dispatch(setMapZoom(16))
      }
    }

    // eslint-disable-next-line
  }, [clusterArr])

  useEffect(() => {
    dispatch(setCheckedMIds([]))
    // eslint-disable-next-line
  }, [globalActiveTab])

  useEffect(() => {
    if (globalActiveTab === "ip_file" && !visualizeCalcInfo.length) {
      setClusterArr([])
    }
    // eslint-disable-next-line
  }, [globalActiveTab])


  return (
    !!mapLatLong && !!mapZoom &&
    <div className="home-container ">
      <Map center={mapCenter} zoom={mapZoom}>
        <Layers>
          <TileLayer source={new olSource.OSM()} zIndex={0} />
          <OverlayDiv />
          {
            clusterArr.map((c, i) => <ClusterLayer key={i} features={c[Object.keys(c)]} keyName={Object.keys(c)[0]} />)
          }
        </Layers>
      </Map>
    </div>
  )
}
