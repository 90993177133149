import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BrowserAuthError } from '@azure/msal-browser';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import B2CConfig from './services/B2CConfig';
import msalObj from './services/MsalService';
import RaptorContext from "./context/RaptorContext";
import LoggedInLayout from "./components/common/LoggedInLayout";
import Welcome from "./views/welcome";
import SpinLoader from "./components/common/SpinLoader";
import PrivateRoute from "./components/PrivateRoute";
import { setUserInfo } from "./actions/global";
import 'bootstrap/dist/css/bootstrap.min.css'
import About from "./views/About"
import NotFound from "./views/NotFound";
import Terms from "./views/Terms";
import "./App.scss";

function App() {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [map, setMap] = useState(null);
  const { isProcess, userInfo } = useSelector(state => state.global)


  const checkBackButton = (e) => {
    e.preventDefault()

    if (!!e && !!e.target && !!e.target.location && !!e.target.location.pathname &&
      (e.target.location.pathname === '/raptor/projects') &&
      (!!e.target.location.search && ((e.target.location.search).includes('flagMsg=1')))) {

      window.location = `${e.target.location.pathname + (e.target.location.search).replace('flagMsg=1', '')}`
      return false
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', checkBackButton)
    return () => {
      window.removeEventListener('popstate', checkBackButton)
    }
    // eslint-disable-next-line
  }, [])


  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const userDetail = msalObj.getActiveAccount()
  useEffect(() => {
    if (!Object.keys(userInfo).length && !!userDetail && !!userDetail.idTokenClaims && !!userDetail.idTokenClaims.exp) {
      dispatch(setUserInfo({ name: userDetail.idTokenClaims.name || '' }))
      if ((new Date()).getTime() > (new Date(userDetail.idTokenClaims.exp * 1000)).getTime()) {
        setIsLoggedIn(false)
      } else {
        setIsLoggedIn(true)
      }
    }
    // eslint-disable-next-line
  }, [userDetail])

  const login = async () => {
    try {
      await msalObj["browserStorage"].clear()
      const loginInfo = await msalObj.loginPopup({
        scopes: ['openid', ...B2CConfig.apiScopes],
      });

      await msalObj.setActiveAccount(loginInfo.account);

      setIsLoggedIn(true);
    } catch (err) {
      if (
        err instanceof BrowserAuthError &&
        err.errorCode === 'user_cancelled'
      ) {
        // Do nothing
      } else {
        console.log("err ", err)
        console.error(err);
      }
    }
  }

  return (
    <RaptorContext.Provider value={{ map, setMap }}>
      {!!isProcess && <SpinLoader />}
      <Router>
        <Routes>
          <Route path={"/"} element={<Welcome loginCallBack={login} isLoggedIn={isLoggedIn} />} />
          <Route path={"/welcome"} element={<Welcome loginCallBack={login} isLoggedIn={isLoggedIn} />} />
          <Route path={"/about"} element={<About />} />
          <Route path={"/terms"} element={<Terms />} />
          <Route path="/raptor/*" element={<PrivateRoute isLoggedIn={isLoggedIn}>
            <LoggedInLayout />
          </PrivateRoute>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <CookieConsent
          buttonText="OK"
          cookieName="raptor-cookie-consent"
          expires={150}
        >
          {t("cookie_consent")}
        </CookieConsent>
      </Router>
    </RaptorContext.Provider>
  );
}

export default App;
