import { useTranslation } from "react-i18next"
import "./index.scss"

const LabelTextArea = ({
    id = Math.random(), label = 'Label', className = '', rows = 5,
    errMsg = '',
    value, onClick, onChange, required = false, style = {},
    placeholder = '', readOnly = false, disabled = false
}) => {
    const { t } = useTranslation()
    return (
        <label htmlFor={id} className={`raptor-label-textarea  ${className}`}
            onClick={(e) => {
                !!onClick && onClick(e)
            }}>{label}
            <textarea id={id} value={value} rows={rows} style={style} readOnly={readOnly} disabled={disabled}
                onChange={onChange} required={required} placeholder={placeholder} />
            {!!errMsg && <span className="err-msg-span">{t(errMsg)}</span>}
        </label>
    )
}

export default LabelTextArea