import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Warning = ({
    fill = colorCodes.BLACK_1,
    height = 33,
    width = 33,
    style = {},
    viewBox = '0 0 33 33',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g clipPath="url(#clip0_293_512)">
            <path d="M32.7704 30.8468L17.7923 0.823408C17.094 -0.344764 15.6439 -0.0560779 15.2747 0.823408L0.155547 30.9878C-0.381544 32.0351 0.578506 32.9482 1.41771 32.9482H31.6022C31.6022 32.9482 31.6224 32.9482 31.6559 32.9482C32.7301 32.9482 33.3679 31.7196 32.7704 30.8468ZM3.65335 30.2157L16.5301 4.4622L29.3397 30.2224H3.65335V30.2157Z" fill={fill} />
            <path d="M15.1337 13.8411V20.8435C15.1337 21.6155 15.7647 22.2466 16.5368 22.2466C17.3089 22.2466 17.8661 21.6894 17.94 20.9173V13.8411C17.94 13.0691 17.3089 12.438 16.5368 12.438C15.7647 12.438 15.1337 13.0691 15.1337 13.8411Z" fill={fill} />
            <path d="M16.5301 27.4293C17.2902 27.4293 17.9064 26.8132 17.9064 26.0531C17.9064 25.2929 17.2902 24.6768 16.5301 24.6768C15.77 24.6768 15.1538 25.2929 15.1538 26.0531C15.1538 26.8132 15.77 27.4293 16.5301 27.4293Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_293_512">
                <rect width={width} height={height} fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default Warning