import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Calendar = ({
    fill = colorCodes.GREY_5,
    height = '24.222',
    width = '22',
    style = {},
    viewBox = '0 0 22 24.222',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g transform="translate(-3.5 -2)">
            <path d="M6.722,6H22.278A2.222,2.222,0,0,1,24.5,8.222V23.778A2.222,2.222,0,0,1,22.278,26H6.722A2.222,2.222,0,0,1,4.5,23.778V8.222A2.222,2.222,0,0,1,6.722,6Z" transform="translate(0 -0.778)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path d="M24,3V7.444" transform="translate(-5.056 0)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path d="M12,3V7.444" transform="translate(-1.944 0)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path d="M4.5,15h20" transform="translate(0 -3.111)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
    </svg>
)

export default Calendar