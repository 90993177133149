import React from 'react';
import ReactDOM from "react-dom/client";
import { ToastContainer } from 'react-toastify';
import store from "./common/Store";
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ToastContainer autoClose={2000} closeButton={null} icon={false}
      className='waywize-toast-container' toastClassName='waywize-toast-content'
      position='top-center' pauseOnHover={false} hideProgressBar={true} />
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
