import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Form } from "react-final-form"
import ProjectForm from "../ProjectForm"
import { editProject, fetchProjectInfo } from "../../../actions/project"
import { toast } from "react-toastify"
import SuccessDialog from "../../../components/common/SuccessDialog"

const EditProject = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { projectInfo } = useSelector(state => state.projects)
  const { id } = useParams()

  useEffect(() => {
    if (!!id) {
      dispatch(fetchProjectInfo(id))
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = async (data) => {
    const resp = await dispatch(editProject(id, data))
    if (!!resp) {
      toast.success(<SuccessDialog message={t("message.success.updated_success")} />)
    }
    navigate("/raptor/projects")
  }

  return (
    <Form
      subscription={{ submitting: true, pristine: true }}
      destroyOnUmount={false}
      enableReinitialize={true}
      forceUnregisterOnUnmount={false}
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={projectInfo}
      FormState
      render={({
        handleSubmit, submitting, pristine, form
      }) => {
        return (
          <ProjectForm
            isEdit={true}
            id={id}
            pristine={pristine}
            submitting={submitting}
            handleSubmit={handleSubmit}
            resetFn={form.reset}
          />
        )
      }
      } />
  )
}

export default EditProject