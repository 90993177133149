import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Pencil = ({
    fill = colorCodes.BLACK_1,
    height = 35,
    width = 35,
    style = {},
    viewBox = '0 0 35 35',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g>
            <path d="M26.8538 2.91699C26.4805 2.91699 26.1071 3.05986 25.8228 3.34424L22.9061 6.26091L20.8439 8.32308L4.375 24.792V30.6253H10.2083L31.6561 9.17757C32.2263 8.60736 32.2263 7.68415 31.6561 7.1154L27.8849 3.34424C27.6006 3.05986 27.2272 2.91699 26.8538 2.91699ZM26.8538 6.4375L28.5628 8.14648L26.6772 10.0321L24.9683 8.32308L26.8538 6.4375ZM22.9061 10.3853L24.6151 12.0942L9.00065 27.7087H7.29167V25.9997L22.9061 10.3853Z" fill={fill} fillOpacity="0.7" />
        </g>
        <defs>
            <clipPath>
                <rect width={width} height={height} fill="white" />
            </clipPath>
        </defs>
    </svg >
)

export default Pencil