import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import SpinLoader from '../../components/common/SpinLoader';
import { ProjectList } from './ProjectList';
import CreateProject from './CreateProject';
import ViewProject from './ViewProject';
import EditProject from './EditProject';
import AddMeasurment from './AddMeasurment';
import './index.scss'

export function Projects() {

  const { isProcessing } = useSelector(state => state.projects)

  return (
    <div className={`projects-container`}>
      {!!isProcessing && <SpinLoader />}
      <Routes>
        <Route path={""} element={<ProjectList />} />
        <Route path="create" element={<CreateProject />} />
        <Route path="edit/:id" element={<EditProject />} />
        <Route path="view/:id" element={<ViewProject />} />
        <Route path="add-measurment/:id" element={<AddMeasurment />} />
      </Routes>
    </div>
  )
}
