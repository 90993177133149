import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { formatBytes } from "../../../constant/helpers"
import "./index.scss"

const FileProgressCard = ({
  progressBarSelector = 'uploading-progress-bar-selector',
  progressValSelector = 'uploading-progress-val-selector',
  progressObj = {
    key1: 'uploading-progress-bar-selector',
    key2: 'uploading-progress-val-selector',
    progress: 0,
    completed: false,
  },
  fileInfo = {}, showProgress = true, onCrossClick = false
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const elem = document.getElementById(`uploading-progress-bar-div ${progressObj.key1}`);
    if (!!elem) {
      elem.style.maxWidth = progressObj.progress + "%";
    }
    const valElem = document.getElementById(`uploading-progress-val-div ${progressObj.key2}`);
    if (!!valElem) {
      valElem.innerHTML = progressObj.progress + "%";
    }

  // eslint-disable-next-line
  }, [progressObj.progress])

  return (
    <div className="file-progress-info-holder">
      <div className="progress-bar-title">{fileInfo.name}<span>({formatBytes(fileInfo.size)})</span>{!!onCrossClick && <span className="dlt-fl" onClick={() => onCrossClick()}>X</span>}</div>
      {
        !!showProgress && (progressObj.progress > 0) &&
        <div className="uploading-progress-bar-holder">
          <div id="uploading-progress-holder">
            <div id={`uploading-progress-bar-div ${progressBarSelector}`}></div>
            <div id={`uploading-progress-val-div ${progressValSelector}`}>0%</div>
          </div>
          <div className="uploading-label">{t(progressObj.progress < 100 ? "project.card.uploading" : "project.card.file_uploaded_success")}</div>
        </div>
      }
    </div>
  )
}

export default FileProgressCard