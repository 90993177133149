import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Report = ({
    fill = colorCodes.BLUE_4,
    height = '25.266',
    width = '20.553',
    style = {},
    viewBox = '0 0 20.553 25.266',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g id="Report" transform="translate(-3.15 -0.15)">
            <path id="Path_27" d="M8.713,1,4,5.713V24.566H22.853V1Z" fill="none" stroke={fill} strokeLinejoin="round" strokeWidth="1.7" />
            <path id="Path_28" d="M8.713,1V5.713H4" fill="none" stroke={fill} strokeLinejoin="round" strokeWidth="1.7" />
            <path id="Path_29" d="M9,12V23H20.783" transform="translate(-1.072 -2.359)" fill="none" stroke={fill} strokeLinejoin="round" strokeWidth="1.7" />
            <path id="Path_30" d="M9,24.07l5.5-5.5,2.357,2.357L20.783,17" transform="translate(-1.072 -3.432)" fill="none" stroke={fill} strokeLinejoin="round" strokeWidth="1.7" />
        </g>
    </svg>
)

export default Report