import React from 'react'
import { colorCodes } from '../../../constant/colors'

const RoundSelect = ({
    fill = colorCodes.BLUE_3,
    height = '32',
    width = '32',
    style = {},
    viewBox = '0 0 32 32',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g transform="translate(-473 -271)">
            <circle cx="16" cy="16" r="16" transform="translate(473 271)" fill="#cff" />
            <path d="M14.235,3.375H9.612A1.547,1.547,0,0,0,8.045,4.9v.329h-.29A1.547,1.547,0,0,0,6.188,6.76V17.9A1.58,1.58,0,0,0,7.754,19.47H15.8A1.547,1.547,0,0,0,17.33,17.9v-.29h.329a1.547,1.547,0,0,0,1.528-1.567V8.327Zm0,1.726,3.227,3.227H14.235Zm1.857,12.8a.312.312,0,0,1-.29.329H7.754a.345.345,0,0,1-.329-.329V6.76a.312.312,0,0,1,.329-.29h.29v9.885A1.1,1.1,0,0,0,9.3,17.613h6.79Zm1.857-1.857a.312.312,0,0,1-.29.329H9.612a.345.345,0,0,1-.329-.329V4.9a.312.312,0,0,1,.329-.29H13V9.565h4.952Z" transform="translate(476.313 275.125)" fill="teal" stroke="#cff" strokeWidth="0.3" />
        </g>
    </svg>
)

export default RoundSelect