import React from 'react'

const Trash2 = ({
    fill = "none",
    height = 23,
    width = 21,
    style = {},
    viewBox = '0 0 21 23',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill={fill}>
        <path d="M18.4496 8.52539C18.4496 8.52539 17.8412 16.0714 17.4883 19.25C17.4792 19.5848 17.4031 19.9145 17.2645 20.2194C17.126 20.5243 16.9277 20.7984 16.6814 21.0255C16.4351 21.2525 16.1459 21.4279 15.8307 21.5413C15.5155 21.6548 15.1808 21.7039 14.8464 21.6858C11.9232 21.7384 8.99667 21.7418 6.07463 21.6802C5.74672 21.6914 5.41984 21.6374 5.11293 21.5214C4.80602 21.4054 4.52517 21.2297 4.28664 21.0044C4.04812 20.7791 3.85665 20.5087 3.72334 20.2089C3.59003 19.9091 3.51752 19.5859 3.51 19.2578C3.15483 16.0512 2.5498 8.52539 2.5498 8.52539" stroke="#200E32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20 4.90918H1" stroke="#200E32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.3392 4.90914C15.9127 4.90893 15.4994 4.76104 15.1695 4.49062C14.8397 4.2202 14.6136 3.84392 14.5297 3.42571L14.2575 2.06329C14.1758 1.75817 13.9957 1.48855 13.7451 1.29629C13.4945 1.10403 13.1874 0.999881 12.8715 1.00001H8.13442C7.8176 0.998644 7.50924 1.10222 7.2575 1.29458C7.00575 1.48694 6.82479 1.75724 6.74286 2.06329L6.4706 3.42571C6.38675 3.84392 6.16069 4.2202 5.83083 4.49062C5.50097 4.76104 5.08767 4.90893 4.66113 4.90914" stroke="#200E32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg >
)

export default Trash2