import { useContext, useEffect } from "react";
import RaptorContext from "../../context/RaptorContext";
import { Vector as VectorLayer } from 'ol/layer.js';
import { boundingExtent } from 'ol/extent.js';

const VectorTileLayer = ({ source, style }) => {
	const { map } = useContext(RaptorContext);

	useEffect(() => {
		if (!map) return;

		let vectorTileLayer = new VectorLayer({
			source,
			style
		});

		map.addLayer(vectorTileLayer);

		map.on('click', (e) => {
			vectorTileLayer.getFeatures(e.pixel).then((clickedFeatures) => {
				if (clickedFeatures.length) {
					// Get clustered Coordinates
					const features = clickedFeatures[0].get('features')
					if (features.length > 1) {
						const extent = boundingExtent(features.map((r) => r.getGeometry().getCoordinates()))
						map.getView().fit(extent, { duration: 1000, padding: [50, 50, 50, 50] })
					}
				}
			})
		})

		return () => {
			if (map) {
				map.removeLayer(vectorTileLayer);
			}
		};
		// eslint-disable-next-line
	}, [map, style]);

	return null;
};

export default VectorTileLayer;
