import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Statics = ({
    fill = colorCodes.BLUE_1,
    height = 22,
    width = 22,
    style = {},
    viewBox = '0 0 22 22',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M6.37109 9.20117V16.0612" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.0381 5.91992V16.0629" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.6279 12.8262V16.0612" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.686 1H6.314C3.048 1 1 3.312 1 6.585V15.415C1 18.688 3.038 21 6.314 21H15.685C18.962 21 21 18.688 21 15.415V6.585C21 3.312 18.962 1 15.686 1Z" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default Statics