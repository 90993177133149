import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomPopUp from '../../../../components/common/CustomPopUp';
import Icon from '../../../../components/common/Icons';
import { colorCodes } from '../../../../constant/colors';
import DeflectionChart from '../DeflectionChart';
import { setCalcGraphInfo } from '../../../../actions/project';
import SpinLoader from '../../../../components/common/SpinLoader';

const DeflectionChartSection = ({
  measurName = "", calcName = "", handleClose, t,
  calcGraphInfo, isProcessing = false
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(setCalcGraphInfo({}))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <CustomPopUp handleClose={() => handleClose()} className="deflection-chart-popup" title={measurName}
      subTitle={calcName}>
      <div className="deflection-chart-holder">
        {!!isProcessing && <SpinLoader />}
        <DeflectionChart gData={calcGraphInfo.data} options={{
          plugins: {
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true
                },
                mode: 'xy',
                drag: {
                  enabled: true,
                  borderColor: 'rgb(54, 162, 235)',
                  borderWidth: 1,
                  backgroundColor: 'rgba(54, 162, 235, 0.3)'
                }
              },
              pan: {
                enabled: true,
                mode: 'xy',
                modifierKey: 'ctrl'
              },
            },
            legend: {
              position: "left"
            },
            title: {
              display: true,
              text: 'Symmetric RDIs',
              color: colorCodes.BLACK_1,
              font: {
                size: 25,
              },
            },
          }
        }} />
      </div>
      <div className='download-chart-btn-holder'>
        <a href={calcGraphInfo.url} target="#" className={!!calcGraphInfo && !!calcGraphInfo.url ? "" : "disabled-anchor"}>
          <Icon name={"download2"} className="download-chart-btn" />
        </a>
      </div>
    </CustomPopUp>
  )
}

export default DeflectionChartSection