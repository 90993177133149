import common from '../assets/scss/common.module.scss'
export const DATE_FORMAT = 'YYYY-DD-MM'
export const DATE_FORMAT_1 = 'DD-MM-YYYY'
export const DATE_FORMAT_2 = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT_1 = 'DD-MM-YYYY HH:mm:ss'
export const NORWAY_CORDI = ["8.4689", "60.4720"]

export const menuList = [
    { id: '/raptor/projects', title: 'sidebar.menu.all_projects', icon: 'folder' },
    { id: '/raptor/visualize_map', title: 'sidebar.menu.visualize_map', icon: 'location' }
]

export const ZOOM_LEVEL = common.zoomLevel

export const cordiList = {
    'sweden': [18.6435, 60.1282],
    'norway': [8.4689, 60.4720],
    'denmark': [9.5018, 56.2639]
}

export const validInputFileExt = [
    'application/x-zip',
    'application/x-zip-compressed'
]

export const ID_SELECTOR = '_id'