import React from 'react'
import { colorCodes } from '../../../constant/colors'

const PlusFile = ({
    fill = colorCodes.BLUE_1,
    height = 29,
    width = 25,
    style = {},
    viewBox = '0 0 25 29',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M16.5081 1.01762H6.67075C5.19193 1.01182 3.76978 1.58267 2.70967 2.6076C1.64957 3.63254 1.03603 5.02983 1.00082 6.49943V22.448C0.98799 23.1876 1.12655 23.9222 1.40807 24.6069C1.6896 25.2917 2.10821 25.9125 2.6385 26.4315C3.16878 26.9505 3.79966 27.3569 4.49282 27.6261C5.18599 27.8953 5.92696 28.0216 6.67075 27.9974H18.4839C19.9536 27.9823 21.3579 27.3907 22.391 26.3514C23.4241 25.312 24.0024 23.9091 24 22.448V8.77654L16.5081 1.01762Z" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.1211 1V5.27637C16.1211 6.27879 16.5211 7.24028 17.2333 7.94992C17.9455 8.65956 18.9119 9.05941 19.9203 9.06175C21.8665 9.06175 23.8585 9.06175 23.993 9.06175" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.8528 15.9414H8.60645" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.2314 19.5431V12.3398" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default PlusFile