import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import RaptorButton from "../../components/common/RaptorButton"
import CopyRight from "../CopyRight"
import "./index.scss"

const Welcome = ({ loginCallBack, isLoggedIn }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/raptor")
    }
    // eslint-disable-next-line
  }, [isLoggedIn])

  return (
    <div id="app">
      <div className="d-flex f-column welcome-container">
        <div className="p-2 raptor-logo-holder">
          <img src={require("../../assets/images/raptor-logo.svg").default} className="raptor-logo" alt="RAPTOR" />
        </div>
        <div className="welcome-info-holder">
          <h1 className="welcome-h1">Welcome to <span>RAPTOR</span></h1>
          <div className="welcome-msg">Application for Raptor data analysis</div>
          {
            !!isLoggedIn ? <Link className="log-in-button" to="/raptor">Enter Raptor</Link>
              : <RaptorButton onClick={loginCallBack} title="Login" className="welcome-login-btn" />
          }
        </div>
        <CopyRight />
      </div>
    </div>
  )
}

export default Welcome