const authorityDomain = process.env.REACT_APP_B2C_AUTHORITY_DOMAIN;
const tenantId = process.env.REACT_APP_B2C_TENANT_ID;

const config = {
    appId: process.env.REACT_APP_B2C_APP_ID,
    graphScopes: ["https://graph.microsoft.com/User.Read"],
    apiScopes: [process.env.REACT_APP_B2C_API_SCOPE],
    b2cPolicies: {
        names: {
            signUpSignIn: "B2C_1A_RAPTOR_SIGNUPSIGNIN "
        },
        authorities: {
            signUpSignIn: {
                authority: `https://${authorityDomain}/${tenantId}/B2C_1A_RAPTOR_SIGNUPSIGNIN`
            }
        },
        authorityDomain
    }
};

module.exports = {
    apiScopes: config.apiScopes,
    msalConfig: {
        auth: {
            clientId: process.env.REACT_APP_B2C_APP_ID,
            authority: config.b2cPolicies.authorities.signUpSignIn.authority,
            knownAuthorities: [config.b2cPolicies.authorityDomain],
            redirectUri: process.env.REACT_APP_UI_URL,
            postLogoutRedirectUri: process.env.REACT_APP_UI_URL
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
        }
    }
};
