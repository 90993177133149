import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Cross = ({
    fill = colorCodes.GREY_2,
    height = 9,
    width = 9,
    style = {},
    viewBox = '0 0 9 9',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g id="cancel" transform="translate(0.5 0.499)">
            <g id="Group_3" data-name="Group 3" transform="translate(0 0.001)">
                <path id="Path_1" data-name="Path 1" d="M4.442,4,7.908.534A.313.313,0,1,0,7.466.092L4,3.559.533.092A.313.313,0,0,0,.091.534L3.558,4,.091,7.467a.313.313,0,0,0,.442.442L4,4.443,7.466,7.909a.313.313,0,1,0,.442-.442Z" transform="translate(0 -0.001)" fill={fill} stroke={fill} strokeWidth="1" />
            </g>
        </g>
    </svg>
)

export default Cross