import React from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import './index.scss'

const About = () => {
    const navigate = useNavigate()
    return (
        <div className="about-us-container">
            <div className="about-us-header" onClick={() => navigate('/')}>
                <img src={require("../../assets/images/raptor-logo.svg").default} alt="Raptor" />
            </div>
            <div className="about-us-content">
                <div className="about-us-section">
                    <div className="about-us-heading">About Raptor</div>
                    <div className="about-us-desc">
                        <p>The Ramboll Raptor (Rapid Pavement Tester) measures the deflection of roads while driving at normal traffic speeds. Raptor consists of a towing truck and a semi-trailer containing the measuring equipment.</p>
                        <p>A heavy load on the axle of the trailer causes the pavement surface to deflect. The deflection is measured using laser sensors pointed at the road surface. Raptor obtains a continuous stream of data describing the structural condition of pavements. </p>
                    </div>
                </div>

                <div className="about-us-section">
                    <div className="about-us-heading">Version 1.3.1</div>
                    <div className="version-welcome-msg">Welcome to the v1.3.1 release of Raptor. There are many updates in this version that we hope you will like, some of the highlights include:</div>
                    <div className="about-us-desc">
                        <ul>
                            <li>Added Graph Visualization for the Analysis module</li>
                            <li>Now user can download RDI Graph data</li>
                            <li>Updated RDI Graph popup window with dynamic title and y-axis value</li>
                            <li>Added new functionality for the Associate User in project module(With Translation)</li>
                            <li>Improved condition for the add measurment section</li>
                            <li>Created Not Found component for the 404 route in the application</li>
                        </ul>
                    </div>
                </div>

                <div className="about-us-section">
                    <div className="about-us-heading">Version 1.3.0</div>

                    <div className="version-welcome-msg">Welcome to the v1.3.0 release of Raptor. There are many updates in this version that we hope you will like, some of the highlights include:</div>

                    <div className="about-us-desc">
                        <ul>
                            <li>Fixed issue related to deleting button on projects and measurements listing page</li>
                            <li>Added dynamic data for the Analysis module tile</li>
                            <li>Replaced rate result indicator position and fixed issue related to updating rate result functionality</li>
                            <li>Added warning messages for the project, calibrations, and measurements module (With Translation)</li>
                            <li>Fixed alignments for the table header inputs and onchange sorting issue</li>
                            <li>Added functionality for the application data syncing, now another open tab will be synced with the latest data</li>
                            <li>Added Analysis progress status on each input file under the Analysis list module</li>
                            <li>The same layout as we are using for Measurement and Analysis has been added for the calibration module in case of data is not found</li>
                        </ul>
                    </div>
                </div>

                <div className="about-us-section">
                    <div className="about-us-heading">Version 1.2.0</div>

                    <div className="version-welcome-msg">Welcome to the v1.2.0 release of Raptor. There are many updates in this version that we hope you will like, some of the highlights include:</div>

                    <div className="about-us-desc">
                        <ul>
                            <li>Added About Us page for the versioning and basic updates version wise</li>
                            <li>Added Analysis list under the project module</li>
                            <li>Created route for the selected calcluation information</li>
                            <li>Added progress info for each specific Analysis</li>
                            <li>Added redirection on project list page with cancel button on create project form</li>
                            <li>Same fix implemented on add measurment form page</li>
                        </ul>
                    </div>
                </div>

                <div className="about-us-section">
                    <div className="about-us-heading">Version 1.1.0</div>
                    <div className="version-welcome-msg">Welcome to the v1.1.0 release of Raptor. There are many updates in this version that we hope you will like, some of the highlights include:</div>
                    <div className="about-us-desc">
                        <ul>
                            <li>Updated Navbar & Sidebar UI</li>
                            <li>Added UI screens for the projects section</li>
                            <li>Implemented UI for the map view (with static content)</li>
                            <li>Improved some points under Projects module</li>
                        </ul>
                    </div>
                </div>

                <div className="about-us-section">
                    <div className="about-us-heading">Version 1.0.0</div>
                    <div className="version-welcome-msg">Welcome to the v1.0.0 release of Raptor. There are many updates in this version that we hope you will like, some of the highlights include:</div>
                    <div className="about-us-desc">
                        <ul>
                            <li>Launched Raptor WebApp with some static content</li>
                            <li>Added some images and styling for the UI purpose</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="about-us-footer">
                Copyright © 2023 Raptor All Rights Reserved <Link to={'/terms'}>Terms of Service</Link>
            </div>
        </div>
    )
}

export default About