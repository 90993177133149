export const SET_GLOBAL_ACTIVE_TAB = 'SET_GLOBAL_ACTIVE_TAB'
export const SET_MAP_LAT_LONG = 'SET_MAP_LAT_LONG'
export const SET_AUTO_OUT = 'SET_AUTO_OUT'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM'
export const SET_DEFAULT_PROJ_ID = 'SET_DEFAULT_PROJ_ID'

export const setGlobalActiveTab = globalActiveTab => ({
  type: SET_GLOBAL_ACTIVE_TAB,
  payload: { globalActiveTab }
})

export const setMapLatLong = (mapLatLong, mapZoom = 7) => ({
  type: SET_MAP_LAT_LONG,
  payload: { mapLatLong, mapZoom }
})

export const setMapZoom = (mapZoom = 7) => ({
  type: SET_MAP_ZOOM,
  payload: { mapZoom }
})

export const setAutoOut = autoOut => ({
  type: SET_AUTO_OUT,
  payload: { autoOut }
})

export const setUserInfo = userInfo => ({
  type: SET_USER_INFO,
  payload: { userInfo }
})

export const setLanguage = lang => {
  localStorage.setItem("raptor-lang", lang)
  return ({
    type: SET_LANGUAGE,
    payload: { lang }
  })
}

export const setDefaultProjId = defaultProjID => ({
  type: SET_DEFAULT_PROJ_ID,
  payload: { defaultProjID }
})