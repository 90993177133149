import { ProfileSection } from './ProfileSection';
import './index.scss'

export function NavBar({ showProjectTitle = false, projectTitle = false }) {
    return (
        <div className="navbar-main-box between-flex w-100">
            <div className="d-flex logo-section ai-center">
                <img src={require('../../assets/images/sm-logo.svg').default} alt="RAPTOR" />
            </div>
            {!!showProjectTitle && !!projectTitle && <div className="d-flex prj-title-sec">
                {projectTitle}
            </div>}
            <div className="d-flex logo-section">
                <ProfileSection />
            </div>
        </div>
    )
}
