import { useState, useEffect } from "react";
import { Field, useFormState } from "react-final-form";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMapLatLong } from "../../../actions/global";
import { checkProjectUniqueName } from "../../../actions/project";
import RaptorButton from "../../../components/common/RaptorButton";
import ReduxLabelInput from "../../../components/redux-form/ReduxLabelInput";
import ReduxSelectField from "../../../components/redux-form/ReduxSelectField";
import ReduxTextArea from "../../../components/redux-form/ReduxTextArea";
import { required } from "../../../components/redux-form/validations";
import { NORWAY_CORDI } from "../../../constant";
import "./index.scss";

const ProjectForm = ({
  submitting, pristine, handleSubmit, isLoading = false, isEdit = false, id = false, resetFn
}) => {
  const isValid = (submitting || pristine)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [nameErr, setNameErr] = useState(false)
  const { countries } = useSelector(state => state.projects)
  const { values } = useFormState()
  const regiouVal = !!values && !!values.country ? values.country : ''

  useEffect(() => {
    if (!!regiouVal) { // HINT: For now, co-ordinate value making issue with ol-map libarary
      const regionLatLong = countries.find(a => a.countryName === regiouVal)
      if(!!regionLatLong && !!regionLatLong.lat && !!regionLatLong.long) {
        dispatch(setMapLatLong([regionLatLong.long, regionLatLong.lat] || NORWAY_CORDI))
      }
    }
    // eslint-disable-next-line
  }, [regiouVal])

  return (
    <div className="project-form-container d-flex">
      <div className="title-and-back-btn">
        <img src={require('../../../assets/images/back.svg').default} alt="Back" onClick={() => navigate("/raptor/projects")} />
        <div className="title-value">{t(!!isEdit ? "project.form.edit_title" : "project.form.title")}</div>
      </div>
      <div className="page-desc-sec">{t(!!isEdit ? "project.form.edit_desc" : "project.form.desc")}</div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex pt-30">
          <Field name="projectName" label={t('project.form.p_name')} component={ReduxLabelInput}
            handleOnBlur={async e => {
              const resp = await dispatch(checkProjectUniqueName(e.target.value, id))
              if (!!resp) {
                setNameErr(false)
              } else {
                setNameErr("message.error.prjct_name_exist")
              }
            }}
            handleOnFocus={() =>
              setNameErr(false)
            }
            serverErrMsg={nameErr}
            placeholder={t("project.form.enter_p_name")} validate={required} />
        </div>
        <div className="d-flex pt-35">
          <Field name="client" label={t('project.form.client')} component={ReduxLabelInput} placeholder={t("project.form.enter_client")} validate={required} />
        </div>
        <div className="d-flex pt-35">
          <Field name="country" label={t('project.form.country')} component={ReduxSelectField} placeholder={t("project.form.select_country")} validate={required}
            options={countries.map(c => ({label: c.countryName, value: c.countryName}))} />
        </div>
        <div className="d-flex pt-35">
          <Field name="description" label={t('project.form.des') + ` (${t('project.form.optional')})`} component={ReduxTextArea} placeholder={t("project.form.des_about")} />
        </div>
        <div className='prj-act-btn-holder'>
          <RaptorButton title={t("button.cancel")} className='cancel-prj-btn' onClick={() => navigate("/raptor/projects")} />
          <RaptorButton type="submit" title={(isLoading || submitting) ? `${t('button.loading')}...` : (!!isEdit ? t('button.save_changes') : t('button.create_proj'))} className='create-prj-btn' disabled={isValid} />
        </div>
      </form>
    </div>
  )
}

export default ProjectForm