import React from 'react'
import { colorCodes } from '../../../constant/colors'

const WorksPlan = ({
    fill = colorCodes.BLUE_4,
    height = '23.288',
    width = '27.897',
    style = {},
    viewBox = '0 0 27.897 23.288',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g id="worksplan" transform="translate(-5.156 -26.282)">
            <path id="Path_31" d="M25.044,110.771a.553.553,0,0,0,.558-.558v-5.839l3.372-1.809v4.79a.558.558,0,1,0,1.117,0V100.7l-5.594,3v6.509A.543.543,0,0,0,25.044,110.771Z" transform="translate(-16.958 -65.738)" fill={fill} stroke={fill} strokeWidth="0.5" />
            <path id="Path_32" d="M85.158,40.8a.553.553,0,0,0,.558-.558v-9.87l3.372-1.809V44.62a.558.558,0,1,0,1.116,0V26.7l-5.6,3v10.54A.56.56,0,0,0,85.158,40.8Z" transform="translate(-70.351)" fill={fill} stroke={fill} strokeWidth="0.5" />
            <path id="Path_33" d="M145.444,67.372a.553.553,0,0,0,.558-.558V56.173l3.372-1.809V64a.558.558,0,1,0,1.117,0V52.5l-5.594,3v11.31A.544.544,0,0,0,145.444,67.372Z" transform="translate(-123.915 -22.919)" fill={fill} stroke={fill} strokeWidth="0.5" />
            <path id="Path_34" d="M32.716,143.979a.563.563,0,0,0-.48-.279H28.384a.563.563,0,0,0-.48.279.554.554,0,0,0,0,.558l.692,1.195-3.07,1.865c-.469.29-.927.569-1.4.86-.335.212-.67.413-.994.625-.871.558-1.641,1.061-2.356,1.552-.368.246-.726.5-1.094.748l-5-4.89L5.663,152.23A.559.559,0,0,0,5.5,153a.565.565,0,0,0,.469.257.625.625,0,0,0,.3-.089l8.262-5.259,5.047,4.924.681-.491c.38-.268.759-.536,1.15-.793.7-.48,1.463-.971,2.333-1.53.324-.212.659-.413.983-.625.458-.29.927-.569,1.384-.86l2.825-1.731.223-.134.681,1.183a.552.552,0,0,0,.96,0l1.932-3.338A.541.541,0,0,0,32.716,143.979Zm-2.412,2.5-.96-1.664h1.92Z" transform="translate(0 -103.937)" fill={fill} stroke={fill} strokeWidth="0.5" />
        </g>
    </svg>
)

export default WorksPlan