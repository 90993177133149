import React from 'react'
import { colorCodes } from '../../../constant/colors'

const LeftArrow = ({
    fill = colorCodes.BLACK_1,
    height = 19,
    width = 12,
    style = {},
    viewBox = '0 0 12 19',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <path d="M3.98697 9.26662L10.875 2.38462C11.1186 2.14022 11.2551 1.80906 11.2545 1.464C11.254 1.11893 11.1164 0.788219 10.872 0.544617C10.751 0.423998 10.6074 0.328396 10.4494 0.263268C10.2914 0.19814 10.1222 0.164762 9.95135 0.165041C9.60628 0.165603 9.27557 0.30322 9.03197 0.547617L1.23197 8.34762C0.996304 8.58447 0.860883 8.90297 0.853807 9.23701C0.846732 9.57105 0.968545 9.895 1.19397 10.1416L9.02997 17.9936C9.27357 18.238 9.60428 18.3756 9.94934 18.3762C10.2944 18.3768 10.6256 18.2402 10.87 17.9966C11.1144 17.753 11.252 17.4223 11.2525 17.0772C11.2531 16.7322 11.1166 16.401 10.873 16.1566L3.98697 9.26662Z" fill={fill} />
    </svg>
)

export default LeftArrow