import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Folder = ({
    fill = colorCodes.BLACK_1,
    height = 31,
    width = 30,
    style = {},
    viewBox = '0 0 30 31',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M15.0782 6.24177H23.1632C24.6537 6.24177 25.8621 7.4501 25.8621 8.94065L25.0314 27.6647C24.9603 29.2683 23.6395 30.5317 22.0344 30.5317H3C1.34315 30.5317 0 29.1886 0 27.5317V3.84399C0 2.18714 1.34315 0.843994 3 0.843994H8.3892C9.42657 0.843994 10.3903 1.37993 10.9377 2.26117L12.5298 4.82459C13.0771 5.70583 14.0409 6.24177 15.0782 6.24177Z" fill={fill} />
        <path d="M27.6189 9.87939H4.35138C3.3429 9.87939 2.49218 10.6302 2.36688 11.6309L0.281571 28.2832C0.132071 29.4771 1.0629 30.5317 2.26607 30.5317H24.6994C25.6708 30.5317 26.5017 29.8338 26.6694 28.8771L29.5888 12.2248C29.8034 11.0007 28.8616 9.87939 27.6189 9.87939Z" fill={fill} />
    </svg>
)

export default Folder