import React from 'react'
import { colorCodes } from '../../../constant/colors'

const MapChart = ({
    fill = colorCodes.BLACK_1,
    height = 27,
    width = 27,
    style = {},
    viewBox = '0 0 27 27',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M9.125 0.375L0.9 3.14583C0.59375 3.24792 0.375 3.51042 0.375 3.84583V25.8958C0.375 26.0892 0.451823 26.2747 0.588568 26.4114C0.725313 26.5482 0.91078 26.625 1.10417 26.625L1.3375 26.5812L9.125 23.5625L17.875 26.625L26.1 23.8542C26.4062 23.7521 26.625 23.4896 26.625 23.1542V1.10417C26.625 0.91078 26.5482 0.725313 26.4114 0.588568C26.2747 0.451823 26.0892 0.375 25.8958 0.375L25.6625 0.41875L17.875 3.4375L9.125 0.375ZM7.66667 3.94792V21.0104L3.29167 22.7021V5.42083L7.66667 3.94792ZM10.5833 3.97708L16.4167 6.01875V23.0229L10.5833 20.9812V3.97708ZM23.7083 4.3125V21.5792L19.3333 23.0521V6.00417L23.7083 4.3125ZM6.87917 5.1875L4.12292 6.16458V9.3L6.87917 8.32292V5.1875ZM6.87917 9.19792L4.12292 10.175V13.3104L6.87917 12.3333V9.19792ZM6.87917 13.2083L4.12292 14.1854V17.3208L6.87917 16.3438V13.2083ZM6.87917 17.2188L4.12292 18.1958V21.3313L6.87917 20.3542V17.2188Z" fill={fill} />
    </svg>
)

export default MapChart