import React from 'react'
import { colorCodes } from '../../../constant/colors'

const RightArrow = ({
    fill = colorCodes.BLACK_1,
    height = 19,
    width = 12,
    style = {},
    viewBox = '0 0 12 19',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <path d="M7.91937 9.26704L1.03237 2.38404C0.789021 2.1403 0.652344 1.80996 0.652344 1.46554C0.652344 1.12112 0.789021 0.790776 1.03237 0.547039C1.27789 0.304776 1.60894 0.168945 1.95387 0.168945C2.29879 0.168945 2.62984 0.304776 2.87537 0.547039L10.6754 8.34704C10.911 8.58389 11.0465 8.90239 11.0535 9.23643C11.0606 9.57048 10.9388 9.89442 10.7134 10.141L2.87737 17.993C2.63377 18.2374 2.30306 18.3751 1.95799 18.3756C1.61292 18.3762 1.28176 18.2396 1.03737 17.996C0.79297 17.7524 0.655353 17.4217 0.654791 17.0767C0.654228 16.7316 0.790765 16.4004 1.03437 16.156L7.91937 9.26704Z" fill={fill} />
    </svg>
)

export default RightArrow