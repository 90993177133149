import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import RaptorButton from '../../../components/common/RaptorButton'
import { perc2color } from '../../../constant/helpers'
import "./index.scss"

const NetworkWidget = () => {
    const wrapperRef = useRef()
    const [networkOpt, setNetworkOpt] = useState(false)
    const { t } = useTranslation()
    const { globalActiveTab } = useSelector(state => state.global)
    const { measurmentObjMinMax, deflectionObjMinMax } = useSelector(state => state.visualizes)

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (!!wrapperRef.current) &&
            !wrapperRef.current.contains(event.target)) {
            setNetworkOpt(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        // eslint-disable-next-line
    }, [])

    let colorArr = []

    for (var i = 0; i <= 100; i++) {
        colorArr.push({ [i]: perc2color(i) })
    }

    const conditionalObj = (globalActiveTab === "measur") ? measurmentObjMinMax : deflectionObjMinMax
    return (
        <>
            <div className='map-network-widget-container' ref={wrapperRef}>
                {
                    !!networkOpt &&
                    <div className='map-network-opt-list'>
                        <div className='lengend-main-title mb-2'>{t("legend_range")}</div>
                        <div className='color-palette-holder'>
                            {
                                !!colorArr.length && colorArr.map((c, k) => <div className='color-palette-div' key={k} style={{
                                    backgroundColor: c[k]
                                }}></div>)
                            }
                        </div>

                        <div className='min-max-rec-holder mt-3 mb-3'>
                            <div className='min-max-rec-opt'>
                                <div></div>
                                <div>{"Min"}</div>
                                <div>{"Max"}</div>
                            </div>
                            {Object.keys(conditionalObj).map((key, index) => (
                                <div key={index} className='min-max-rec-opt'>
                                    <div>{key}</div>
                                    <div>{conditionalObj[key]['min']}</div>
                                    <div>{conditionalObj[key]['max']}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                <RaptorButton className='network-widget-btn center-flex' title="Network" onClick={() => setNetworkOpt(true)}>
                    <div className='network-btn-title'>
                        {t("button.legend")}
                    </div>
                </RaptorButton>
            </div>
        </>
    )
}

export default NetworkWidget