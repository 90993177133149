import React from 'react'
import Edit from './Edit'
import Trash from './Trash'
import Trash2 from './Trash2'
import Download from './Download'
import Select from './Select'
import User from './User'
import Stack from './Stack'
import Plus from './Plush'
import Minus from './Minus'
import Network from './Network'
import Cross from './Cross'
import DoubleArrow from './DoubleArrow'
import StrategyHead from './StrategyHead'
import Magnifier from './Magnifier'
import Report from './Report'
import WorksPlan from './WorksPlan'
import Caret from './Caret'
import File from './File'
import Eye from './Eye'
import LeftArrow from './LeftArrow'
import RightArrow from './RightArrow'
import Calendar from './Calendar'
import RoundSelect from './RoundSelect'
import Folder from './Folder'
import Warning from './Warning'
import RoundCheck from './RoundCheck'
import Pencil from './Pencil'
import BigFiles from './BigFiles'
import CircleCross from './CircleCross'
import Wallet from './Wallet'
import VerticalThreeDot from './VerticalThreeDot'
import SquareGrowth from './SquareGrowth'
import PieSlice from './PieSlice'
import RoundSquareGrowth from './RoundSquareGrowth'
import GrowthArrow from './GrowthArrow'
import Download2 from './Download2'
import Location from './Location'
import PlusFile from './PlusFile'
import Statics from './Statics'
import Eye2 from './Eye2'
import MapChart from './MapChart'
import Key from './Key'

const Icon = ({ name, ...rest }) => {
    switch (name) {
        case 'download':
            return <Download {...rest} />
        case 'download2':
            return <Download2 {...rest} />
        case 'edit':
            return <Edit {...rest} />
        case 'trash':
            return <Trash {...rest} />
        case 'trash2':
            return <Trash2 {...rest} />
        case 'select':
            return <Select {...rest} />
        case 'user':
            return <User {...rest} />
        case 'stack':
            return <Stack {...rest} />
        case 'plus':
            return <Plus {...rest} />
        case 'minus':
            return <Minus {...rest} />
        case 'network':
            return <Network {...rest} />
        case 'cross':
            return <Cross {...rest} />
        case 'key':
            return <Key {...rest} />
        case 'double-arrow':
            return <DoubleArrow {...rest} />
        case 'strategy-head':
            return <StrategyHead {...rest} />
        case 'magnifier':
            return <Magnifier {...rest} />
        case 'report':
            return <Report {...rest} />
        case 'worksplan':
            return <WorksPlan {...rest} />
        case 'caret':
            return <Caret {...rest} />
        case 'file':
            return <File {...rest} />
        case 'eye':
            return <Eye {...rest} />
        case 'eye-2':
            return <Eye2 {...rest} />
        case 'left-arrow':
            return <LeftArrow {...rest} />
        case 'right-arrow':
            return <RightArrow {...rest} />
        case 'calendar':
            return <Calendar {...rest} />
        case 'round-select':
            return <RoundSelect {...rest} />
        case 'folder':
            return <Folder {...rest} />
        case 'warning':
            return <Warning {...rest} />
        case 'round-check':
            return <RoundCheck {...rest} />
        case 'pencil':
            return <Pencil {...rest} />
        case 'big-files':
            return <BigFiles {...rest} />
        case 'circle-cross':
            return <CircleCross {...rest} />
        case 'wallet':
            return <Wallet {...rest} />
        case 'vertical-three-dot':
            return <VerticalThreeDot {...rest} />
        case 'square-growth':
            return <SquareGrowth {...rest} />
        case 'pie-slice':
            return <PieSlice {...rest} />
        case 'round-square-growth':
            return <RoundSquareGrowth {...rest} />
        case 'growth-arrow':
            return <GrowthArrow {...rest} />
        case 'location':
            return <Location {...rest} />
        case 'plus-file':
            return <PlusFile {...rest} />
        case 'statics':
            return <Statics {...rest} />
        case 'map-chart':
            return <MapChart {...rest} />
        default:
            return null
    }
}

export default Icon
