import { useRef } from "react"
import { confirmAlert } from "react-confirm-alert"
import { Form } from "react-final-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { createProject } from "../../../actions/project"
import ConfirmAlertPopup from "../../../components/common/ConfirmAlertPopup"
import RaptorButton from "../../../components/common/RaptorButton"
import { Home } from "../../home"
import ProjectForm from "../ProjectForm"
import "./index.scss"

const CreateProject = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const formRef = useRef(null);

  const onSubmit = async (data) => {
    const resp = await dispatch(createProject(data, t))
    if (!!resp) {
      confirmAlert({
        overlayClassName: "overlay-dialog-create-project",
        customUI: values => <ConfirmAlertPopup {...values}
          icon="round-check"
          title="project.dialog.create_title"
          message="project.dialog.create_desc"
          confirmBtnTxt="project.dialog.add_measurment"
          cancelBtnTxt="project.dialog.add_later"
          handleOnClose={() => navigate("/raptor/projects")}
          onConfirm={() => navigate(`/raptor/projects/add-measurment/${resp._id}`)}
        />
      })
    }
  }

  return (
    <div className="map-and-form-holder">
      <Form
        subscription={{ submitting: true, pristine: true }}
        enableReinitialize={true}
        forceUnregisterOnUnmount={false}
        onSubmit={onSubmit}
        initialValues={{ projectName: "", client: "", country: "", description: "" }}
        FormState
        render={({
          handleSubmit, submitting, pristine, form
        }) => {
          formRef.current = form;
          return (
            <ProjectForm
              pristine={pristine}
              submitting={submitting}
              handleSubmit={handleSubmit}
              resetFn={form.reset}
            />
          )
        }
        } />
      <div className="map-view-window">
        { false &&
          (!formRef || !formRef.current || !formRef.current.getFieldState('country').value) &&
          <div className="map-view-shield">
            <RaptorButton title="Select Country to view the map" className="map-hint-btn" />
          </div>
        }
        <Home />
      </div>
    </div>
  )
}

export default CreateProject