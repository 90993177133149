import React from 'react'

const Eye2 = ({
    fill = '#200E32',
    height = '22',
    width = '27',
    style = {},
    viewBox = '0 0 27 22',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M17.691 11.0061C17.691 11.8597 17.4379 12.6941 16.9637 13.4038C16.4894 14.1135 15.8154 14.6667 15.0268 14.9933C14.2382 15.32 13.3705 15.4054 12.5333 15.2389C11.6962 15.0724 10.9272 14.6614 10.3236 14.0578C9.72005 13.4542 9.30902 12.6852 9.1425 11.8481C8.97597 11.0109 9.06144 10.1432 9.38809 9.35458C9.71473 8.56599 10.2679 7.89197 10.9776 7.41776C11.6873 6.94354 12.5217 6.69043 13.3753 6.69043C13.942 6.69043 14.5032 6.80206 15.0268 7.01894C15.5504 7.23583 16.0262 7.55372 16.4269 7.95447C16.8277 8.35522 17.1456 8.83098 17.3625 9.35458C17.5793 9.87819 17.691 10.4394 17.691 11.0061V11.0061Z" stroke="#200E32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.3723 20.9666C18.5697 20.9666 23.3235 17.2296 26 11.0031C23.3235 4.77655 18.5697 1.03955 13.3723 1.03955C8.18032 1.03955 3.4265 4.77655 0.75 11.0031C3.4265 17.2323 8.18032 20.9666 13.3777 20.9666H13.3723Z" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default Eye2