import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import { toast } from "react-toastify"
import { checkCalcUniqueName, createCalculation, deleteMeasurmentFile, tagCalibrationFile } from "../../../actions/project"
import CommonTable from "../../../components/common/CommonTable"
import Icon from "../../../components/common/Icons"
import RaptorButton from "../../../components/common/RaptorButton"
import { DATE_TIME_FORMAT_1, ID_SELECTOR } from "../../../constant"
import LabelSelectBox from "../../../components/common/LabelSelectBox"
import CustomPopUp from "../../../components/common/CustomPopUp"
import LabelInput from "../../../components/common/LabelInput"
import CommonMultiSelectFilter from "../../../components/common/CommonTable/CommonMultiSelectFilter"
import RaptorCheckbox from "../../../components/common/RaptorCheckbox"
import { setCheckedMIds } from "../../../actions/visualize"
import EditMeasurment from "../EditMeasurment"
import "./index.scss"

let abortController = new AbortController()

const MeasurmentList = ({
  onlyTable = false, actionBtn = true, fullView = true,
  measurmentInstance = false, onRowClick = false,
  mIdSelector = ID_SELECTOR, setGlobalState = false
}) => {
  const { checkedMeasurmentIds } = useSelector(state => state.visualizes)
  const [multiIDs, setMultiIDs] = useState([])
  const [editForm, setEditForm] = useState(false)
  const [measurmentData, setMeasurmentData] = useState({
    "roadName": "",
    "roadType": "Highway",
    "direction": 1
  })

  useEffect(() => {
    setMultiIDs(checkedMeasurmentIds)
  }, [checkedMeasurmentIds])

  const [blobArr, setBlobArr] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const { globalActiveTab } = useSelector(state => state.global)
  const projectDetails = useSelector(state => state.projects)
  const projectInfo = !!measurmentInstance ? measurmentInstance : projectDetails.projectInfo
  const [checkAll, setCheckAll] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [selectedSecs, setSelectedSecs] = useState([])
  const [nameErr, setNameErr] = useState(false)

  const defaultFormInput = {
    calc_name: "",
    report_length: 10,
    temp: 20,
    load: 50,
    is_load_change: "false",
    is_temp_change: "false"
  }
  const [formInput, setFormInput] = useState(defaultFormInput)


  const columns = (calList = []) => !!fullView ? [
    { Header: "table.rd_name", accessor: (row) => !!row.roadName ? row.roadName : '-', isSortable: !onlyTable },
    { Header: "table.type", accessor: (row) => !!row.roadType ? row.roadType : '-', isSortable: !onlyTable, disableFilter: onlyTable },
    {
      Header: "table.direction", accessor: (row) => !!row.direction ? row.direction : '-', filter: 'multiSelect',
      isSortable: !onlyTable, Filter: onlyTable ? false : CommonMultiSelectFilter, options: [
        { label: '1' },
        { label: '2' },
        { label: '3' },
        { label: '4' }
      ]
    },
    {
      Header: "table.measur_file", accessor: (row) => !!row.fileName ? <>
        <div className="file-title" title={(row.fileName).replace('ready_for_upload.zip', '')}>{(row.fileName).replace('ready_for_upload.zip', '')}</div>
        <div className="file-time-stamp">{moment(new Date(row.measurementDateTime)).format(DATE_TIME_FORMAT_1)}</div>
      </> : '-', Filter: false
    },
    {
      Header: "table.calb_file", accessor: (row, a, b) => {
        const updatedCalList = calList.map(c => {
          return { ...c, measurementDateTime: row.measurementDateTime };
        })

        updatedCalList.sort((a, b) => a.calibrationDateTime > b.measurementDateTime ? -1 : 1)
      
        return <LabelSelectBox
        placeholder="Select File"
        parentClassName="calibration-options center-flex"
        getOptionValue={option => option._id}
        getOptionLabel={option => option.fileName}
        options={updatedCalList}
        handleOnChange={e => {
          dispatch(tagCalibrationFile(id, {
            "inputFileId": row._id,
            "calibrationFileId": e._id
          }))
        }}
        optionValKey={"_id"}
        value={row.taggedCalibrationFileId}
      />}, Filter: false
    },
    { Header: "table.comment", accessor: (row) => !!row.comment ? row.comment : '-' },
    { Header: "table.av_speed", accessor: (row) => !!row.averageSpeed ? row.averageSpeed : '-', isSortable: !onlyTable, Filter: false },
    { Header: "table.length", accessor: (row) => !!row.measurementLength ? row.measurementLength : '-', isSortable: !onlyTable, Filter: false },
    { Header: "table.date", accessor: "measurementDateTime", Filter: false, isSortable: !onlyTable, type: 'date' }
  ] :
    [{ Header: "table.rd_name", accessor: (row) => !!row.roadName ? row.roadName : '-', isSortable: !onlyTable, disableFilter: onlyTable }]


  useEffect(() => {
    if (!!projectInfo && !!projectInfo.inputFiles && !!projectInfo.inputFiles.length) {
      const isAllChecked = (currentValue) => multiIDs.includes(currentValue[mIdSelector])
      setCheckAll(projectInfo.inputFiles.every(isAllChecked))

      const selectedMFiles = []
      multiIDs.map(m => {
        const rec = projectInfo.inputFiles.find(r => (((r._id).toString() === (m).toString()) && !!r.taggedCalibrationFileId))
        if (!!rec) {
          selectedMFiles.push(rec)
        }
        return m
      })

      setSelectedSecs(selectedMFiles)

      if (!multiIDs.length) {
        setShowForm(false)
        setFormInput(defaultFormInput)
      }
    }

    // eslint-disable-next-line
  }, [multiIDs, projectInfo])

  const memoizeMultiIDs = records => !!records && !!records.length ? records.map(rec => rec[mIdSelector]) : []
  const allMultiIDs = useMemo(() => memoizeMultiIDs(projectInfo.inputFiles), [projectInfo.inputFiles])

  useEffect(() => {
    if (checkAll) {
      setMultiIDs(allMultiIDs)
      if (!!setGlobalState) {
        dispatch(setCheckedMIds(allMultiIDs))
      }
    }
    // eslint-disable-next-line
  }, [checkAll])

  const onCheckboxChange = (e, row) => {
    let newIDsList = multiIDs
    if (e.target.checked) {
      newIDsList = [...new Set([...multiIDs, row[mIdSelector]])]
    } else {
      newIDsList = newIDsList.filter(id => id !== row[mIdSelector])
    }
    setMultiIDs(newIDsList)

    if (!!setGlobalState) {
      dispatch(setCheckedMIds(newIDsList))
    }
  }

  const calbList = !!projectInfo && !!projectInfo.calibrationFiles ? projectInfo.calibrationFiles : []
  for (var i = 0; i < calbList.length; i++) {
    calbList[i]['fileName'] = (calbList[i]['fileName']).replace('_calibration_ready_for_upload.zip', '')
  }

  const removeSecFile = id => {
    const newIDs = multiIDs.filter(oid => oid !== id)
    setMultiIDs(newIDs)
  }

  const onChangeInput = (key, value) => {
    let response = null
    if (!!key && (
      (key === 'is_load_change') ||
      (key === 'is_temp_change')
    )) {
      response = {
        ...formInput,
        ...(key === 'is_load_change' && {
          is_load_change: value,
          load: (value === "true") ? formInput.load : 50
        }),
        ...(key === 'is_temp_change' && {
          is_temp_change: value,
          temp: (value === "true") ? formInput.temp : 20
        })
      }
    } else {
      response = {
        ...formInput,
        [key]: value
      }
    }
    setFormInput(response)
  }

  const unCheckAll = () => {
    setCheckAll(false)
    setMultiIDs([])
    if (!!setGlobalState) {
      dispatch(setCheckedMIds([]))
    }
  }

  useEffect(() => {
    unCheckAll()
    // eslint-disable-next-line
  }, [globalActiveTab])

  const submitCreateCalculation = async () => {
    const { calc_name, report_length, temp, load, is_load_change, is_temp_change } = formInput

    if ((load < 10) || (load > 200)) {
      toast.error(t('message.error.norm_load_should_be'))
      return
    }

    if ((temp < 5) || (temp > 50)) {
      toast.error(t('message.error.norm_temp_should_be'))
      return
    }

    const userOid = !!projectInfo && !!projectInfo.owner ? projectInfo.owner : false
    if (!userOid) {
      return toast.error('Project user id not found')
    }

    if (!selectedSecs || !selectedSecs.length) {
      return toast.error('Please choose something')
    }

    const inputFilesArr = selectedSecs.map(s => s._id)
    const calbFilesArr = selectedSecs.map(s => s.taggedCalibrationFileId)

    const resp = await dispatch(createCalculation(id, {
      calbFilesArr,
      "NGuess": "-0.3",
      "calculationName": calc_name,
      "projectId": id,
      "reportLength": Number(report_length),
      "normalizationTemperature": Number(temp),
      "normalizationLoad": Number(load),
      "userOid": userOid,
      "inputFilesId": inputFilesArr,
      "isTempChange": is_temp_change === "true" ? "1" : "0",
      "isLoadChange": is_load_change === "true" ? "1" : "0"
    }, t))

    if (!!resp) {
      setShowForm(false)
      navigate(`/raptor/projects/view/${id}?calcId=${resp[ID_SELECTOR]}`)
    }

  }

  useEffect(() => {
    let blobArrList = []
    const projectFiles = !!projectInfo && !!projectInfo.inputFiles ? projectInfo.inputFiles : []
    for (var k = 0; k < projectFiles.length; k++) {
      for (var j = 0; j < multiIDs.length; j++) {
        if (multiIDs[j] === projectFiles[k]['_id']) {
          blobArrList.push(projectFiles[k]['blobName'])
        }
      }
    }
    setBlobArr(blobArrList)
    // eslint-disable-next-line
  }, [multiIDs])

  const { calc_name, report_length, temp, load, is_load_change, is_temp_change } = formInput
  return (
    !!projectInfo && !!projectInfo.inputFiles && !!projectInfo.inputFiles.length ?
      <div className='measurment-context'>
        {!!editForm && <EditMeasurment handleClose={() => setEditForm(false)} data={measurmentData} />}
        {
          !!showForm && !!multiIDs.length && <CustomPopUp handleClose={() => {
            setFormInput(defaultFormInput)
            setShowForm(false)
          }} className="calculation-input-form" title={t("calc_input_form.calc_input")}>
            <div className="calculation-form-context">
              <div className="calculation-form-context-info">{t("calc_input_form.enter_the_below")}</div>
              <div className="input-field">
                <LabelInput showErrWithLabel={false} label={`${t("calc_input_form.calc_name")}*`} value={calc_name}
                  isValidating={projectDetails.calcIsValidating}
                  onChange={async e => {
                    onChangeInput('calc_name', e.target.value)
                    abortController.abort()
                    abortController = new AbortController()
                    const signal = abortController.signal
                    const resp = await dispatch(checkCalcUniqueName(e.target.value, id, signal))
                    if (!!resp) {
                      setNameErr(false)
                    } else {
                      setNameErr("message.error.calc_name_exist")
                    }
                  }}
                  errClass={!!nameErr ? 'error_border' : ''}
                  onFocus={() =>
                    setNameErr(false)
                  } errMsg={nameErr} />
                <LabelInput label={`${t("calc_input_form.repo_length")}*`} type="number" value={report_length} onChange={e => onChangeInput('report_length', e.target.value)} />
              </div>

              <div className="input-field">
                <div className="d-flex">
                  <label className="title-label">{t("calc_input_form.norm_degree")}*</label>
                  <div className="yes-no-option-holder">
                    <RaptorCheckbox type="radio" label="Yes" checked={is_temp_change === "true"} value={"true"} onChange={(e) => onChangeInput('is_temp_change', e.target.value)} />
                    <RaptorCheckbox type="radio" label="No" checked={is_temp_change === "false"} value={"false"} onChange={(e) => onChangeInput('is_temp_change', e.target.value)} />
                  </div>
                  <LabelInput className="repo-length-input" showLabel={false} label={false} type="number"
                    disabled={is_temp_change === "false"} readOnly={is_temp_change === "false"}
                    value={temp} onChange={e => onChangeInput('temp', e.target.value, e)} />
                </div>
              </div>

              <div className="input-field">
                <div className="d-flex">
                  <label className="title-label">{t("calc_input_form.norm_load")}*</label>
                  <div className="yes-no-option-holder">
                    <RaptorCheckbox type="radio" label="Yes" checked={is_load_change === "true"} value={"true"} onChange={(e) => onChangeInput('is_load_change', e.target.value)} />
                    <RaptorCheckbox type="radio" label="No" checked={is_load_change === "false"} value={"false"} onChange={(e) => onChangeInput('is_load_change', e.target.value)} />
                  </div>
                  <LabelInput className="repo-length-input" showLabel={false} label={false} type="number"
                    disabled={is_load_change === "false"} readOnly={is_load_change === "false"}
                    value={load} onChange={e => onChangeInput('load', e.target.value, e)} />
                </div>
              </div>

              <div className="calculation-form-m-sec-info">{t("calc_input_form.measr_sec")}</div>


              <div className="sec-list">
                {
                  !!selectedSecs && !!selectedSecs.length && selectedSecs.map((m, key) => <div className="d-flex" key={key}>
                    <div className="mr-20">{m.blobName}</div>
                    <div className="remove-btn" onClick={() => removeSecFile(m._id)}>{t("calc_input_form.remove")}</div>
                  </div>)
                }
              </div>

              <div className="action-btn-holder">
                <RaptorButton className="cancel-btn" title={t('button.back_to_measur')} onClick={() => setMultiIDs([])} />
                <RaptorButton className="submit-btn" title={t('button.calculate')} disabled={!calc_name} onClick={async () => {
                  const resp = await dispatch(checkCalcUniqueName(formInput.calc_name, id))
                  if (!!resp) {
                    setNameErr(false)
                    await submitCreateCalculation()
                  } else {
                    setNameErr("message.error.calc_name_exist")
                  }
                }} />
              </div>
            </div>
          </CustomPopUp>
        }

        {!onlyTable && <div className="btn-holder end-flex">
          <div className="d-flex">
            {!!blobArr && !!blobArr.length &&
              <RaptorButton
                title={t("button.delete_measurs")}
                className={`add-measurment-file mr-27`}
                onClick={() => dispatch(deleteMeasurmentFile(id, false, { blobName: blobArr }))}
              />
            }
            <RaptorButton
              title={t("button.add_measur")}
              className={`add-measurment-file`}
              onClick={() => navigate(`/raptor/projects/add-measurment/${id}`)} />
            {
              !!multiIDs && !!multiIDs.length && <RaptorButton
                title={t("button.calculate")}
                className={`calculate-btn ml-5`}
                onClick={() => setShowForm(true)} />
            }
          </div>
        </div>}
        <div className='measurment-context-table center-flex'>
          {
            !!projectInfo.inputFiles.length ?
              <CommonTable columns={columns(calbList)} data={projectInfo.inputFiles}
                onCheckboxChange={(e, info) => onCheckboxChange(e, info || {})}
                handleCheckAll={(checked) => !!checked ? setCheckAll(checked) : unCheckAll()}
                checkAll={checkAll}
                actionBtn={actionBtn}
                showMultiCheckbox={true}
                extraCheckBoxCondi={'taggedCalibrationFileId'}
                multiIDs={multiIDs}
                actionBtnType={2}
                mIdSelector={mIdSelector}
                deleteIcon="trash2"
                editFn={(info) => {
                  setEditForm(true)
                  setMeasurmentData({
                    "measurementId": info.original._id,
                    "roadName": info.original.roadName || "",
                    "roadType": info.original.roadType || "Highway",
                    "direction": info.original.direction || 1,
                  })
                }}
                onRowClick={row => !!onRowClick && onRowClick(row)}
                deleteBtnTxt="button.dlt_measur_file"
                deleteMsg={t("delete_dialog.measur_permanent_dlt")}
                deleteFn={r => !!r && !!r.original && !!r.original.blobName && dispatch(deleteMeasurmentFile(id, false, { blobName: [r.original.blobName] }))} /> :
              <h3 className="center-flex h3-fetching-text">{!!projectDetails.isProcessing ? `${t('project.fetching')}...` : t('project.no_records')}</h3>
          }
        </div>
      </div>
      :
      <div className="measurment-section-card">
        <div className="no-measurment-found center-flex">
          <Icon name="big-files" />
          <div className="no-measurment-title">{t("project.no_measurment_yet")}</div>
          {
            !onlyTable &&
            <>
              <div className="no-measurment-message">{t("project.please_add_measur_message")}</div>
              <label onClick={() => navigate(`/raptor/projects/add-measurment/${id}`)}>{t("button.add_measurment")}</label>
            </>
          }
        </div>
      </div>
  )
}

export default MeasurmentList