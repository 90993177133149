import React, { useRef, useEffect, useContext } from "react";
import "./Map.css";
import RaptorContext from "../../context/RaptorContext";
import * as ol from "ol";


export const Map = ({ children, zoom, center }) => {
  const mapRef = useRef();
  const { map, setMap } = useContext(RaptorContext)
  // on component mount
  useEffect(() => {
    let options = {
      view: new ol.View({ zoom, center }),
      layers: [],
      controls: [],
      overlays: [],
    };

    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);
    return () => mapObject.setTarget(undefined);
    // eslint-disable-next-line
  }, []);

  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
    // eslint-disable-next-line
  }, [zoom]);

  // center change handler

  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center);
    // eslint-disable-next-line
  }, [JSON.stringify(center)]);



  return (
    <div ref={mapRef} className="ol-map">
      {children}
    </div>
  );
};