import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Field, Form } from "react-final-form"
import { toast } from "react-toastify"
import CustomPopUp from "../../../components/common/CustomPopUp"
import { required } from "../../../components/redux-form/validations"
import RaptorButton from "../../../components/common/RaptorButton"
import ReduxLabelInput from "../../../components/redux-form/ReduxLabelInput"
import ReduxSelectField from "../../../components/redux-form/ReduxSelectField"
import SuccessDialog from "../../../components/common/SuccessDialog"
import { updateMeasurment } from "../../../actions/project"
import "./index.scss"


const EditMeasurment = ({ handleClose, data = [] }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    try {
      await dispatch(updateMeasurment(id, data))
      toast.success(<SuccessDialog message={'Updated Successfully'} />)
      handleClose()
      return false
    } catch (e) {
      console.log('Update Measurment Form Submit Error:', e)
    }
  }


  return (
    <div className='edit-measur-form-container'>
      <CustomPopUp handleClose={() => !!handleClose && handleClose()} className="measur-input-form" title={t("form_input.edit_measur")}>
        <div className="edit-measurs-form-context">
          <div className="d-flex f-column mt-40">
            <Form
              subscription={{ submitting: true, pristine: true }}
              enableReinitialize={true}
              keepDirtyOnReinitialize={true}
              forceUnregisterOnUnmount={false}
              onSubmit={onSubmit}
              initialValues={data}
              FormState
              render={({
                handleSubmit, submitting, pristine
              }) => (
                <form onSubmit={handleSubmit} className='add-measurment-form-div'>
                  <div className="d-flex">
                    <Field name={`roadName`} label={t('project.form.rd_name') + "*"} component={ReduxLabelInput} placeholder={t('project.form.enter_rd_name')} validate={required} />
                    <Field name={`roadType`} label={t('project.form.type') + "*"} defaultValue="Highway" component={ReduxLabelInput} placeholder={t("project.form.enter_type")} validate={required} />
                  </div>

                  <div className="d-flex ml-22">
                    <Field name={`direction`} label={t('project.form.direction') + "*"} defaultValue="1" placeholder={t('project.form.direction')} component={ReduxSelectField} validate={required} options={[1, 2, 3, 4].map(s => ({ label: `${s}`, value: s }))} />
                  </div>

                  <div className="d-flex ml-22 mt-40">
                    <RaptorButton type="submit" title={!!submitting ? `${t('button.loading')}...` : (t('button.update'))} disabled={submitting || pristine} />
                    <RaptorButton title={t("button.cancel")} className='cancel-prj-btn' onClick={() => handleClose()} />
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </CustomPopUp>
    </div>
  )
}

export default EditMeasurment