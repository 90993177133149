import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Magnifier = ({
    fill = colorCodes.BLUE_4,
    height = '19.131',
    width = '19.131',
    style = {},
    viewBox = '0 0 19.131 19.131',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g id="visualize" transform="translate(-3.5 -3.5)">
            <path id="Path_14" d="M19.359,11.93A7.43,7.43,0,1,1,11.93,4.5,7.43,7.43,0,0,1,19.359,11.93Z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path id="Path_15" d="M29.015,29.015l-4.04-4.04" transform="translate(-7.798 -7.798)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
    </svg>
)

export default Magnifier