import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Eye = ({
    fill = colorCodes.LIGHT_GREEN_2,
    height = '32',
    width = '32',
    style = {},
    viewBox = '0 0 32 32',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g transform="translate(-388 -281)">
            <circle cx="16" cy="16" r="16" transform="translate(388 281)" fill="#e5fff5" />
            <g transform="translate(394.25 285)">
                <path d="M1.5,12s3-6,8.25-6S18,12,18,12s-3,6-8.25,6S1.5,12,1.5,12Z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                <path d="M18,15.75a2.25,2.25,0,1,1-2.25-2.25A2.25,2.25,0,0,1,18,15.75Z" transform="translate(-6 -3.75)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
            </g>
        </g>
    </svg>
)

export default Eye