import React from 'react'
import './index.scss'

const AuthPopup = ({ t }) => (
    <div className="auth-popup-container center-flex">
        <div className="auth-popup-message center-flex f-column">
            <img src={require("../../assets/images/raptor-logo.svg").default} className="raptor-logo" alt="Raptor" />
            <div className='auth-message-holder'>
                {t("message.error.session_exp")} <div className="reload-act-btn" onClick={() => window.location.reload()}>{t("button.click_here")}</div>
            </div>
        </div>
    </div>
)

export default AuthPopup