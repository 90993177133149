import React from 'react'
import { colorCodes } from '../../../constant/colors'

const SquareGrowth = ({
    fill = colorCodes.BLUE_1,
    height = 22,
    width = 21,
    style = {},
    viewBox = '0 0 21 22',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M5.4668 13.9031L8.4598 10.0141L11.8738 12.6941L14.8028 8.91406" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.2169 5.24244C19.2784 5.24244 20.1389 4.38193 20.1389 3.32044C20.1389 2.25895 19.2784 1.39844 18.2169 1.39844C17.1554 1.39844 16.2949 2.25895 16.2949 3.32044C16.2949 4.38193 17.1554 5.24244 18.2169 5.24244Z" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.146 2.23828H5.879C2.867 2.23828 1 4.37128 1 7.38228V15.4643C1 18.4753 2.831 20.5993 5.879 20.5993H14.479C17.49 20.5993 19.358 18.4753 19.358 15.4643V8.42628" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

)

export default SquareGrowth