import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { Home } from '../home'
import Tabs from '../../components/common/Tabs'
import MeasurmentTab from './MeasurmentTab'
import NetworkWidget from './NetworkWidget'
import Icon from '../../components/common/Icons'
import { colorCodes } from '../../constant/colors'
import { getVisualizeMapForAllMeasurment, setDFormView, setVisualizeCalculation, setVisualizeInfo, setVisualizeMeasurment } from '../../actions/visualize'
import DeflectionTab from './DeflectionTab'
import SpinLoader from '../../components/common/SpinLoader'
import RawDataChartSection from '../Projects/CalculationList/RawDataChartSection'
import './index.scss'

export function VisualizeMap() {
  const wrapperRef = useRef()
  const dispatch = useDispatch()
  const { defaultProjID, globalActiveTab } = useSelector(state => state.global)
  const { isProcessing, selectedCalc, dForm, rawDataObj } = useSelector(state => state.visualizes)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (!!defaultProjID) {
      dispatch(getVisualizeMapForAllMeasurment(defaultProjID))
    }
    return () => {
      dispatch(setVisualizeInfo([]))
      dispatch(setVisualizeMeasurment([]))
      dispatch(setVisualizeCalculation([]))
    }
  // eslint-disable-next-line
  }, [defaultProjID])

  const toggleState = status => {

    if (!!wrapperRef &&
      (wrapperRef.current !== null)) {
      const elem = wrapperRef.current
      if (!!elem && !!elem.style) {

        // Set the element's new position
        if (!status) {
          setTimeout(() => {
            elem.style.top = 0 + "px";
          }, 1000)
        } else {
          elem.style.top = 0 + "px";
        }
      }
    }
    setIsOpen(status)
  }

  return (
    <div className={`visualize-container`}>
      {!!isProcessing && <SpinLoader />}
      {
        !!dForm && <RawDataChartSection
          calcName={!!selectedCalc && !!selectedCalc.calculationName ? selectedCalc.calculationName : ""}
          measurName={!!rawDataObj && !!rawDataObj.roadName ? rawDataObj.roadName : "--"}
          handleClose={() => { dispatch(setDFormView(false)) }}
          lineAtIndex={rawDataObj.lineAtIndex}
          rawDataInfo={rawDataObj.mapData} />
      }
      <Home />
      <div className={`visualize-form-container full-transition ${isOpen ? '' : 'hide-info-tile'}`} ref={wrapperRef}>
        <div className='visualize-form-header-sec'>
          <div className='title-with-icon'>
            <Icon name="map-chart" className="mr-10" />
            {t("visualize_map.visualize_details")}
          </div>
          <div className='action-btn-icon' onClick={() => toggleState(!isOpen)}>
            <Icon name="double-arrow" fill={colorCodes.BLACK_1} />
          </div>
        </div>
        <Tabs tabList={[
          { title: 'tabs.measur', id: 'measur', component: (globalActiveTab === "measur") ? <MeasurmentTab /> : null },
          { title: 'tabs.ip_file', id: 'ip_file', component: (globalActiveTab === "ip_file") ? <DeflectionTab mIdSelector={'measurementFileIds'} /> : null }
        ]} />
      </div>
      <NetworkWidget />
    </div>
  )
}
