import {
    VISUALIZE_IS_PROCESSING, SET_VISUALIZE_MAP_INFO, SET_VISUALIZE_MEASUR_INFO,
    SET_VISUALIZE_PARAM, SET_SHOW_EVENTS, SET_VISUALIZE_CALC_INFO, SET_CHECKED_M_IDS,
    SET_SELECTED_CALC, SET_D_FORM_VIEW, SET_RAW_DATA_OBJ, VISUALIZE_IS_PROCESSING_2,
    SET_DEF_MIN_MAX
} from "../actions/visualize"

const initialState = {
    isProcessing: false,
    isProcessing2: false,
    visualizeMapInfo: [],
    visualizeMeasurInfo: [],
    visualizeCalcInfo: [],
    visualizeParam: false,
    showEvents: false,
    selectedCalc: false,
    dForm: false,
    checkedMeasurmentIds: [],
    rawDataObj: {},
    measurmentObjMinMax: {
        'Speed': { min: 10, max: 80 },
        'AirTemp': { min: 10, max: 40 },
        'SurfTemp': { min: 10, max: 40 }
    },
    deflectionObjMinMax: {
        'D0': { min: 100, max: 1200 },
        'D100': { min: 90, max: 1100 },
        'D200': { min: 80, max: 950 },
        'D300': { min: 70, max: 850 },
        'D450': { min: 60, max: 650 },
        'D600': { min: 50, max: 450 },
        'D900': { min: 30, max: 300 },
        'D1200': { min: 20, max: 150 },
        'D1800': { min: 10, max: 50 },
        'SCI': { min: 50, max: 450 },
        'BCI': { min: 30, max: 200 },
        'BDI': { min: 10, max: 80 }
    }
}

const Visualizes = (state = initialState, action) => {
    switch (action.type) {
        case VISUALIZE_IS_PROCESSING:
        case VISUALIZE_IS_PROCESSING_2:
        case SET_VISUALIZE_MAP_INFO:
        case SET_VISUALIZE_MEASUR_INFO:
        case SET_VISUALIZE_CALC_INFO:
        case SET_VISUALIZE_PARAM:
        case SET_SHOW_EVENTS:
        case SET_CHECKED_M_IDS:
        case SET_SELECTED_CALC:
        case SET_D_FORM_VIEW:
        case SET_RAW_DATA_OBJ:
        case SET_DEF_MIN_MAX:
            return Object.assign({}, state, action.payload)
        default:
            return state
    }
}

export default Visualizes