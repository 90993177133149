import { useSelector } from "react-redux";
import MVT from "ol/format/MVT";
import { Cluster, Vector as VectorSource } from 'ol/source';
import VectorTileLayer from "../../common/Layers/VectorTileLayer";
import { mapAttributeFilter } from "../../constant/helpers";

export function ClusterLayer({ features, keyName = false }) {
  const { visualizeParam, showEvents, checkedMeasurmentIds, measurmentObjMinMax, deflectionObjMinMax } = useSelector(state => state.visualizes);


  // create the source and layer for random features
  const vectorSource = new VectorSource({
    features,
    format: new MVT(),
  })

  const clusterSource = new Cluster({
    distance: parseInt(20, 10),
    minDistance: parseInt(10, 10),
    source: vectorSource
  })

  const objectWithMinMax = {
    ...measurmentObjMinMax,
    ...deflectionObjMinMax
  }

  return (
    <VectorTileLayer
      source={clusterSource}
      style={(feature) => mapAttributeFilter(feature, visualizeParam, checkedMeasurmentIds, showEvents, keyName, objectWithMinMax)}
    />
  )
}
