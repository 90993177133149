import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomPopUp from '../../../../components/common/CustomPopUp';
import { colorCodes } from '../../../../constant/colors';
import { setRawDataObj } from '../../../../actions/visualize';
import SpinLoader from '../../../../components/common/SpinLoader';
import RawDataChart from '../RawDataChart';

const RawDataChartSection = ({
  measurName = "", calcName = "", handleClose,
  rawDataInfo, isProcessing = false, lineAtIndex = false
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(setRawDataObj({}))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <CustomPopUp handleClose={() => handleClose()} className="raw-data-chart-popup" title={measurName}
      subTitle={calcName}>
      <div className="deflection-chart-holder">
        {!!isProcessing && <SpinLoader />}
        <RawDataChart gData={rawDataInfo} lineAtIndex={lineAtIndex}
          options={{
            plugins: {
              zoom: {
                zoom: {
                  wheel: {
                    enabled: true,
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'xy',
                  drag: {
                    enabled: true,
                    borderColor: 'rgb(54, 162, 235)',
                    borderWidth: 1,
                    backgroundColor: 'rgba(54, 162, 235, 0.3)'
                  }
                },
                pan: {
                  enabled: true,
                  mode: 'xy',
                  modifierKey: 'ctrl'
                },
              },
              legend: {
                position: "left"
              },
              title: {
                display: true,
                text: 'Deflections',
                color: colorCodes.BLACK_1,
                font: {
                  size: 25,
                },
              },
            }
          }} />
      </div>
    </CustomPopUp>
  )
}

export default RawDataChartSection