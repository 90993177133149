import React from 'react'
import { colorCodes } from '../../../constant/colors'

const RoundCheck = ({
    fill = colorCodes.WHITE_1,
    height = 34,
    width = 33,
    style = {},
    viewBox = '0 0 33 34',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <path d="M22.1661 12.7661L22.1661 12.7661L22.1708 12.7613C22.3045 12.624 22.4863 12.5441 22.6779 12.5385C22.8263 12.5343 22.9725 12.5749 23.0975 12.6551C23.2225 12.7354 23.3203 12.8515 23.3782 12.9883C23.4361 13.125 23.4514 13.2761 23.422 13.4217C23.3926 13.5673 23.32 13.7006 23.2136 13.8043L23.2136 13.8042L23.2089 13.8089L14.959 22.0588C14.9589 22.0588 14.9589 22.0589 14.9589 22.0589C14.8206 22.1971 14.633 22.2748 14.4375 22.2748C14.242 22.2748 14.0544 22.1971 13.9161 22.0589C13.9161 22.0589 13.9161 22.0588 13.916 22.0588L9.7911 17.9339L9.79118 17.9338L9.78384 17.9268C9.71306 17.8588 9.65655 17.7774 9.61762 17.6873C9.57869 17.5973 9.55812 17.5003 9.55712 17.4022C9.55613 17.3041 9.57472 17.2067 9.61181 17.1159C9.6489 17.0251 9.70374 16.9425 9.77313 16.8731C9.84252 16.8037 9.92505 16.7489 10.0159 16.7118C10.1067 16.6747 10.2041 16.6561 10.3022 16.6571C10.4003 16.6581 10.4973 16.6787 10.5873 16.7176C10.6774 16.7565 10.7588 16.8131 10.8268 16.8838L10.8267 16.8839L10.8339 16.8911L14.0839 20.1412L14.4375 20.4947L14.7911 20.1412L22.1661 12.7661ZM0.5 17C0.5 8.1781 7.6781 1 16.5 1C25.3219 1 32.5 8.1781 32.5 17C32.5 25.8219 25.3219 33 16.5 33C7.6781 33 0.5 25.8219 0.5 17ZM31.025 17C31.025 8.96341 24.5366 2.475 16.5 2.475C8.4634 2.475 1.975 8.96341 1.975 17C1.975 25.0366 8.4634 31.525 16.5 31.525C24.5366 31.525 31.025 25.0366 31.025 17Z" fill={fill} stroke={fill} />
    </svg>
)

export default RoundCheck