import {
    fetchVisualizeMapForAllMeasurment,
    fetchVisualizeMapForMeasurment, fetchVisualizeMapInfo
} from "../utils/visualize"

export const VISUALIZE_IS_PROCESSING = "VISUALIZE_IS_PROCESSING"
export const VISUALIZE_IS_PROCESSING_2 = "VISUALIZE_IS_PROCESSING_2"
export const SET_VISUALIZE_MAP_INFO = "SET_VISUALIZE_MAP_INFO"
export const SET_VISUALIZE_MEASUR_INFO = "SET_VISUALIZE_MEASUR_INFO"
export const SET_VISUALIZE_CALC_INFO = "SET_VISUALIZE_CALC_INFO"
export const SET_VISUALIZE_PARAM = "SET_VISUALIZE_PARAM"
export const SET_SHOW_EVENTS = "SET_SHOW_EVENTS"
export const SET_CHECKED_M_IDS = "SET_CHECKED_M_IDS"
export const SET_SELECTED_CALC = "SET_SELECTED_CALC"
export const SET_D_FORM_VIEW = "SET_D_FORM_VIEW"
export const SET_RAW_DATA_OBJ = "SET_RAW_DATA_OBJ"
export const SET_DEF_MIN_MAX = "SET_DEF_MIN_MAX"

const setVisualizeIsProcessing = isProcessing => ({
    type: VISUALIZE_IS_PROCESSING,
    payload: { isProcessing }
})

const setVisualizeIsProcessing2 = isProcessing2 => ({
    type: VISUALIZE_IS_PROCESSING_2,
    payload: { isProcessing2 }
})


export const setVisualizeInfo = visualizeMapInfo => ({
    type: SET_VISUALIZE_MAP_INFO,
    payload: {
        visualizeMapInfo,
        isProcessing2: false
    }
})


export const setVisualizeMeasurment = visualizeMeasurInfo => ({
    type: SET_VISUALIZE_MEASUR_INFO,
    payload: {
        visualizeMeasurInfo,
        isProcessing: false
    }
})


export const setVisualizeCalculation = visualizeCalcInfo => ({
    type: SET_VISUALIZE_CALC_INFO,
    payload: {
        visualizeCalcInfo,
        isProcessing: false
    }
})

export const setVisualizeParam = visualizeParam => ({
    type: SET_VISUALIZE_PARAM,
    payload: {
        visualizeParam,
        isProcessing: false
    }
})

export const setShowEvents = showEvents => ({
    type: SET_SHOW_EVENTS,
    payload: {
        showEvents
    }
})

export const setCheckedMIds = checkedMeasurmentIds => ({
    type: SET_CHECKED_M_IDS,
    payload: {
        checkedMeasurmentIds
    }
})

export const setDFormView = dForm => ({
    type: SET_D_FORM_VIEW,
    payload: { dForm }
})

export const setRawDataObj = rawDataObj => ({
    type: SET_RAW_DATA_OBJ,
    payload: { rawDataObj }
})

export const setSelectedCalc = selectedCalc => ({
    type: SET_SELECTED_CALC,
    payload: { selectedCalc }
})

export const setDefMinMaxObj = deflectionObjMinMax => ({
    type: SET_DEF_MIN_MAX,
    payload: { deflectionObjMinMax }
})

/**
 * Get Visualize
 * Map Info
 * @param {*} id 
 * @returns 
 */
export const getVisualizeMapInfo = (id) => async (dispatch) => {
    dispatch(setVisualizeIsProcessing2(true))
    try {
        const resp = await fetchVisualizeMapInfo(id)
        const mapInfo = !!resp && !!resp.success && !!resp.returnObject && !!resp.returnObject.result ? resp.returnObject.result : []
        dispatch(setVisualizeInfo(mapInfo))
        return true
    } catch (e) {
        dispatch(setVisualizeIsProcessing2(false))
        console.log('getVisualizeMapInfo Error: ', e)
        return e
    }
}



/**
 * Get Visualize
 * Map Info for measurment
 * @param {*} pId
 * @param {*} mId
 * @returns 
 */
export const getVisualizeMapForMeasurment = (pId, mId) => async (dispatch, getState) => {
    dispatch(setVisualizeIsProcessing(true))
    try {
        const resp = await fetchVisualizeMapForMeasurment(pId, mId)
        let mapInfo = !!resp && !!resp.success && !!resp.returnObject && !!resp.returnObject.data ? resp.returnObject.data : []

        const { visualizeMeasurInfo, checkedMeasurmentIds } = getState().visualizes

        let updatedRec = []
        if (visualizeMeasurInfo.some(rec => rec.measurementId === mId)) {
            updatedRec = visualizeMeasurInfo.map(mRec => {
                if (mRec.measurementId === mId) {
                    mRec.rawData = mapInfo
                }
                return mRec
            })
        } else {
            updatedRec = [
                ...visualizeMeasurInfo,
                {
                    measurementId: mId,
                    rawData: mapInfo
                }
            ]
        }

        dispatch(setVisualizeMeasurment(updatedRec))
        dispatch(setCheckedMIds([...checkedMeasurmentIds, mId]))
        return true
    } catch (e) {
        dispatch(setVisualizeIsProcessing(false))
        console.log('getVisualizeMapForMeasurment Error: ', e)
        return e
    }
}



/**
 * Get Visualize Map Info
 * for all measurment
 * @param {*} pId
 * @returns 
 */
export const getVisualizeMapForAllMeasurment = (pId) => async (dispatch) => {
    dispatch(setVisualizeIsProcessing(true))
    try {
        const resp = await fetchVisualizeMapForAllMeasurment(pId)
        let mapInfo = !!resp && !!resp.success && !!resp.returnObject && !!resp.returnObject.finalData ? resp.returnObject.finalData : []
        dispatch(setVisualizeMeasurment(mapInfo))
        dispatch(setCheckedMIds(mapInfo.map(a => a.measurementId)))
        return true
    } catch (e) {
        dispatch(setVisualizeIsProcessing(false))
        console.log('getVisualizeMapForAllMeasurment Error: ', e)
        return e
    }
}