import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Wallet = ({
    fill = colorCodes.BLACK_1,
    height = '20',
    width = '21',
    style = {},
    viewBox = '0 0 21 20',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M20.1379 12.3947H16.0899C15.3761 12.3947 14.6915 12.1112 14.1868 11.6064C13.682 11.1016 13.3984 10.417 13.3984 9.70322C13.3984 8.98939 13.682 8.3048 14.1868 7.80004C14.6915 7.29529 15.3761 7.01172 16.0899 7.01172H20.1379" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.5491 9.64453H16.2371" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.248 1H14.891C16.2829 1 17.6177 1.55291 18.6019 2.5371C19.5861 3.52129 20.139 4.85614 20.139 6.248V13.425C20.139 14.1142 20.0033 14.7966 19.7395 15.4333C19.4758 16.07 19.0892 16.6486 18.6019 17.1359C18.1146 17.6232 17.536 18.0098 16.8993 18.2735C16.2626 18.5373 15.5802 18.673 14.891 18.673H6.248C4.85614 18.673 3.52129 18.1201 2.5371 17.1359C1.55291 16.1517 1 14.8169 1 13.425L1 6.248C1 4.85614 1.55291 3.52129 2.5371 2.5371C3.52129 1.55291 4.85614 1 6.248 1V1Z" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.53589 5.53906H10.9359" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

)

export default Wallet