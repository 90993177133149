import React from 'react'
import { colorCodes } from '../../../constant/colors'

const DoubleArrow = ({
    fill = colorCodes.GREY_2,
    height = '17.49',
    width = '17.381',
    style = {},
    viewBox = '0 0 17.381 17.49',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g id="collaps" transform="translate(-5.056 -4.987)">
            <path id="Path_298" d="M14.144,15.068l7.8,7.811a.791.791,0,0,1-1.115,1.115L13.67,16.831,6.713,23.788a.791.791,0,0,1-1.115,0h0a.791.791,0,0,1,0-1.123l7.6-7.6a.672.672,0,0,1,.949,0Z" transform="translate(0 -2.006)" fill={fill} stroke={fill} strokeWidth="0.5" />
            <path id="Path_299" d="M14.145,5.488l7.8,7.811a.791.791,0,0,1-1.115,1.115L13.671,7.251,6.714,14.208a.791.791,0,0,1-1.115,0h0a.791.791,0,0,1,0-1.115l7.6-7.605a.672.672,0,0,1,.949,0Z" transform="translate(-0.001 0)" fill={fill} stroke={fill} strokeWidth="0.5" />
        </g>
    </svg>
)

export default DoubleArrow