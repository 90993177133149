import { useEffect, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteAssociateMail, deleteCalculation, deleteProjects, setDefaultProj, setProjectInfo, submitAssociateMail } from '../../../actions/project';
import CommonTable from '../../../components/common/CommonTable';
import ConfirmAlertPopup from '../../../components/common/ConfirmAlertPopup';
import RaptorButton from '../../../components/common/RaptorButton';
import { ID_SELECTOR } from '../../../constant';
import UploadStatus from '../UploadStatus';
import CustomPopUp from '../../../components/common/CustomPopUp';
import LabelInput from '../../../components/common/LabelInput';
import './index.scss'

const columns = [
  { Header: "table.prj_name", accessor: "projectName", isSortable: true, toolTip: 'description' },
  { Header: "table.clnt_name", accessor: (row) => !!row.client ? row.client : '-', isSortable: false },
  { Header: "table.total_cal", accessor: (row) => !!row.calculations ? row.calculations.length : 0, Filter: false, isSortable: true },
  { Header: "table.created_at", accessor: "createdAt", Filter: false, isSortable: true, type: 'date' }
]

const childrenColumns = [
  { Header: "table.calc_file_name", accessor: "calculationName", Filter: false },
  {
    Header: "table.progress", accessor: (row) => {
      const progressVal = ((row.runtimeStatus === "Running") || (row.runtimeStatus === "Pending")) ? 60 : ((row.runtimeStatus === "Complete") || (row.runtimeStatus === "Error") ? 100 : 0)
      const progressTxt = ((row.runtimeStatus === "Running") || (row.runtimeStatus === "Pending")) ? "In Progress" : (row.runtimeStatus === "Complete" ? "Complete" : "Error")
      return <UploadStatus className={row.runtimeStatus} progress={progressVal} text={progressTxt} uniqueSelector={row._id} />
    }, Filter: false
  },
  { Header: "table.repo_length", accessor: "ReportLengthM", Filter: false },
  { Header: "table.normo_load", accessor: "NormalizationLoadN", Filter: false },
  { Header: "table.normo_temp", accessor: "NormalizationTemperatureC", Filter: false },
  { Header: "table.created_at", accessor: "createdAt", Filter: false, type: 'date' }
]

export function ProjectList() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [multiIDs, setMultiIDs] = useState([])
  // const [multiRecs, setMultiRecs] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [accessWin, setAccessWin] = useState(false)
  const [emailValue, setEmailValue] = useState('')

  const { projects, isProcessing, projectInfo } = useSelector(state => state.projects)

  const onCheckboxChange = (e, row) => {
    let newIDsList = multiIDs
    if (e.target.checked) {
      newIDsList = [...new Set([...multiIDs, row[ID_SELECTOR]])]
    } else {
      newIDsList = newIDsList.filter(id => id !== row[ID_SELECTOR])
    }
    setMultiIDs(newIDsList)
    // const multiRec = newIDsList.map(id => projects.find(p => parseInt(p[ID_SELECTOR]) === parseInt(id)))
    // setMultiRecs(multiRec)
  }


  useEffect(() => {
    if (!!projects && !!projects.length) {
      const isAllChecked = (currentValue) => multiIDs.includes(currentValue[ID_SELECTOR])
      setCheckAll(projects.every(isAllChecked))
    }
  }, [multiIDs, projects])

  const memoizeMultiIDs = records => records.map(rec => rec[ID_SELECTOR])
  const allMultiIDs = useMemo(() => memoizeMultiIDs(projects), [projects])

  useEffect(() => {
    if (checkAll) {
      setMultiIDs(allMultiIDs)
    }
  }, [checkAll, allMultiIDs])

  const handleDeleteProject = async (ids = multiIDs) => {
    const resp = await dispatch(deleteProjects(ids))
    if (!!resp) {
      setCheckAll(false)
      setMultiIDs([])
    }
  }

  const unCheckAll = () => {
    setCheckAll(false)
    setMultiIDs([])
  }

  const submitForm = async (e) => {
    e.preventDefault()
    try {
      await dispatch(submitAssociateMail(emailValue.toLowerCase(), projectInfo._id))
    } catch (e) {
      console.log('check error submitForm ', e)
    }
  }

  const deleteMsg = 'message.prjct_dlt_msg'
  return (
    <>
      {
        !!accessWin && <CustomPopUp handleClose={() => {
          setAccessWin(false)
          setEmailValue("")
          dispatch(setProjectInfo({}))
        }} className=" " title={t("project.user_associate")}>
          <form className="user-associate-form-context" onSubmit={e => submitForm(e)}>
            <div className="d-flex mt-40">
              <LabelInput type='email'
                onChange={e => setEmailValue(e.target.value)} value={emailValue}
                label={t("form_input.enter_email")} placeholder={t("form_input.enter_email")} />
            </div>
            <div className="d-flex mt-10">
              <RaptorButton type='submit' title={t("button.save")} className='dlt-prj-btn' disabled={!emailValue} />
            </div>
            <hr />
            <div className="associated-sec">
              <h5>{t("form_input.associated_users")}</h5>
              <div>{(!!projectInfo && !!projectInfo.ownership && projectInfo.ownership.length) || 0} {t("form_input.result")}(s)</div>
              {
                !!projectInfo && !!projectInfo.ownership && !!projectInfo.ownership.length && projectInfo.ownership.map((r, i) =>
                  <div key={i}>{`${r.name || r.userName} - (${r.email})`} <span className='c-pointer' onClick={() => dispatch(deleteAssociateMail(r.email, projectInfo._id))}>X</span></div>)
              }
            </div>
          </form>
        </CustomPopUp>
      }
      <div className='between-flex'>
        <div className="page-info">
          <div className="page-info-title">{t("project.all_proj")}</div>
        </div>
        <div className='prj-btn-holder'>
          {
            !!multiIDs.length &&
            <RaptorButton title={t("button.dlt_proj")} className='dlt-prj-btn' onClick={() => confirmAlert({
              customUI: values => <ConfirmAlertPopup {...values} onConfirm={() => handleDeleteProject()}
                title="delete_dialog.dlt_proj" message={deleteMsg} />
            })} />
          }
          <RaptorButton title={t("button.create_proj")} className='create-prj-btn' onClick={() => navigate("/raptor/projects/create")} />
        </div>
      </div>

      <div className='project-context'>
        <div className='project-context-table o-auto center-flex'>
          {
            !!projects.length ?
              <CommonTable columns={columns} data={projects}
                onCheckboxChange={(e, info) => onCheckboxChange(e, info || {})}
                handleCheckAll={(checked) => !!checked ? setCheckAll(checked) : unCheckAll()}
                checkAll={checkAll}
                actionBtn={true}
                showMultiCheckbox={true}
                multiIDs={multiIDs}
                actionBtnType={2}
                deleteIcon="trash2"
                childrenColumns={childrenColumns}
                childKeyName="calculations"
                deleteMsg={t("delete_dialog.project_permanent_dlt")}
                customFnIcon="key"
                customFn={row => {
                  setAccessWin(true)
                  dispatch(setProjectInfo(row.original))
                }}
                onRowClick={(row) => {
                  dispatch(setDefaultProj(row.original[ID_SELECTOR]))
                  navigate(`/raptor/projects/view/${row.original[ID_SELECTOR]}`)
                }}
                childRowClick={(row) => {
                  navigate(`/raptor/projects/view/${row.original.projectId}?calcId=${row.original[ID_SELECTOR]}`)
                }}
                childActionBtnType={2}
                childDeleteFn={(info) => {
                  dispatch(deleteCalculation(info.original.projectId, info.original[ID_SELECTOR]))
                }}
                childPagination={false}
                deleteFn={(info) => handleDeleteProject([info.original[ID_SELECTOR]])} /> :
              <h3 className="center-flex h3-fetching-text">{!!isProcessing ? `${t('project.fetching')}...` : t('project.no_records')}</h3>
          }
        </div>
      </div>
    </>
  )
}
