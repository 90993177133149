import { useTranslation } from "react-i18next"
import LabelInput from "../../LabelInput"
import "./index.scss"

const CommonInputFilter = ({ column }) => {
    const { t } = useTranslation()
    const labelVal = <div className="">{(typeof column.Header === "function") ? (column.render("Header")) : t(column.render("Header"))}{!!column.isSortable && <img src={require('../../../../assets/images/up-down-arrow.svg').default} alt="Sort" className="ml-10 c-pointer" />}</div>
    return (
        ((column.disableFilter !== undefined) || (column.disableFilter !== 'undefined')) && !!column.disableFilter ?
            labelVal : <LabelInput
                className="common-search-input-filter"
                labelComp={labelVal}
                value={column.filterValue || ''}
                onChange={e => {
                    column.setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                placeholder={t(column.placeholder || '')}
            />
    )
}

export default CommonInputFilter