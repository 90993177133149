import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../../components/common/Icons'
import LabelSelectBox from '../../../components/common/LabelSelectBox'
import { colorCodes } from '../../../constant/colors'
import BasicStaticsCard from '../../Projects/BasicStaticsCard'
import MeasurmentList from '../../Projects/MeasurmentList'
import { getVisualizeMapForMeasurment, setShowEvents, setVisualizeParam } from '../../../actions/visualize'
import RaptorCheckbox from '../../../components/common/RaptorCheckbox'
import './index.scss'

const MeasurmentTab = () => {

    const { t } = useTranslation()
    const { projectInfo } = useSelector(state => state.projects)
    const { visualizeParam, showEvents, visualizeMeasurInfo } = useSelector(state => state.visualizes)
    const { defaultProjID } = useSelector(state => state.global)
    const [fullView, setFullView] = useState(false)
    const [staticForm, setStaticForm] = useState(false)
    const dispatch = useDispatch()

    const isActive = true

    const calbList = !!projectInfo && !!projectInfo.calibrationFiles ? projectInfo.calibrationFiles : []
    for (var i = 0; i < calbList.length; i++) {
        calbList[i]['fileName'] = (calbList[i]['fileName']).replace('_calibration_ready_for_upload.zip', '')
    }

    const inputFilesArr = !!projectInfo && !!projectInfo.inputFiles ? projectInfo.inputFiles : []

    let filteredInputFiles = []
    for (var j = 0; j < inputFilesArr.length; j++) {
        if (!!inputFilesArr[j]['taggedCalibrationFileId']) {
            filteredInputFiles.push(inputFilesArr[j])
        }
    }

    projectInfo.inputFiles = filteredInputFiles
    return (
        <div className="measurment-tab-container">
            <div className='image-holder center-flex'>
                <img src={require('./dummy-image.png')} alt="Road" />
            </div>
            {!!staticForm && <BasicStaticsCard handleClose={() => setStaticForm(false)} data={visualizeMeasurInfo} />}
            <div className='tab-btn-holder evenly-flex'>
                <div className='btn-holder center-flex' onClick={() => setStaticForm(true)}>
                    <button className={`tab-icon ${isActive ? 'active' : ''}`}>
                        <Icon fill={isActive ? colorCodes.WHITE_1 : colorCodes.BLUE_1} name="statics" />
                    </button>
                    <div className='btn-title'>{t("button.statistics")}</div>
                </div>
            </div>

            <div className='select-visual-parameter'>
                <LabelSelectBox label={t("visualize_map.form.visual_param")}
                    value={visualizeParam}
                    handleOnChange={e => dispatch(setVisualizeParam(e.value))}
                    options={[
                        { label: 'Surface Temperature', value: 'SurfTemp' },
                        { label: 'Air Temperature', value: 'AirTemp' },
                        { label: 'Driving Speed', value: 'Speed' }
                    ]} />
            </div>

            <div className="show-events-checkbox-container" onClick={e => { e.stopPropagation() }}>
                <RaptorCheckbox checked={showEvents} onChange={e => dispatch(setShowEvents(e.target.checked))}></RaptorCheckbox>
                <span>{t("visualize_map.show_events")}</span>
            </div>

            <div className='select-visual-hr'></div>
            <div className='select-multi-measur'>
                <div className='select-multi-measur-title'>{t("visualize_map.form.multi_title")}</div>
                <div className={`toogle-full-view-btn ${fullView ? 'active' : ''}`} onClick={() => setFullView(!fullView)}>
                    <Icon name="double-arrow" />
                </div>
            </div>
            <div className='multi-options' id='visualize-table-selector-div'>
                <MeasurmentList onlyTable={true} actionBtn={false} fullView={fullView}
                    setGlobalState={true}
                    measurmentInstance={projectInfo}
                    onRowClick={row => {
                        dispatch(getVisualizeMapForMeasurment(defaultProjID, row.original._id))
                    }}
                />
            </div>
        </div>
    )
}

export default MeasurmentTab