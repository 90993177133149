import { toast } from "react-toastify"
import SuccessDialog from "../components/common/SuccessDialog"
import {
    addAssociateMail, checkCalcName, checkProjectName, createNewCalculation,
    deleteCalculationInfo, deleteCalibrationInputFile, deleteMeasurmentInputFile,
    deleteMultiMeasurmentInputFile, deleteProjectInfo, editMeasurmentInfo, editProjectInfo, fetchCalcRDIData,
    fetchCountryList, fetchProjectCalculations, fetchProjectDetails, fetchProjectList,
    removeAssociateMail, saveProject, setDefaultProject, tagCalibrationInputFile, unzipUploadFile, updateCalRateResult,
    uploadCalibrationInputFile, uploadMeasurmentFile
} from "../utils/project"
import { setDefaultProjId } from "./global"
import { getVisualizeMapInfo } from "./visualize"

export const CALC_IS_VALIDATING = "CALC_IS_VALIDATING"
export const PROJECT_IS_PROCESSING = "PROJECT_IS_PROCESSING"
export const RDI_IS_PROCESSING = "RDI_IS_PROCESSING"
export const SET_ALL_PROJECT = "SET_ALL_PROJECT"
export const CREATE_PROJS_SUCCESS = "CREATE_PROJS_SUCCESS"
export const SET_PROJ_INFO = "SET_PROJ_INFO"
export const EDIT_PROJ_SUCCESS = "EDIT_PROJ_SUCCESS"
export const DELETE_PROJS_SUCCESS = "DELETE_PROJS_SUCCESS"
export const SET_ALL_CALCULATIONS = "SET_ALL_CALCULATIONS"
export const SET_ALL_COUNTRIES = "SET_ALL_COUNTRIES"
export const ADD_NEW_CALCULATION = "ADD_NEW_CALCULATION"
export const SET_CALC_GRAPH_INFO = "SET_CALC_GRAPH_INFO"
export const UPDATE_OWNERSHIP = "UPDATE_OWNERSHIP"
export const DELETE_CALC_SUCCESS = "DELETE_CALC_SUCCESS"

const setCalcIsValidating = calcIsValidating => ({
    type: CALC_IS_VALIDATING,
    payload: { calcIsValidating }
})

const setProjIsProcessing = isProcessing => ({
    type: PROJECT_IS_PROCESSING,
    payload: { isProcessing }
})

const setRDIIsProcessing = isProcessingRDI => ({
    type: RDI_IS_PROCESSING,
    payload: { isProcessingRDI }
})

const setAllProjects = projects => ({
    type: SET_ALL_PROJECT,
    payload: {
        isProcessing: false,
        projects
    }
})

const editProjSuccess = (id, projectInfo) => ({
    type: EDIT_PROJ_SUCCESS,
    id, projectInfo
})

const createProjectsSuccess = (project) => ({
    type: CREATE_PROJS_SUCCESS,
    project
})

export const setProjectInfo = (projectInfo) => ({
    type: SET_PROJ_INFO,
    payload: {
        isProcessing: false,
        projectInfo
    }
})

const deleteProjectsSuccess = (projectIDs) => ({
    type: DELETE_PROJS_SUCCESS,
    projectIDs
})

const setAllCountries = countries => ({
    type: SET_ALL_COUNTRIES,
    payload: {
        isProcessing: false,
        countries
    }
})

const setAllCalculations = (calculations, pid) => ({
    type: SET_ALL_CALCULATIONS,
    payload: {
        isProcessing: false,
        calculations, pid
    }
})

const addNewCalculation = (calculation, pid) => ({
    type: ADD_NEW_CALCULATION,
    payload: {
        isProcessing: false,
        calculation, pid
    }
})

export const setCalcGraphInfo = calcGraphInfo => ({
    type: SET_CALC_GRAPH_INFO,
    payload: {
        isProcessing: false,
        calcGraphInfo
    }
})


const updateOwnershipSuccess = (id, ownership) => ({
    type: UPDATE_OWNERSHIP,
    id, ownership
})


const deleteCalcSuccess = (pId, cId) => ({
    type: DELETE_CALC_SUCCESS,
    pId, cId
})


/**
 * Fetch All Countries
 */
export const fetchAllCountries = () => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await fetchCountryList()
        const countryResp = !!resp && !!resp.success && !!resp.returnObject ? resp.returnObject : []
        dispatch(setAllCountries(countryResp))
        return true
    } catch (e) {
        dispatch(setProjIsProcessing(false))
        console.log('fetchAllCountries Error: ', e)
        return e
    }
}



/**
 * Fetch All Projects
 */
export const fetchAllProjects = () => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await fetchProjectList()
        const projectsResp = !!resp && !!resp.returnObject && !!resp.returnObject.allProjects ? resp.returnObject.allProjects : []
        dispatch(setAllProjects(projectsResp))
        return true
    } catch (e) {
        dispatch(setProjIsProcessing(false))
        console.log('fetchAllProjects Error: ', e)
        return e
    }
}


/**
 * Fetch Project Info
 * @param {*} id 
 * @returns 
 */
export const fetchProjectInfo = (id) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await fetchProjectDetails(id)
        if (!!resp && !!resp.success) {
            dispatch(setProjectInfo(resp.returnObject || {}))
        }
        return true
    } catch (e) {
        dispatch(setProjIsProcessing(false))
        console.log('fetchProjectInfo Error: ', e)
        if (!!e && !!e.response && !!e.response.data && !e.response.data.success && !!e.response.data.message) {
            toast.error(e.response.data.message)
        }
        return e
    }
}




/**
 * Set default Project
 * @param {*} id 
 * @returns 
 */
export const setDefaultProj = (id) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await setDefaultProject(id, { projectId: id })
        if (!!resp && !!resp.success) {
            dispatch(setDefaultProjId(resp.returnObject.projectId || false))
            if (resp.returnObject.projectId) {
                dispatch(getVisualizeMapInfo(resp.returnObject.projectId))
            }
        }
        return true
    } catch (e) {
        dispatch(setProjIsProcessing(false))
        console.log('setDefaultProj Error: ', e)
        if (!!e && !!e.response && !!e.response.data && !e.response.data.success && !!e.response.data.message) {
            toast.error(e.response.data.message)
        }
        return e
    }
}



/**
 * Check Project Name
 * @param {*} name
 * @param {*} id
 * @returns 
 */
export const checkProjectUniqueName = (name, id = false) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await checkProjectName({
            "projectName": name,
            ...(!!id && { "projectId": id })
        })
        dispatch(setProjIsProcessing(false))
        if (!!resp && !resp.success && !!resp.returnObject && !!resp.returnObject.projectNameExists) {
            return false
        }
        return true
    } catch (err) {
        console.log('checkProjectUniqueName Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}



/**
 * Create Project
 * @param {*} data
 * @returns 
 */
export const createProject = (data, t) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await saveProject(data)
        dispatch(setProjIsProcessing(false))

        if (!!resp && !!resp.success && !!resp.returnObject && !!resp.returnObject.projectCreated) {
            dispatch(createProjectsSuccess(resp.returnObject.projectCreated))
            return resp.returnObject.projectCreated
        } else if (!!resp && !resp.success && !!resp.returnObject && !!resp.returnObject.projectNameExists) {
            toast.error(t("message.error.prjct_name_exist"))
        }
        return false
    } catch (err) {
        console.log('createProject Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}



/**
 * Edit Project
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const editProject = (id, data) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await editProjectInfo(id, {
            ...(!!data.projectName && { "projectName": data.projectName }),
            ...(!!data.country && { "country": data.country }),
            ...(!!data.client && { "client": data.client }),
            "description": data.description || ""
        })
        dispatch(setProjIsProcessing(false))

        if (!!resp && !!resp.success && !!resp.returnObject) {
            dispatch(editProjSuccess(id, resp.returnObject))
            return true
        }
        return false
    } catch (err) {
        console.log('editProject Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}


/**
 * Delete Multiple
 * Project IDs
 * @param {*} ids
 * @returns 
 */
export const deleteProjects = (ids) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await deleteProjectInfo(JSON.stringify({ projectIds: ids }))
        dispatch(setProjIsProcessing(false))

        const returnObj = !!resp && !!resp.returnObject ? resp.returnObject : false
        if (!!resp && !!resp.success && !!returnObj) {
            dispatch(deleteProjectsSuccess(ids))

            const successCount = returnObj.filter(r => r.deleteStatus)
            const failCount = returnObj.filter(r => !r.deleteStatus)

            if (successCount.length) {
                toast.success(<SuccessDialog message={`${successCount.length} project(s) deleted successfully`} />)
            }

            if (failCount.length) {
                toast.error(`${failCount.length} project(s) deletion failed`)
            }
        }
        return true
    } catch (err) {
        console.log('deleteProjects Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}


/**
 * Upload Measurment input
 * files for project
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const uploadMeasurmentInputFile = (id, data) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await uploadMeasurmentFile(id, data)
        dispatch(setProjIsProcessing(false))
        if (!!resp && !!resp.success) {
            return !!resp.returnObject ? resp.returnObject : false
        }
        return false
    } catch (err) {
        console.log('uploadMeasurmentInputFile Error:', err);
        toast.error('Something went wrong, Please try again')
        dispatch(setProjIsProcessing(false))
        return false
    }
}


/**
 * Upload Calibration
 * files for project
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const uploadCalibrationFile = (id, data) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await uploadCalibrationInputFile(id, data)
        dispatch(setProjIsProcessing(false))

        if (!!resp && !!resp.success) {
            toast.success(<SuccessDialog message='Uploaded Successfully' />)
            return !!resp.returnObject ? resp.returnObject : false
        }
        return false
    } catch (err) {
        console.log('uploadCalibrationFile Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}


/**
 * Delete Measurment file
 * @param {*} blobName
 * @returns 
 */
export const deleteMeasurmentFile = (id, blobName = false, blobArr = false) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        let resp = false
        if (blobName) {
            resp = await deleteMeasurmentInputFile(id, blobName)
        } else {
            resp = await deleteMultiMeasurmentInputFile(id, blobArr)
        }
        dispatch(setProjIsProcessing(false))

        const returnObj = !!resp && !!resp.returnObject ? resp.returnObject : false

        if (!!resp && !!resp.success && !!returnObj && !!returnObj.updatedProject) {
            dispatch(setProjectInfo(returnObj.updatedProject || {}))
            if (!!blobName) {
                toast.success(resp.message)
            }
        }

        if (!!resp && !!resp.success && !!returnObj &&
            !!returnObj.inputFilesDeletion && !!returnObj.inputFilesDeletion.length) {

            const successCount = (returnObj.inputFilesDeletion).filter(r => r.deleteStatus)
            const failCount = (returnObj.inputFilesDeletion).filter(r => !r.deleteStatus)

            if (successCount.length) {
                toast.success(<SuccessDialog message={`${successCount.length} files(s) deleted successfully`} />)
            }

            if (failCount.length) {
                toast.error(`${failCount.length} file(s) deletion failed`)
            }
        }
        return true
    } catch (err) {
        console.log('deleteMeasurmentFile Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}


/**
 * Delete Calibration file
 * @param {*} blobName
 * @returns 
 */
export const deleteCalibrationFile = (id, blobName) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await deleteCalibrationInputFile(id, blobName)
        dispatch(setProjIsProcessing(false))

        const returnObj = !!resp && !!resp.returnObject ? resp.returnObject : false

        if (!!resp && !!resp.success && !!returnObj && !!returnObj.updatedProject) {
            dispatch(setProjectInfo(returnObj.updatedProject || {}))
        }

        if (!!resp && !!resp.success && !!returnObj &&
            !!returnObj.calibraitionFileDeletion && !!returnObj.calibraitionFileDeletion.length) {

            const successCount = (returnObj.calibraitionFileDeletion).filter(r => r.deleteStatus)
            const failCount = (returnObj.calibraitionFileDeletion).filter(r => !r.deleteStatus)

            if (successCount.length) {
                toast.success(<SuccessDialog message={`${successCount.length} calibration(s) deleted successfully`} />)
            }

            if (failCount.length) {
                toast.error(`${failCount.length} calibration(s) deletion failed`)
            }
        }
        return true
    } catch (err) {
        console.log('deleteCalibrationFile Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}



/**
 * Tag Calibration file
 * @param {*} data
 * @returns 
 */
export const tagCalibrationFile = (id, data) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await tagCalibrationInputFile(id, data)
        dispatch(setProjIsProcessing(false))

        if (!!resp && !!resp.success && !!resp.returnObject && !!resp.returnObject.project) {
            const currentProj = resp.returnObject.project.find(p => p._id === id)
            dispatch(setProjectInfo(currentProj || {}))
            return true
        }
        return false
    } catch (err) {
        console.log('tagCalibrationFile Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}



/**
 * Update Rate Result
 * @param {*} data
 * @returns 
 */
export const updateRateResult = (data, id) => async (dispatch) => {
    if (!!data && !data.calculationId) {
        return alert("Please choose a Analysis to dropdown")
    }
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await updateCalRateResult(id, data)
        dispatch(setProjIsProcessing(false))

        if (!!resp && !!resp.success) {
            dispatch(getProjectCalculations(id))
        }
        toast.success(<SuccessDialog message='Updated Successfully' />)
        return true
    } catch (err) {
        console.log('updateRateResult Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}


/**
 * Fetch Project's Calculations
 * @param {*} id 
 * @param {*} loaderStatus 
 * @returns 
 */
export const getProjectCalculations = (id, loaderStatus = true) => async (dispatch) => {
    dispatch(setProjIsProcessing(loaderStatus))
    try {
        const resp = await fetchProjectCalculations(id)
        if (!!resp && !!resp.success) {
            const calcResp = !!resp.returnObject && !!resp.returnObject.result ? resp.returnObject.result : []
            dispatch(setAllCalculations(calcResp, id))

            let calcInProgs = false
            for (var i = 0; i < calcResp.length; i++) {
                if (!!calcResp[i] && !!calcResp[i].runtimeStatus && ((calcResp[i].runtimeStatus === "Pending") || (calcResp[i].runtimeStatus === "Running"))) {
                    calcInProgs = true
                }
            }
            if (calcInProgs) {
                // HINT: We're removing for some time
                setTimeout(() => {
                    dispatch(getProjectCalculations(id, false))
                }, 10000)
            }
        }
        return true
    } catch (e) {
        dispatch(setProjIsProcessing(false))
        console.log('getProjectCalculations Error: ', e)
        return e
    }
}



/**
 * Fetch Calculation's RDI Data
 * @param {*} id 
 * @param {*} calcId 
 * @param {*} inputId 
 * @returns 
 */
export const getCalcRDIData = (id, calcId, inputId) => async (dispatch) => {
    dispatch(setRDIIsProcessing(true))
    try {
        const resp = await fetchCalcRDIData(id, {
            "calculationId": calcId,
            "inputFileId": inputId
        })
        if (!!resp && !!resp.success) {
            dispatch(setCalcGraphInfo(!!resp.returnObject ? resp.returnObject : {}))
        }
        dispatch(setRDIIsProcessing(false))
        return true
    } catch (e) {
        dispatch(setRDIIsProcessing(false))
        console.log('getCalcRDIData Error: ', e)
        return e
    }
}


export const createCalculation = (id, data, t) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await createNewCalculation(id, data)
        dispatch(setProjIsProcessing(false))
        if (!!resp && !!resp.success) {
            const savedCalcInfo = !!resp.returnObject && !!resp.returnObject.result && !!resp.returnObject.result[0] ? resp.returnObject.result[0] : {}
            dispatch(addNewCalculation(savedCalcInfo, id))
            let calcInProgs = false

            if (!!savedCalcInfo && !!savedCalcInfo.runtimeStatus && ((savedCalcInfo.runtimeStatus === "Pending") || (savedCalcInfo.runtimeStatus === "Running"))) {
                calcInProgs = true
            }

            if (calcInProgs) {
                // HINT: We're removing for some time
                setTimeout(() => {
                    dispatch(getProjectCalculations(id, false))
                }, 10000)
            }

            return savedCalcInfo
        } else if (!!resp && !resp.success && !!resp.returnObject && !!resp.returnObject.calculationsNameExists) {
            toast.error(t("message.error.calc_name_exist"))
        }
        return true
    } catch (e) {
        dispatch(setProjIsProcessing(false))
        console.log('createCalculation Error: ', e)
        return false
    }
}



/**
 * Check Calculation Name
 * @param {*} name
 * @param {*} projectId
 * @param {*} signal
 * @returns 
 */
export const checkCalcUniqueName = (name, projectId, signal) => async (dispatch) => {
    dispatch(setCalcIsValidating(true))
    try {
        const resp = await checkCalcName(projectId, {
            "calculationName": name
        }, signal)
        dispatch(setCalcIsValidating(false))
        if (!!resp && !resp.success && !!resp.returnObject && !!resp.returnObject.calculationsNameExists) {
            return false
        }
        return true
    } catch (err) {
        console.log('checkCalcUniqueName Error:', err);
        dispatch(setCalcIsValidating(false))
        return false
    }
}



/**
 * Delete Calculation
 * @param {*} pId
 * @param {*} cId
 * @returns 
 */
export const deleteCalculation = (pId, cId) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await deleteCalculationInfo(pId, cId)
        dispatch(setProjIsProcessing(false))

        if (!!resp) {
            dispatch(deleteCalcSuccess(pId, cId))
        }
        return true
    } catch (err) {
        console.log('deleteCalculation Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}



/**
 * Submit Associate Email
 * @param {*} email
 * @param {*} projectId
 * @returns 
 */
export const submitAssociateMail = (email, projectId) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await addAssociateMail(projectId, {
            projectId,
            email
        })
        dispatch(setProjIsProcessing(false))
        if (!!resp && !!resp.success && !!resp.returnObject && !!resp.returnObject.email) {
            toast.error(resp.message)
            return true
        } else if (!!resp && !!resp.success && !!resp.returnObject && !!resp.returnObject.allUsers) {
            dispatch(updateOwnershipSuccess(projectId, resp.returnObject.allUsers))
            toast.success(<SuccessDialog message={"User added successfully."} />)
            return true
        }
        return false
    } catch (err) {
        console.log('submitAssociateMail Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}


/**
 * Delete Associate Email
 * @param {*} email
 * @param {*} projectId
 * @returns 
 */
export const deleteAssociateMail = (email, projectId) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await removeAssociateMail(projectId, {
            projectId,
            email
        })
        dispatch(setProjIsProcessing(false))
        if (!!resp && !!resp.success && !!resp.returnObject && !!resp.returnObject.allUsers) {
            dispatch(updateOwnershipSuccess(projectId, resp.returnObject.allUsers))
            toast.success(<SuccessDialog message={resp.message} />)
            return true
        }
        return false
    } catch (err) {
        console.log('deleteAssociateMail Error:', err);
        dispatch(setProjIsProcessing(false))
        return false
    }
}



/**
 * Unzip Uploaded Measurment input
 * files for project
 * @param {*} id 
 * @param {*} blobNameArr 
 * @returns 
 */
export const unzipUploadedMeasurmentInputFile = (id, blobNameArr) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await unzipUploadFile(id, { upoadedBlobName: blobNameArr })
        dispatch(setProjIsProcessing(false))
        if (!!resp && !!resp.success) {
            return true
        }
        return false
    } catch (err) {
        console.log('unzipUploadedMeasurmentInputFile Error:', err);
        toast.error('Something went wrong during unzip, Please try again')
        dispatch(setProjIsProcessing(false))
        return false
    }
}



/**
 * Updated Measurment Info
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const updateMeasurment = (id, data) => async (dispatch) => {
    dispatch(setProjIsProcessing(true))
    try {
        const resp = await editMeasurmentInfo(id, data)
        dispatch(setProjIsProcessing(false))
        if (!!resp && !!resp.success) {
            if (!!resp.returnObject) {
                dispatch(editProjSuccess(id, resp.returnObject))
                dispatch(setProjectInfo(resp.returnObject))
            }
            return true
        }
        return false
    } catch (err) {
        console.log('updateMeasurment Error:', err);
        toast.error('Something went wrong during update, Please try again')
        dispatch(setProjIsProcessing(false))
        return false
    }
}