import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from "moment"
import { BlobServiceClient } from '@azure/storage-blob'
import { ZipReader, BlobReader, BlobWriter } from '@zip.js/zip.js'
import { deleteCalibrationFile, setProjectInfo, uploadCalibrationFile } from '../../../actions/project';
import Icon from '../../../components/common/Icons';
import CommonTable from '../../../components/common/CommonTable';
import { ID_SELECTOR, validInputFileExt } from '../../../constant';
import SpinLoader2 from '../../../components/common/SpinLoader2';
import RaptorButton from '../../../components/common/RaptorButton';
import './index.scss'

const columns = [
  { Header: "table.file_name", accessor: "fileName", Filter: false, isSortable: true },
  { Header: "table.created_on", accessor: "calibrationDateTime", Filter: false, isSortable: true, type: 'date' }
]

export function CalibrationList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const fileInput2Ref = useRef()
  const { id } = useParams()
  const [multiIDs, setMultiIDs] = useState([])
  const [multiBNs, setMultiBNs] = useState([])
  // const [multiRecs, setMultiRecs] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [mFiles, setMFiles] = useState([])
  const [progressVal, setProgressVal] = useState(0)

  const { globalActiveTab } = useSelector(state => state.global)
  const projectDetails = useSelector(state => state.projects)
  const projectInfo = projectDetails.projectInfo
  const isProcessing = projectDetails.isProcessing

  const calibrations = useMemo(() => !!projectInfo && !!projectInfo.calibrationFiles ? projectInfo.calibrationFiles : [], [projectInfo])

  const onCheckboxChange = (e, row) => {
    let newIDsList = multiIDs
    let newBNsList = multiBNs
    if (e.target.checked) {
      newIDsList = [...new Set([...multiIDs, row[ID_SELECTOR]])]
      newBNsList = [...new Set([...multiBNs, row.blobName])]
    } else {
      newIDsList = newIDsList.filter(id => id !== row[ID_SELECTOR])
      newBNsList = newBNsList.filter(id => id !== row.blobName)
    }
    setMultiIDs(newIDsList)
    setMultiBNs(newBNsList)
    // const multiRec = newIDsList.map(id => projects.find(p => parseInt(p[ID_SELECTOR]) === parseInt(id)))
    // setMultiRecs(multiRec)
  }


  useEffect(() => {
    if (!!calibrations && !!calibrations.length) {
      const isAllChecked = (currentValue) => multiIDs.includes(currentValue[ID_SELECTOR])
      setCheckAll(calibrations.every(isAllChecked))
    }
  }, [multiIDs, calibrations])

  const memoizeMultiIDs = records => records.map(rec => rec[ID_SELECTOR])
  const allMultiIDs = useMemo(() => memoizeMultiIDs(calibrations), [calibrations])

  const memoizeMultiBNs = records => records.map(rec => rec.blobName)
  const allMultiBNs = useMemo(() => memoizeMultiBNs(calibrations), [calibrations])

  useEffect(() => {
    if (checkAll) {
      setMultiIDs(allMultiIDs)
    }
  }, [checkAll, allMultiIDs])

  useEffect(() => {
    if (checkAll) {
      setMultiBNs(allMultiBNs)
    }
  }, [checkAll, allMultiBNs])

  const unCheckAll = () => {
    setCheckAll(false)
    setMultiIDs([])
    setMultiBNs([])
  }

  useEffect(() => {
    unCheckAll()
  }, [globalActiveTab])

  useEffect(() => {
    if (!!fileInput2Ref && !!fileInput2Ref.current) {
      fileInput2Ref.current.value = ""
    }
  }, [mFiles])


  const uploadChoosedFile = async (files, sasTokenObj) => {
    try {
      const uploadOptions = {
        maxSingleShotSize: 4 * 1000 * 1000, // 4 MB chunks
        onProgress: (p) => setProgressVal(Math.round(p.loadedBytes / files.size * 1000) / 10) // progress percentage with one decimal
      }
      setIsLoading(true)
      const blobServiceClient = new BlobServiceClient(`${sasTokenObj.storageAccountUri}?${sasTokenObj.sasToken}`);
      const containerClient = blobServiceClient.getContainerClient(sasTokenObj.containerName)
      const blockBlobClient = containerClient.getBlockBlobClient(sasTokenObj.blobName)

      const uploadResponse = await blockBlobClient.uploadData(files, uploadOptions)
      setIsLoading(false)
      return uploadResponse
    } catch (e) {
      setIsLoading(false)
      console.log('Upload Input File Error:', e)
      toast.error('Something went wrong, Please upload again.')
      return false
    }
  }

  const handleFileChoose = async (data) => {
    const { files } = data.target

    if (!files.length) {
      return false
    }

    for (var j = 0; j < files.length; j++) {
      if (!validInputFileExt.includes(files[j].type)) {
        toast.error(t("message.error.file_format_invalid"))
        return false
      }
    }

    let filesArray = []
    for (var i = 0; i < files.length; i++) {
      filesArray.push(files[i])

      // Extract general params (avg speed, etc.)
      const blobReader = new BlobReader(files[i]);
      const zipReader = new ZipReader(blobReader);
      const blobWriter = new BlobWriter('application/json');
      // Get names of files inside zip
      const entries = await zipReader.getEntries();

      // Find general params file
      const generalParamsFile = entries.find((entry) => entry.filename.match(/calibration_date\.json/))
      if (!!generalParamsFile) {
        const fileData = await generalParamsFile.getData(blobWriter);
        if (!!fileData) {

          const generalParams = JSON.parse(await fileData.text());
          const isDate = function (date) {
            return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
          }
          if (!!generalParams) {
            const uploadResp = await dispatch(uploadCalibrationFile(id, {
              "fileName": files[i].name,
              "calibrationDateTime": !!isDate(generalParams.record_date) ? new Date(moment(new Date(generalParams.record_date)).format('lll')).getTime() : new Date(moment(new Date()).format('lll')).getTime()
            }))

            if (!!uploadResp && !!uploadResp.sasTokenObj) {
              const result = await uploadChoosedFile(files[i], uploadResp.sasTokenObj)
              if (!!result && !!uploadResp && !!uploadResp.updatedProject && !!uploadResp.updatedProject._id) {
                dispatch(setProjectInfo(uploadResp.updatedProject))
              }
            }

            return true
          }
        }
      } else {
        // HINT: I'll remove it later
        const uploadResp = await dispatch(uploadCalibrationFile(id, {
          "fileName": files[i].name,
          "calibrationDateTime": new Date(moment(new Date()).format('lll')).getTime()
        }))
        if (!!uploadResp && !!uploadResp.sasTokenObj) {
          const result = await uploadChoosedFile(files[i], uploadResp.sasTokenObj)
          if (!!result && !!uploadResp && !!uploadResp.updatedProject && !!uploadResp.updatedProject._id) {
            dispatch(setProjectInfo(uploadResp.updatedProject))
          }
        }
        // toast.error('JSON file not found in this zip folder for the date value')
      }
    }
    setMFiles(filesArray)
  }

  return (
    !!calibrations && !!calibrations.length ?
      <>
        {!!isLoading && <SpinLoader2 progress={progressVal} />}
        <div className="calibration-files-header-container end-flex">
          {!!multiBNs && !!multiBNs.length && <div className='mr-10'>
            <RaptorButton
              title={t("button.delete_calb")}
              className={`delete-calb-file`}
              onClick={() => dispatch(deleteCalibrationFile(id, { blobName: multiBNs }))} />
          </div>}
          <div className="d-flex">
            <label className="upload_calibration_file" htmlFor="upload-calibration-file">{t("button.upload_calibration_file")}</label>
            <input type="file" multiple name="file1" id="upload-calibration-file" ref={fileInput2Ref} onChange={param => { handleFileChoose(param) }} />
          </div>
        </div>

        <div className='calibration-files-context'>
          <div className='project-context-table center-flex'>
            {
              !!calibrations.length ?
                <CommonTable columns={columns} data={calibrations}
                  onCheckboxChange={(e, info) => onCheckboxChange(e, info || {})}
                  handleCheckAll={(checked) => !!checked ? setCheckAll(checked) : unCheckAll()}
                  checkAll={checkAll}
                  actionBtn={true}
                  showMultiCheckbox={true}
                  multiIDs={multiIDs}
                  actionBtnType={2}
                  deleteIcon="trash2"
                  deleteMsg={t("delete_dialog.calb_permanent_dlt")}
                  deleteFn={(r) => {
                    if (!!r && !!r.original && !!r.original.blobName) {
                      dispatch(deleteCalibrationFile(id, { blobName: [r.original.blobName] }))
                    }
                  }} /> :
                <h3 className="center-flex h3-fetching-text">{!!isProcessing ? `${t('calibration_files.fetching')}...` : t('calibration_files.no_records')}</h3>
            }
          </div>
        </div>
      </> :
      <div className="measurment-section-card">
        <div className="no-measurment-found center-flex">
          <Icon name="big-files" />
          <div className="no-measurment-title">{t("project.no_calb_yet")}</div>
          <div className="no-measurment-message">{t("project.please_add_calb_message")}</div>
          <div className="d-flex no-calb-found">
            <label className="upload_calibration_file_2" htmlFor="upload-calibration-file-2">{t("button.upload_calibration_file")}</label>
            <input type="file" multiple={true} name="file1" id="upload-calibration-file-2" ref={fileInput2Ref} onChange={param => { handleFileChoose(param) }} />
          </div>
        </div>
      </div>
  )
}
