import { configureStore } from '@reduxjs/toolkit'
import thunk from "redux-thunk"
import {
  createStateSyncMiddleware,
  initMessageListener
} from "redux-state-sync"
import rootReducer from "../../reducers/root_reducers"

const middlewares = [
  thunk,
  createStateSyncMiddleware()
]

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
  devTools: window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
})

initMessageListener(store)

export default store