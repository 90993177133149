import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { AgChartsReact } from 'ag-charts-react';
import CustomPopUp from "../../../components/common/CustomPopUp"
import { colorCodes } from "../../../constant/colors";
import "./index.scss"

const series = [{
  type: 'histogram',
  xKey: 'value',
  xName: 'Range'
}]

const raptorTheme = {
  overrides: {
    histogram: {
      series: {
        fill: colorCodes.BLUE_1,
        stroke: colorCodes.BLUE_1
      }
    }
  }
}

const BasicStaticsCard = ({ handleClose, data = [], deflectionTab = false }) => {
  const { t } = useTranslation()
  const { visualizeParam, checkedMeasurmentIds } = useSelector(state => state.visualizes);

  useEffect(() => {
    const contentHolderDiv = document.getElementsByClassName('visualize-form-container')
    if (!!contentHolderDiv && !!contentHolderDiv[0]) {
      contentHolderDiv[0].style.zoom = '1'
    }
    return () => {
      if (!!contentHolderDiv && !!contentHolderDiv[0]) {
        contentHolderDiv[0].style.zoom = '0.8'
      }
    }
  }, [])

  let d0Arr = []
  let d100Arr = []
  let d200Arr = []
  let d300Arr = []
  let d450Arr = []
  let d600Arr = []
  let d900Arr = []
  let d1200Arr = []
  let d1800Arr = []
  let sciArr = []
  let bciArr = []
  let bdiArr = []
  let surfTempArr = []
  let airtTempArr = []
  let drivArr = []

  for (var l = 0; l < data.length; l++) {

    if (!!deflectionTab) {
      if (!!data[l] &&
        !!data[l]['measurementFileInfo'] &&
        !!data[l]['measurementFileInfo'].length) {

        for (var j = 0; j < data[l]['measurementFileInfo'].length; j++) {
          const mFile = data[l]['measurementFileInfo']

          const mIdRec = !!mFile && !!mFile[j] && !!mFile[j]['measurementFileIds'] ? mFile[j]['measurementFileIds'] : false
          if (checkedMeasurmentIds.includes(mIdRec)) {
            if (!!mFile[j] && mFile[j]['mapData'] && mFile[j]['mapData'].length) {

              for (var k = 0; k < mFile[j]['mapData'].length; k++) {
                const mapDataInst = mFile[j]['mapData']

                if (!!mapDataInst[k].D0) {
                  d0Arr.push({ value: Number(mapDataInst[k].D0) })
                }

                if (!!mapDataInst[k].D100) {
                  d100Arr.push({ value: Number(mapDataInst[k].D100) })
                }

                if (!!mapDataInst[k].D200) {
                  d200Arr.push({ value: Number(mapDataInst[k].D200) })
                }

                if (!!mapDataInst[k].D300) {
                  d300Arr.push({ value: Number(mapDataInst[k].D300) })
                }

                if (!!mapDataInst[k].D450) {
                  d450Arr.push({ value: Number(mapDataInst[k].D450) })
                }

                if (!!mapDataInst[k].D600) {
                  d600Arr.push({ value: Number(mapDataInst[k].D600) })
                }

                if (!!mapDataInst[k].D900) {
                  d900Arr.push({ value: Number(mapDataInst[k].D900) })
                }

                if (!!mapDataInst[k].D1200) {
                  d1200Arr.push({ value: Number(mapDataInst[k].D1200) })
                }

                if (!!mapDataInst[k].D1800) {
                  d1800Arr.push({ value: Number(mapDataInst[k].D1800) })
                }

                if (!!mapDataInst[k].SCI) {
                  sciArr.push({ value: Number(mapDataInst[k].SCI) })
                }

                if (!!mapDataInst[k].BCI) {
                  bciArr.push({ value: Number(mapDataInst[k].BCI) })
                }

                if (!!mapDataInst[k].BDI) {
                  bdiArr.push({ value: Number(mapDataInst[k].BDI) })
                }

                if (!!mapDataInst[k].Speed) {
                  drivArr.push({ value: Number(mapDataInst[k].Speed) })
                }
    
                if (!!mapDataInst[k].AirTemp) {
                  airtTempArr.push({ value: Number(mapDataInst[k].AirTemp) })
                }
    
                if (!!mapDataInst[k].SurfTemp) {
                  surfTempArr.push({ value: Number(mapDataInst[k].SurfTemp) })
                }
              }
            }
          }
        }
      }
    } else {
      if (checkedMeasurmentIds.includes(data[l]['measurementId'])) {
        if (!!data[l].rawData) {
          const measurRawData = data[l].rawData
          for (var m = 0; m < measurRawData.length; m++) {
            if (!!measurRawData[m].Speed) {
              drivArr.push({ value: Number(measurRawData[m].Speed) })
            }

            if (!!measurRawData[m].AirTemp) {
              airtTempArr.push({ value: Number(measurRawData[m].AirTemp) })
            }

            if (!!measurRawData[m].SurfTemp) {
              surfTempArr.push({ value: Number(measurRawData[m].SurfTemp) })
            }
          }
        }
      }
    }
  }

  const deflectArr = (val) => {
    switch (val) {
      case 'D0':
        return d0Arr
      case 'D100':
        return d100Arr
      case 'D200':
        return d200Arr
      case 'D300':
        return d300Arr
      case 'D450':
        return d450Arr
      case 'D600':
        return d600Arr
      case 'D900':
        return d900Arr
      case 'D1200':
        return d1200Arr
      case 'D1800':
        return d1800Arr
      case 'SCI':
        return sciArr
      case 'BCI':
        return bciArr
      case 'BDI':
        return bdiArr
      default:
        return d0Arr
    }
  }

  return (
    <div className='basic-static-form-container'>
      <CustomPopUp handleClose={() => !!handleClose && handleClose()} className="calculation-input-form" title={t("form_input.basic_static")}>
        <div className="basic-statics-form-context">
          {
            (!!visualizeParam && !!checkedMeasurmentIds.length) ? <>
              <div className="center-flex">
                {!!deflectArr(visualizeParam).length && <AgChartsReact containerStyle={{ width: '100%' }} options={{
                  title: { text: visualizeParam },
                  series,
                  axes: [
                    {
                      type: 'number',
                      position: 'bottom',
                      title: { text: '' },
                      tick: { interval: 50 },
                    },
                    {
                      type: 'number',
                      // position: 'left',
                      // title: { text: 'Number of participants' },
                    },
                  ],
                  data: deflectArr(visualizeParam),
                  theme: raptorTheme
                }} />}
              </div>

              <div className="d-flex f-column mt-40">
                <div>
                  {
                    !!surfTempArr.length && <AgChartsReact containerStyle={{ width: '100%' }} options={{
                      title: { text: 'Surface Temperature' },
                      series,
                      axes: [
                        {
                          type: 'number',
                          position: 'bottom',
                          title: { text: '' },
                          tick: { interval: 2 },
                        },
                        { type: 'number' },
                      ],
                      data: surfTempArr,
                      theme: raptorTheme
                    }} />
                  }
                </div>

                <div>
                  {
                    !!airtTempArr.length && <AgChartsReact containerStyle={{ width: '100%' }} options={{
                      title: { text: 'Air Temperature' },
                      series,
                      axes: [
                        {
                          type: 'number',
                          position: 'bottom',
                          title: { text: '' },
                          tick: { interval: 2 },
                        },
                        { type: 'number' }
                      ],
                      data: airtTempArr,
                      theme: raptorTheme
                    }} />
                  }
                </div>

                <div>
                  {
                    !!drivArr.length &&
                    <AgChartsReact containerStyle={{ width: '100%' }} options={{
                      title: { text: 'Driving Speed' },
                      series,
                      axes: [
                        {
                          type: 'number',
                          position: 'bottom',
                          title: { text: '' },
                          tick: { interval: 2 },
                        },
                        { type: 'number' }
                      ],
                      data: drivArr,
                      theme: raptorTheme
                    }} />
                  }
                </div>
              </div>
            </> :
              <p className="ml-30">Please select visualize param and measurment file</p>
          }
        </div>
      </CustomPopUp>
    </div>
  )
}

export default BasicStaticsCard