import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../Icons'
import './index.scss'

const ConfirmAlertPopup = ({
    title = 'delete_dialog.are_you_sure',
    message = 'delete_dialog.delete_msg',
    confirmBtnTxt = 'delete_dialog.yes_dlt',
    cancelBtnTxt = 'button.no_keep',
    icon = "warning",
    onConfirm, onClose, handleOnClose
}) => {
    const wrapperRef = useRef()
    const { t } = useTranslation()

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null) &&
            !wrapperRef.current.contains(event.target)) {
            onClose()
        }
    }

    const handleKeyDown = e => {
        if (((e.keyCode === 27) ||
            (e.key === 'Escape') ||
            (e.key === 'Esc'))) {
            onClose()
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="confirm-alert-popup-container center-flex">
            <div className="confirm-alert-popup-message center-flex f-column" ref={wrapperRef}>
                <div className='confirm-alert-title-sec'>
                    <Icon name={icon} />
                    <h3>{t(title)}</h3>
                    <img src={require('./cross.png')} alt="Close" onClick={() => onClose()} />
                </div>
                <div className='confirm-alert-desc-sec'>
                    <p>{t(message)}</p>
                    <div className="button-container">
                        <button onClick={() => {
                            !!handleOnClose && handleOnClose()
                            onClose()
                        }} className='confirm-cancel'>{t(cancelBtnTxt)}</button>
                        <button onClick={() => {
                            onConfirm()
                            onClose()
                        }} className='confirm-agree'>{t(confirmBtnTxt)}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmAlertPopup