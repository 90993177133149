import {
  SET_GLOBAL_ACTIVE_TAB,
  SET_AUTO_OUT, SET_LANGUAGE, SET_MAP_LAT_LONG,
  SET_MAP_ZOOM, SET_USER_INFO, SET_DEFAULT_PROJ_ID
} from '../actions/global';
import { NORWAY_CORDI } from '../constant';

const defaultLang = localStorage.getItem('raptor-lang');
const defaultState = {
  autoOut: false,
  userInfo: {},
  lang: !!defaultLang ? defaultLang : 'US',
  globalActiveTab: 0,
  mapZoom: 8,
  mapLatLong: NORWAY_CORDI, //HINT: We're using by Default Norway Lat long
  defaultProjID: false
};

const Global = (state = defaultState, action) => {
  switch (action.type) {
    case SET_GLOBAL_ACTIVE_TAB:
    case SET_MAP_LAT_LONG:
    case SET_MAP_ZOOM:
    case SET_AUTO_OUT:
    case SET_USER_INFO:
    case SET_LANGUAGE:
    case SET_DEFAULT_PROJ_ID:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export default Global;
