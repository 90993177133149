import moment from "moment"
import {
    ADD_NEW_CALCULATION, CALC_IS_VALIDATING, CREATE_PROJS_SUCCESS,
    DELETE_CALC_SUCCESS, DELETE_PROJS_SUCCESS, EDIT_PROJ_SUCCESS,
    PROJECT_IS_PROCESSING, RDI_IS_PROCESSING, SET_ALL_CALCULATIONS,
    SET_ALL_COUNTRIES, SET_ALL_PROJECT, SET_CALC_GRAPH_INFO, SET_PROJ_INFO, UPDATE_OWNERSHIP
} from "../actions/project"
import { DATE_FORMAT_1 } from "../constant";

const initialState = {
    countries: [],
    projects: [],
    calculations: {},
    projectInfo: {},
    calcIsValidating: false,
    isProcessing: false,
    isProcessingRDI: false,
    calcGraphInfo: {}
}

const Projects = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_PROJECT:
        case CALC_IS_VALIDATING:
        case PROJECT_IS_PROCESSING:
        case RDI_IS_PROCESSING:
        case SET_PROJ_INFO:
        case SET_ALL_COUNTRIES:
        case SET_CALC_GRAPH_INFO:
            return Object.assign({}, state, action.payload)
        case SET_ALL_CALCULATIONS: {
            return {
                ...state,
                isProcessing: false,
                calculations: {
                    ...state.calculations,
                    ...{ [action.payload.pid]: action.payload.calculations }
                }
            }
        }
        case ADD_NEW_CALCULATION: {
            return {
                ...state,
                isProcessing: false,
                calculations: {
                    ...state.calculations,
                    ...{
                        [action.payload.pid]: [
                            ...state.calculations[action.payload.pid],
                            action.payload.calculation
                        ]
                    }
                }
            }
        }
        case DELETE_CALC_SUCCESS: {
            let allProjects = (state.projects).map(a => {
                a.calculations = (a.calculations).filter(c => (c._id) !== action.cId)
                return a
            })

            let pIdCalculations = (state.calculations[action.pId]).filter(a => (a._id) !== action.cId)

            return {
                ...state,
                isProcessing: false,
                projects: allProjects,
                calculations: {
                    ...state.calculations,
                    ...{
                        [action.pId]: pIdCalculations
                    }
                }
            }
        }
        case CREATE_PROJS_SUCCESS: {
            action.project.id = state.projects.length + 1
            action.project.created_at = moment(new Date()).format(DATE_FORMAT_1)
            return {
                ...state,
                isProcessing: false,
                projects: [
                    ...state.projects,
                    action.project
                ]
            }
        }
        case UPDATE_OWNERSHIP: {
            let allProjects = (state.projects).map(a => {
                if (a._id === action.id) {
                    return {
                        ...a,
                        ownership: action.ownership
                    }
                } else {
                    return a
                }
            })

            return {
                ...state,
                isProcessing: false,
                projects: allProjects,
                projectInfo: {
                    ...state.projectInfo,
                    ...(!!state.projectInfo && !!state.projectInfo._id && (state.projectInfo._id === action.id) && { ownership: action.ownership })
                }
            }
        }
        case EDIT_PROJ_SUCCESS: {
            let allProjects = (state.projects).map(a => {
                if (a._id === action.id) {
                    return { ...a, ...action.projectInfo }
                } else {
                    return a
                }
            })
            return {
                ...state,
                isProcessing: false,
                projects: allProjects
            }
        }
        case DELETE_PROJS_SUCCESS: {
            let allProjects = (state.projects).filter(a => ((action.projectIDs).indexOf(a._id) === -1))
            return {
                ...state,
                isProcessing: false,
                projects: allProjects
            }
        }
        default:
            return state
    }
}

export default Projects