import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Location = ({
    fill = colorCodes.BLACK_1,
    height = 21,
    width = 17,
    style = {},
    viewBox = '0 0 17 21',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M0.821533 8.65454C0.827898 6.61807 1.64299 4.66754 3.08748 3.23205C4.53198 1.79655 6.48757 0.993672 8.52403 1.00004C10.5605 1.0064 12.511 1.82149 13.9465 3.26599C15.382 4.71049 16.1849 6.66607 16.1785 8.70254V8.78954C15.9558 11.5676 14.7177 14.1664 12.7005 16.0895C11.6215 17.2103 10.4165 18.2026 9.10953 19.0465C8.94053 19.1928 8.72452 19.2732 8.50103 19.2732C8.27755 19.2732 8.06154 19.1928 7.89253 19.0465C5.94413 17.7784 4.23406 16.1773 2.84053 14.3165C1.59767 12.6936 0.891906 10.7235 0.821533 8.68054L0.821533 8.65454Z" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.50055 11.2618C9.85972 11.2618 10.9616 10.16 10.9616 8.80084C10.9616 7.44167 9.85972 6.33984 8.50055 6.33984C7.14138 6.33984 6.03955 7.44167 6.03955 8.80084C6.03955 10.16 7.14138 11.2618 8.50055 11.2618Z" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default Location