import global from './global'
import projects from './projects'
import visualizes from './visualizes'

const rootReducer = {
    global,
    projects,
    visualizes
}

export default rootReducer