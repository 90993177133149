import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { fetchAllCountries, fetchAllProjects } from "../../../actions/project"
import AuthPopup from "../../../views/AuthPopup"
import CopyRight from "../../../views/CopyRight"
import { NavBar } from "../../../views/navbar"
import { Projects } from "../../../views/Projects"
import { SideBar } from "../../../views/sidebar"
import { VisualizeMap } from "../../../views/VisualizeMap"
import NotFound from "../../../views/NotFound"
import "./index.scss"

const LoggedInLayout = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const { autoOut, defaultProjID } = useSelector(state => state.global)
    const { projectInfo } = useSelector(state => state.projects)
    const { pathname } = location

    const [breadcrumbList, setBreadCrumbList] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchAllProjects())
        dispatch(fetchAllCountries())

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if ((pathname === "/raptor") ||
            (pathname === "/raptor/")) {
            navigate("/raptor/projects")
        }

        let optList = []
        if (!!pathname) {
            const splittedPath = (pathname.split("/")).filter(e => String(e).trim())
            if (!!splittedPath) {
                if (!!splittedPath[0] && splittedPath[0] === 'raptor') {
                    optList.push('breadcrumb.dashboard')
                }

                if (!!splittedPath[1] && splittedPath[1] === 'projects') {
                    optList.push('breadcrumb.all_proj')

                    if (!!splittedPath[2]) {
                        if (splittedPath[2] === 'create') {
                            optList.push('breadcrumb.create_proj')
                        } else if (splittedPath[2] === 'edit') {
                            optList.push('breadcrumb.edit_proj')
                        } else if (splittedPath[2] === 'view') {
                            optList.push('breadcrumb.view_proj')
                        }
                    }
                } else if (!!splittedPath[1] && splittedPath[1] === 'visualize_map') {
                    optList.push('breadcrumb.visualize_map')
                } else if (!!splittedPath[1] && splittedPath[1] === 'calibration_files') {
                    optList.push('breadcrumb.calibration_files')
                }
            }
        }
        setBreadCrumbList(optList)
        // eslint-disable-next-line
    }, [pathname])

    const inCaseOfView = val => {
        switch (val) {
            case 'breadcrumb.view_proj':
                return !!projectInfo && !!projectInfo.projectName ? projectInfo.projectName : '-'
            default:
                return val
        }
    }

    return (
        <>
            {autoOut && <AuthPopup t={t} />}
            <NavBar
                showProjectTitle={!!defaultProjID && pathname === "/raptor/visualize_map"}
                projectTitle={!!projectInfo && !!projectInfo.projectName ? projectInfo.projectName : false}
            />
            <SideBar />
            <div className={`content-holder ${pathname === '/raptor/visualize_map' ? 'map-view' : ''}`}>
                <div className="breadcrumb-info">
                    {
                        breadcrumbList.map((opt, i) =>
                            <span className={((breadcrumbList.length - 1) === i) ? "" : "parent-breadcrumb"} key={i}>{`${t(inCaseOfView(opt))} ${(breadcrumbList.length - 1) === i ? '' : ' > '}`}</span>)
                    }
                </div>
                <Routes>
                    <Route path="projects/*" element={<Projects />} />
                    {
                        !!defaultProjID && <Route path="visualize_map" element={<VisualizeMap />} />
                    }
                    <Route path="*" element={<NotFound />} />
                </Routes>
                {(pathname !== "/raptor/visualize_map") && <CopyRight />}
            </div>
        </>
    )
}

export default LoggedInLayout