import React from 'react'

const BigFiles = ({
    fill = "none",
    height = 242,
    width = 270,
    style = {},
    viewBox = '0 0 270 242',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill={fill}>
        <path d="M138.276 0.527497L129.49 2.79003L21.2535 30.6649L12.4682 32.9275C8.2039 34.0308 4.55198 36.7783 2.31361 40.5673C0.0752458 44.3562 -0.566758 48.8772 0.528448 53.1382L38.6236 200.665C39.7284 204.924 42.4795 208.571 46.2735 210.806C50.0675 213.042 54.5945 213.683 58.8613 212.589L58.884 212.583L184.646 180.195L184.669 180.189C188.933 179.086 192.585 176.338 194.823 172.549C197.062 168.761 197.704 164.24 196.609 159.979L158.514 12.4514C157.409 8.19278 154.658 4.54571 150.864 2.31032C147.07 0.074922 142.543 -0.566239 138.276 0.527497Z" fill="#F2F2F2" />
        <path d="M139.47 5.15269L129.065 7.83251L24.0679 34.8729L13.6624 37.5527C10.6257 38.3384 8.02509 40.295 6.43109 42.9932C4.83708 45.6914 4.37989 48.9108 5.15981 51.9452L43.2549 199.472C44.0417 202.505 46.0009 205.102 48.7027 206.694C51.4045 208.286 54.6282 208.742 57.6667 207.964L57.6894 207.958L183.452 175.569L183.474 175.564C186.511 174.778 189.112 172.821 190.706 170.123C192.3 167.425 192.757 164.205 191.977 161.171L153.882 13.644C153.095 10.6114 151.136 8.01421 148.434 6.42233C145.732 4.83046 142.509 4.37385 139.47 5.15269Z" fill="white" />
        <path d="M131.613 52.9265L67.9929 69.3109C67.2824 69.4939 66.5281 69.3875 65.8961 69.0152C65.2641 68.6428 64.8061 68.0349 64.6228 67.3253C64.4396 66.6157 64.5461 65.8624 64.919 65.2312C65.2919 64.6001 65.9005 64.1427 66.6111 63.9597L130.231 47.5752C130.941 47.393 131.695 47.4998 132.327 47.8722C132.958 48.2447 133.416 48.8523 133.599 49.5615C133.782 50.2707 133.676 51.0235 133.303 51.6545C132.931 52.2854 132.323 52.743 131.613 52.9265Z" fill="#F2F2F2" />
        <path d="M144.997 59.1097L70.325 78.3404C69.6144 78.5234 68.8601 78.417 68.2281 78.0446C67.5961 77.6722 67.1381 77.0644 66.9548 76.3547C66.7716 75.6451 66.8781 74.8919 67.251 74.2607C67.6239 73.6295 68.2326 73.1721 68.9431 72.9891L143.615 53.7584C144.326 53.5754 145.08 53.6818 145.712 54.0542C146.344 54.4266 146.802 55.0345 146.985 55.7441C147.168 56.4537 147.062 57.207 146.689 57.8381C146.316 58.4693 145.707 58.9267 144.997 59.1097Z" fill="#F2F2F2" />
        <path d="M142.063 93.3949L78.4431 109.779C78.0913 109.87 77.725 109.89 77.3653 109.84C77.0055 109.789 76.6592 109.668 76.3463 109.484C76.0333 109.299 75.7598 109.055 75.5414 108.765C75.3229 108.475 75.1637 108.145 75.073 107.794C74.9823 107.442 74.9617 107.077 75.0125 106.717C75.0634 106.358 75.1845 106.012 75.3692 105.7C75.5538 105.387 75.7983 105.114 76.0886 104.896C76.3789 104.678 76.7095 104.519 77.0613 104.428L140.681 88.0436C141.033 87.953 141.399 87.9325 141.759 87.9832C142.119 88.034 142.465 88.155 142.778 88.3394C143.091 88.5237 143.365 88.7679 143.583 89.0578C143.802 89.3478 143.961 89.6779 144.051 90.0292C144.142 90.3806 144.163 90.7464 144.112 91.1057C144.061 91.465 143.94 91.8108 143.755 92.1233C143.571 92.4358 143.326 92.709 143.036 92.9271C142.746 93.1453 142.415 93.3043 142.063 93.3949Z" fill="#F2F2F2" />
        <path d="M155.447 99.5794L80.7749 118.81C80.4231 118.901 80.0568 118.921 79.697 118.87C79.3372 118.82 78.991 118.699 78.6781 118.514C78.3651 118.33 78.0916 118.086 77.8731 117.796C77.6547 117.506 77.4955 117.176 77.4048 116.824C77.3141 116.473 77.2935 116.107 77.3443 115.748C77.3952 115.389 77.5163 115.043 77.701 114.73C77.8856 114.418 78.13 114.145 78.4204 113.927C78.7107 113.708 79.0412 113.549 79.3931 113.459L154.065 94.2282C154.775 94.0452 155.53 94.1516 156.162 94.5239C156.794 94.8963 157.252 95.5042 157.435 96.2138C157.618 96.9234 157.512 97.6767 157.139 98.3079C156.766 98.9391 156.157 99.3965 155.447 99.5794Z" fill="#F2F2F2" />
        <path d="M152.513 133.865L88.8932 150.249C88.1828 150.431 87.429 150.325 86.7974 149.952C86.1658 149.58 85.7081 148.972 85.5249 148.263C85.3418 147.554 85.4481 146.801 85.8206 146.17C86.1931 145.539 86.8012 145.081 87.5114 144.898L151.131 128.513C151.842 128.33 152.596 128.437 153.228 128.809C153.86 129.181 154.318 129.789 154.502 130.499C154.685 131.209 154.578 131.962 154.205 132.593C153.832 133.224 153.224 133.682 152.513 133.865Z" fill="#F2F2F2" />
        <path d="M165.897 140.048L91.2251 159.279C90.8731 159.37 90.5066 159.391 90.1466 159.34C89.7866 159.29 89.4401 159.169 89.1269 158.984C88.8137 158.8 88.5399 158.556 88.3213 158.266C88.1026 157.976 87.9433 157.645 87.8526 157.294C87.7618 156.942 87.7412 156.576 87.7922 156.217C87.8431 155.857 87.9645 155.511 88.1493 155.199C88.3342 154.886 88.579 154.613 88.8696 154.395C89.1603 154.177 89.4911 154.018 89.8432 153.928L164.515 134.697C165.226 134.514 165.98 134.62 166.612 134.993C167.244 135.365 167.702 135.973 167.885 136.683C168.068 137.392 167.962 138.145 167.589 138.777C167.216 139.408 166.607 139.865 165.897 140.048Z" fill="#F2F2F2" />
        <path d="M57.1322 86.1102L34.6176 91.9085C34.2769 91.9959 33.9154 91.9447 33.6125 91.7662C33.3095 91.5877 33.0899 91.2965 33.0017 90.9564L27.8335 70.9421C27.746 70.6018 27.7973 70.2408 27.976 69.9383C28.1547 69.6357 28.4463 69.4163 28.7868 69.3283L51.3014 63.5299C51.6421 63.4426 52.0036 63.4938 52.3065 63.6723C52.6095 63.8508 52.8291 64.142 52.9174 64.4821L58.0856 84.4964C58.173 84.8366 58.1218 85.1976 57.943 85.5002C57.7643 85.8027 57.4727 86.0221 57.1322 86.1102Z" fill="#E6E6E6" />
        <path d="M67.5824 126.579L45.0678 132.377C44.7271 132.465 44.3656 132.413 44.0627 132.235C43.7597 132.056 43.5401 131.765 43.4518 131.425L38.2837 111.411C38.1962 111.071 38.2475 110.71 38.4262 110.407C38.6049 110.104 38.8965 109.885 39.237 109.797L61.7516 103.999C62.0923 103.911 62.4537 103.963 62.7567 104.141C63.0597 104.32 63.2793 104.611 63.3675 104.951L68.5357 124.965C68.6232 125.305 68.5719 125.666 68.3932 125.969C68.2145 126.271 67.9229 126.491 67.5824 126.579Z" fill="#E6E6E6" />
        <path d="M78.0323 167.049L55.5178 172.847C55.1771 172.934 54.8156 172.883 54.5126 172.705C54.2097 172.526 53.99 172.235 53.9018 171.895L48.7336 151.881C48.6461 151.54 48.6974 151.179 48.8761 150.877C49.0549 150.574 49.3465 150.355 49.687 150.267L72.2015 144.468C72.5422 144.381 72.9037 144.432 73.2067 144.611C73.5096 144.789 73.7293 145.08 73.8175 145.421L78.9857 165.435C79.0732 165.775 79.0219 166.136 78.8432 166.439C78.6644 166.741 78.3728 166.961 78.0323 167.049Z" fill="#E6E6E6" />
        <path d="M226.02 37.9238H96.0968C91.6917 37.9288 87.4685 39.6786 84.3536 42.7893C81.2387 45.9 79.4866 50.1176 79.4817 54.5168V206.87C79.4866 211.27 81.2387 215.487 84.3536 218.598C87.4685 221.709 91.6917 223.458 96.0968 223.463H226.02C230.425 223.458 234.649 221.709 237.763 218.598C240.878 215.487 242.63 211.27 242.635 206.87V54.5168C242.63 50.1176 240.878 45.9 237.763 42.7893C234.649 39.6786 230.425 37.9288 226.02 37.9238Z" fill="#E6E6E6" />
        <path d="M226.02 42.7002H96.0967C92.9597 42.7037 89.9522 43.9498 87.7341 46.165C85.5159 48.3802 84.2682 51.3836 84.2646 54.5164V206.87C84.2682 210.003 85.5159 213.006 87.7341 215.221C89.9522 217.437 92.9597 218.683 96.0967 218.686H226.02C229.157 218.683 232.165 217.437 234.383 215.221C236.601 213.006 237.849 210.003 237.852 206.87V54.5164C237.849 51.3837 236.601 48.3802 234.383 46.165C232.165 43.9498 229.157 42.7037 226.02 42.7002Z" fill="white" />
        <path d="M239.67 242C256.421 242 270 228.439 270 211.711C270 194.982 256.421 181.421 239.67 181.421C222.919 181.421 209.34 194.982 209.34 211.711C209.34 228.439 222.919 242 239.67 242Z" fill="#00B0FF" />
        <path d="M254.175 207.76H243.626V197.224C243.626 196.176 243.209 195.172 242.467 194.431C241.725 193.69 240.719 193.273 239.67 193.273C238.62 193.273 237.614 193.69 236.872 194.431C236.13 195.172 235.714 196.176 235.714 197.224V207.76H225.164C224.115 207.76 223.108 208.176 222.366 208.917C221.625 209.658 221.208 210.663 221.208 211.711C221.208 212.759 221.625 213.763 222.366 214.504C223.108 215.245 224.115 215.662 225.164 215.662H235.714V226.197C235.714 227.245 236.13 228.25 236.872 228.991C237.614 229.732 238.62 230.148 239.67 230.148C240.719 230.148 241.725 229.732 242.467 228.991C243.209 228.25 243.626 227.245 243.626 226.197V215.662H254.175C255.225 215.662 256.231 215.245 256.973 214.504C257.715 213.763 258.132 212.759 258.132 211.711C258.132 210.663 257.715 209.658 256.973 208.917C256.231 208.176 255.225 207.76 254.175 207.76Z" fill="white" />
        <path d="M206.467 126.419H140.765C140.402 126.419 140.042 126.348 139.706 126.209C139.37 126.071 139.064 125.867 138.807 125.611C138.55 125.354 138.346 125.049 138.206 124.714C138.067 124.378 137.995 124.019 137.995 123.656C137.995 123.293 138.067 122.933 138.206 122.598C138.346 122.262 138.55 121.957 138.807 121.701C139.064 121.444 139.37 121.241 139.706 121.102C140.042 120.963 140.402 120.892 140.765 120.893H206.467C207.2 120.893 207.903 121.185 208.421 121.703C208.939 122.221 209.231 122.923 209.231 123.656C209.231 124.388 208.939 125.09 208.421 125.609C207.903 126.127 207.2 126.418 206.467 126.419Z" fill="#E6E6E6" />
        <path d="M217.88 135.744H140.765C140.402 135.745 140.042 135.673 139.706 135.535C139.37 135.396 139.064 135.193 138.807 134.936C138.55 134.679 138.346 134.375 138.206 134.039C138.067 133.704 137.995 133.344 137.995 132.981C137.995 132.618 138.067 132.258 138.206 131.923C138.346 131.587 138.55 131.283 138.807 131.026C139.064 130.769 139.37 130.566 139.706 130.427C140.042 130.289 140.402 130.217 140.765 130.218H217.88C218.614 130.218 219.318 130.509 219.837 131.027C220.355 131.545 220.647 132.248 220.647 132.981C220.647 133.714 220.355 134.417 219.837 134.935C219.318 135.453 218.614 135.744 217.88 135.744Z" fill="#E6E6E6" />
        <path d="M206.467 168.212H140.765C140.402 168.212 140.042 168.141 139.706 168.002C139.37 167.864 139.064 167.66 138.807 167.404C138.55 167.147 138.346 166.842 138.206 166.507C138.067 166.171 137.995 165.812 137.995 165.449C137.995 165.086 138.067 164.726 138.206 164.391C138.346 164.055 138.55 163.75 138.807 163.494C139.064 163.237 139.37 163.034 139.706 162.895C140.042 162.756 140.402 162.685 140.765 162.686H206.467C207.201 162.686 207.904 162.977 208.423 163.495C208.942 164.013 209.234 164.716 209.234 165.449C209.234 166.182 208.942 166.884 208.423 167.403C207.904 167.921 207.201 168.212 206.467 168.212Z" fill="#E6E6E6" />
        <path d="M217.88 177.537H140.765C140.402 177.538 140.042 177.466 139.706 177.328C139.37 177.189 139.064 176.986 138.807 176.729C138.55 176.472 138.346 176.168 138.206 175.832C138.067 175.497 137.995 175.137 137.995 174.774C137.995 174.411 138.067 174.051 138.206 173.716C138.346 173.38 138.55 173.076 138.807 172.819C139.064 172.562 139.37 172.359 139.706 172.22C140.042 172.082 140.402 172.01 140.765 172.011H217.88C218.244 172.01 218.604 172.082 218.94 172.22C219.276 172.359 219.581 172.562 219.839 172.819C220.096 173.076 220.3 173.38 220.439 173.716C220.578 174.051 220.65 174.411 220.65 174.774C220.65 175.137 220.578 175.497 220.439 175.832C220.3 176.168 220.096 176.472 219.839 176.729C219.581 176.986 219.276 177.189 218.94 177.328C218.604 177.466 218.244 177.538 217.88 177.537Z" fill="#E6E6E6" />
        <path d="M126.048 139.978H102.797C102.445 139.978 102.108 139.838 101.859 139.59C101.61 139.341 101.47 139.005 101.47 138.653V117.984C101.47 117.633 101.61 117.296 101.859 117.048C102.108 116.799 102.445 116.66 102.797 116.659H126.048C126.4 116.66 126.737 116.799 126.986 117.048C127.234 117.296 127.374 117.633 127.375 117.984V138.653C127.374 139.005 127.234 139.341 126.986 139.59C126.737 139.838 126.4 139.978 126.048 139.978Z" fill="#E6E6E6" />
        <path d="M126.048 181.771H102.797C102.445 181.771 102.108 181.631 101.859 181.383C101.61 181.134 101.47 180.797 101.47 180.446V159.777C101.47 159.426 101.61 159.089 101.859 158.841C102.108 158.592 102.445 158.453 102.797 158.452H126.048C126.4 158.453 126.737 158.592 126.986 158.841C127.234 159.089 127.374 159.426 127.375 159.777V180.446C127.374 180.797 127.234 181.134 126.986 181.383C126.737 181.631 126.4 181.771 126.048 181.771Z" fill="#E6E6E6" />
        <path d="M206.54 79.8945H158.101C157.367 79.8945 156.663 79.6034 156.144 79.0852C155.625 78.567 155.334 77.8642 155.334 77.1313C155.334 76.3985 155.625 75.6957 156.144 75.1775C156.663 74.6593 157.367 74.3682 158.101 74.3682H206.54C207.273 74.3682 207.977 74.6593 208.496 75.1775C209.015 75.6957 209.306 76.3985 209.306 77.1313C209.306 77.8642 209.015 78.567 208.496 79.0852C207.977 79.6034 207.273 79.8945 206.54 79.8945Z" fill="#CCCCCC" />
        <path d="M217.953 89.2207H158.101C157.737 89.2207 157.377 89.1492 157.042 89.0103C156.706 88.8715 156.401 88.6679 156.144 88.4114C155.887 88.1548 155.683 87.8502 155.544 87.5149C155.405 87.1797 155.334 86.8204 155.334 86.4575C155.334 86.0946 155.405 85.7353 155.544 85.4001C155.683 85.0648 155.887 84.7602 156.144 84.5036C156.401 84.2471 156.706 84.0435 157.042 83.9047C157.377 83.7658 157.737 83.6943 158.101 83.6943H217.953C218.687 83.6943 219.391 83.9854 219.909 84.5036C220.428 85.0218 220.72 85.7247 220.72 86.4575C220.72 87.1903 220.428 87.8932 219.909 88.4114C219.391 88.9296 218.687 89.2207 217.953 89.2207Z" fill="#CCCCCC" />
        <path d="M147.38 100.522H102.724C102.372 100.521 102.035 100.382 101.786 100.133C101.537 99.8848 101.397 99.5481 101.397 99.1968V64.3913C101.397 64.0401 101.537 63.7033 101.786 63.4549C102.035 63.2065 102.372 63.0668 102.724 63.0664H147.38C147.732 63.0668 148.069 63.2065 148.318 63.4549C148.567 63.7033 148.707 64.0401 148.707 64.3913V99.1968C148.707 99.5481 148.567 99.8848 148.318 100.133C148.069 100.382 147.732 100.521 147.38 100.522Z" fill="#00B0FF" />
    </svg>
)

export default BigFiles