import { useState } from "react"
import { useTranslation } from 'react-i18next'
import Icon from "../Icons"
import LabelInput from "../LabelInput"
import "./index.scss"

const TablePagination = ({
    total = 0,
    pageCount = 0,
    canNextPage = false,
    canPreviousPage = false,
    pageIndex, pageOptions, pageJump = true,
    pageSize, setPageSize,
    gotoPage, previousPage, nextPage, recordsOnPage
}) => {
    const activePage = pageIndex + 1
    const [jumpOn, setJumpOn] = useState(pageIndex + 1)
    const fromRec = (pageIndex * 10)
    const uptoRec = fromRec + recordsOnPage
    const { t } = useTranslation()

    const goToPageCall = pageNumber => {
        if (pageNumber > pageOptions.length) {
            setJumpOn(pageOptions.length - 1)
            gotoPage(pageOptions.length - 1)
        } else if (pageNumber < 1) {
            setJumpOn(1)
            gotoPage(pageNumber)
        } else {
            gotoPage(pageNumber)
        }
    }

    const buttonList = []

    if (pageCount <= 6) {
        for (var i = 1; i <= pageCount; i++) {
            buttonList.push({ className: 'page-nmbr-btn', value: i })
        }
    }
    else {

        // Always print first page button
        buttonList.push({ className: 'page-nmbr-btn', value: 1 })

        // Print "..." only if activePage is > 3
        if (activePage > 3) {
            buttonList.push({ className: 'page-dotted-btn', value: '...' })
        }

        // special case where last page is selected...
        if (activePage === pageCount) {
            buttonList.push({ className: 'page-nmbr-btn', value: activePage - 2 })
        }

        // Print previous number button if activePage > 2
        if (activePage > 2) {
            buttonList.push({ className: 'page-nmbr-btn', value: activePage - 1 })
        }

        //Print current page number button as long as it not the first or last page
        if (activePage !== 1 && activePage !== pageCount) {
            buttonList.push({ className: 'page-nmbr-btn', value: activePage })
        }

        //print next number button if activePage < lastPage - 1
        if (activePage < pageCount - 1) {
            buttonList.push({ className: 'page-nmbr-btn', value: activePage + 1 })
        }

        // special case where first page is selected...
        if (activePage === 1) {
            buttonList.push({ className: 'page-nmbr-btn', value: activePage + 2 })
        }

        //print "..." if activePage is < lastPage -2
        if (activePage < pageCount - 2) {
            buttonList.push({ className: 'page-dotted-btn', value: '...' })
        }

        //Always print last page button if there is more than 1 page
        buttonList.push({ className: 'page-nmbr-btn', value: pageCount })
    }

    return (
        <div className="raptor-table-pagination" onClick={e => e.stopPropagation()}>
            <button className="pagi-prev-btn" onClick={() => previousPage()} disabled={!canPreviousPage}><Icon name="left-arrow" /></button>
            {buttonList.map((btn, i) => (btn.className === 'page-nmbr-btn') ?
                <button key={i} className={`${btn.className} ${(parseInt(activePage) === parseInt(btn.value)) ? 'active' : ''}`} onClick={() => gotoPage(Number(btn.value) - 1)}>{btn.value}</button> :
                <span key={i} className={btn.className}>{btn.value}</span>)}
            <button className="pagi-next-btn" onClick={() => nextPage()} disabled={!canNextPage}><Icon name="right-arrow" /></button>

            <span className="page-record-counting">{fromRec + 1} - {uptoRec}{' of ' + total}</span>
            {/* HINT: Not in use for now */}
            {false && !!pageJump && <div className="go-to-page-sec center-flex">
                <span className="ml-20 mr-20">{t('table.go_to_pg')}</span>
                <LabelInput showLabel={false} type="number" value={jumpOn}
                    onChange={e => {
                        setJumpOn((e.target.value ? Number(e.target.value) - 1 : 0) + 1)
                    }} />
                <button onClick={() => goToPageCall(jumpOn)} className="ml-10"><Icon name="right-arrow" /></button>
            </div>}
            {false && !!pageSize && !!pageSize && !!setPageSize && <select
                value={pageSize}
                onChange={e => {
                    !!setPageSize && setPageSize(Number(e.target.value))
                }}
            >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                ))}
            </select>}
        </div>
    )
}

export default TablePagination