import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import queryString from "query-string"
import { deleteMeasurmentFile, getCalcRDIData, getProjectCalculations, updateRateResult } from "../../../actions/project"
import CommonTable from "../../../components/common/CommonTable"
import Icon from "../../../components/common/Icons"
import RaptorButton from "../../../components/common/RaptorButton"
import { DATE_FORMAT_2, DATE_TIME_FORMAT_1 } from "../../../constant"
import LabelSelectBox from "../../../components/common/LabelSelectBox"
import TileCard from "./TileCard"
import { colorCodes } from "../../../constant/colors"
import CommonMultiSelectFilter from "../../../components/common/CommonTable/CommonMultiSelectFilter"
import DeflectionChartSection from "./DeflectionChartSection"
import UploadStatus from "../UploadStatus"
import "./index.scss"

const DotButton = ({ className = '', onClickFn, isActive = false, title = "" }) => <div className={`dot-btn center-flex ${isActive ? 'active-state' : ''}`} title={title}>
  <div className={!!isActive ? className : 'grey-dot'} onClick={onClickFn} />
</div>

const columns = (dispatch, id, setDForm, setSelectedMeasName, calbList = [], selectedCalc, t) => {

  const calbName = (id) => {
    const rec = calbList.find(a => a._id === id)
    return !!rec && !!rec.fileName ? rec.fileName : '-'
  }
  return [
    { Header: "table.rd_name", accessor: (row) => !!row.roadName ? row.roadName : '-', isSortable: true },
    {
      Header: "table.progress", accessor: (row) => {
        const progressVal = ((row.runtimeStatus === "Running") || (row.runtimeStatus === "Pending")) ? 60 : ((row.runtimeStatus === "Complete") || (row.runtimeStatus === "Error") ? 100 : 0)
        const progressTxt = ((row.runtimeStatus === "Running") || (row.runtimeStatus === "Pending")) ? "In Progress" : (row.runtimeStatus === "Complete" ? "Complete" : "Error")
        return <UploadStatus className={row.runtimeStatus} progress={progressVal} text={progressTxt} uniqueSelector={row._id} />
      }, Filter: false
    },
    { Header: "table.type", accessor: (row) => !!row.roadType ? row.roadType : '-', Filter: false },
    {
      Header: "table.direction", accessor: (row) => !!row.direction ? row.direction : '-', filter: 'multiSelect', Filter: CommonMultiSelectFilter, options: [
        { label: '1' },
        { label: '2' },
        { label: '3' },
        { label: '4' }
      ]
    },
    {
      Header: "table.measur_file", accessor: (row) => !!row.fileName ? <>
        <div className="file-title" title={(row.fileName).replace('ready_for_upload.zip', '')}>{(row.fileName).replace('ready_for_upload.zip', '')}</div>
        <div className="file-time-stamp">{moment(new Date(row.measurementDateTime)).format(DATE_TIME_FORMAT_1)}</div>
      </> : '-', Filter: false
    },
    {
      Header: "table.calb_file", accessor: (row) => !!row.taggedCalibrationFileId ? <>
        <div className="file-title" title={calbName(row.taggedCalibrationFileId)}>{calbName(row.taggedCalibrationFileId)}</div>
      </> : '-', Filter: false
    },
    { Header: "table.comment", accessor: (row) => !!row.comment ? row.comment : '-', Filter: false },
    { Header: "table.date", accessor: "measurementDateTime", Filter: false, isSortable: true, type: 'date' },
    {
      Header: () => <div className="calc-heading-holder">
        <div>{t("table.rate_result")}</div>
        <div className="result_rate_indicator between-flex">
          <div className="result_dot failure" title={t('rate_result.failure')} />
          <div className="result_dot need_investigation" title={t('rate_result.need_investigation')} />
          <div className="result_dot satisfied" title={t('rate_result.satisfied')} />
        </div>
      </div>, id: 'calc-rate-result-header',
      accessor: (row) => <div className="rate_result-container">
        <DotButton className="failure" onClickFn={() => dispatch(updateRateResult({
          "calculationId": !!selectedCalc && !!selectedCalc._id ? selectedCalc._id : false,
          "inputFilesId": row.measurementFileIds,
          "rateResult": "something-is-wrong"
        }, id))}
          title={t('rate_result.satisfied')}
          isActive={!!row.rateResult && row.rateResult === 'something-is-wrong' ? true : false} />
        <DotButton className="need_investigation" onClickFn={() => dispatch(updateRateResult({
          "calculationId": !!selectedCalc && !!selectedCalc._id ? selectedCalc._id : false,
          "inputFilesId": row.measurementFileIds,
          "rateResult": "needs-investigation"
        }, id))}
          title={t('rate_result.need_investigation')}
          isActive={!!row.rateResult && row.rateResult === 'needs-investigation' ? true : false} />
        <DotButton className="satisfied" onClickFn={() => dispatch(updateRateResult({
          "calculationId": !!selectedCalc && !!selectedCalc._id ? selectedCalc._id : false,
          "inputFilesId": row.measurementFileIds,
          "rateResult": 'result-satisfied'
        }, id))}
          title={t('rate_result.failure')}
          isActive={!!row.rateResult && row.rateResult === 'result-satisfied' ? true : false} />
      </div>, Filter: false
    },
    {
      Header: t("table.actions"), accessor: (row) => <div className="graph-action-btn">
        <div className={`mr-5 ${!!row && !!row.url ? "" : "disabled-anchor"}`} onClick={() => {
          if (!!row.url) {
            setDForm(true)
            setSelectedMeasName(row.fileName || "")
            dispatch(getCalcRDIData(id, selectedCalc._id, row.measurementFileIds))
          }
        }}>
          <Icon name={"eye-2"} />
        </div>
        <a href={!!row && !!row.url ? row.url : "#!"} target={!!row && !!row.url ? "#" : "_self"} className={!!row && !!row.url ? "" : "disabled-anchor"}>
          <Icon name={"download2"} />
        </a>
      </div>, Filter: false
    }
  ]
}

const CalculationList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const { search } = useLocation()
  const searchParam = queryString.parse(search)
  const projectDetails = useSelector(state => state.projects)
  const projectInfo = projectDetails.projectInfo
  const { calculations, isProcessing, calcGraphInfo, isProcessingRDI } = useSelector(state => state.projects)
  const [selectedCalc, setSelectedCalc] = useState(false)
  const [selectedCalcId, setSelectedCalcId] = useState(false)
  const [dForm, setDForm] = useState(false)
  const [selectedMeasName, setSelectedMeasName] = useState("")

  const calcId = !!searchParam && !!searchParam.calcId ? searchParam.calcId : false
  useEffect(() => {
    if (!!id) {
      dispatch(getProjectCalculations(id))
    }

    // eslint-disable-next-line
  }, [id])

  const findAndSetCalculation = (_id) => {
    setSelectedCalcId(_id)
    if (!!id && !!calculations && !!calculations[id] && !!calculations[id].length) {
      const resp = calculations[id].find(a => a._id === _id)
      if (!!resp) {
        setSelectedCalc(resp)
        return
      }
    }
    setSelectedCalc(false)
  }

  useEffect(() => {
    if (!!calcId) {
      findAndSetCalculation(calcId)
    }

    // eslint-disable-next-line
  }, [calcId, calculations])


  useEffect(() => {
    if (!!selectedCalcId) {
      findAndSetCalculation(selectedCalcId)
    }

    // eslint-disable-next-line
  }, [selectedCalcId, calculations])

  const calbList = !!projectInfo && !!projectInfo.calibrationFiles ? projectInfo.calibrationFiles : []
  for (var i = 0; i < calbList.length; i++) {
    calbList[i]['fileName'] = (calbList[i]['fileName']).replace('_calibration_ready_for_upload.zip', '')
  }

  let inputFilesArr = !!selectedCalc && !!selectedCalc.measurementFileInfo ? selectedCalc.measurementFileInfo :
    (!!selectedCalc && !!selectedCalc.inputFiles ? selectedCalc.inputFiles : [])

  return (
    !!id && !!calculations && !!calculations[id] && !!calculations[id].length ?
      <div className='calculation-context'>

        {
          !!dForm && <DeflectionChartSection
            calcName={!!selectedCalc && !!selectedCalc.calculationName ? selectedCalc.calculationName : ""}
            measurName={selectedMeasName}
            isProcessing={isProcessingRDI} handleClose={() => {
              setSelectedMeasName("")
              setDForm(false)
            }} t={t} calcGraphInfo={calcGraphInfo} />
        }

        <div className="select-box-holder">
          <LabelSelectBox placeholder={t('calculations.select_calc')} isSearchable={true} handleOnChange={e => findAndSetCalculation(e.value)}
            label={t('calculations.select_calc')} className="calc-select-box"
            classNamePrefix="calculation" value={selectedCalc}
            options={calculations[id].map(s => ({ label: `${s.calculationName}`, value: s._id }))} />
        </div>

        {
          !!selectedCalc && <div className="brdr-btm-ln"><div className="calc-detail-sec">
            <div className="calc-name-card center-flex">{selectedCalc.calculationName}</div>
            <div className="calc-val-label-card">
              <div className="cal-value">{selectedCalc.ReportLengthM}</div>
              <div className="cal-label">{t("table.repo_length")}</div>
            </div>

            <div className="calc-val-label-card">
              <div className="cal-value">{!!Number(selectedCalc.isLoadChange) ? `${selectedCalc.NormalizationLoadN / 1000}kN` : '-'}</div>
              <div className="cal-label">{t("table.normo_load")}</div>
            </div>

            <div className="calc-val-label-card">
              <div className="cal-value">{!!Number(selectedCalc.isTempChange) ? `${selectedCalc.NormalizationTemperatureC} Degree` : '-'}</div>
              <div className="cal-label">{t("table.normo_temp")}</div>
            </div>

            <div className="calc-val-label-card">
              <div className="cal-value">{moment(selectedCalc.createdAt).format(DATE_FORMAT_2)}</div>
              <div className="cal-label">{t("table.created_on")}</div>
            </div>
          </div>
            <div className="tile-info-cont">
              <div className="tile-title-label">{t("calculations.total_measur_n_results")}</div>
              <div className="tile-list-holder">
                <TileCard icon="round-square-growth" count={selectedCalc.totalInputFiles || 0} />
                <TileCard icon="growth-arrow" count={selectedCalc.resultSatisfied || 0} />
                <TileCard icon="growth-arrow" iconColor={colorCodes.ORANGE_2} count={selectedCalc.needsInvestigation || 0} />
                <TileCard icon="growth-arrow" iconColor={colorCodes.RED_4} count={selectedCalc.somethingIsWrong || 0} />
              </div>
            </div>
          </div>
        }

        {
          !!selectedCalc && <div className="calc-heading-holder between-flex">
            <div className="heading-title">{t("project.measurement_files")}</div>
            <div className="d-flex">
              {
                !!selectedCalc.downloadAll &&
                <RaptorButton
                  title={t("button.download_all")}
                  className={`download-all-file-btn`}
                  onClick={() => window.open(selectedCalc.downloadAll)}>
                  <Icon name="download2" />
                  <div>{t("button.download_all")}</div>
                </RaptorButton>
              }
            </div>
          </div>
        }

        <div className='calc-context-table center-flex'>
          {
            !!inputFilesArr.length ?
              <CommonTable columns={columns(dispatch, id, setDForm, setSelectedMeasName, calbList, selectedCalc, t)} data={inputFilesArr}
                deleteIcon="trash2"
                deleteBtnTxt="button.dlt_measur_file"
                deleteFn={r => !!r && !!r.original && !!r.original.blobName && dispatch(deleteMeasurmentFile(id, { blobName: r.original.blobName }))} /> :
              <h3 className="center-flex h3-fetching-text">{
                !!isProcessing ? `${t('project.fetching')}...` : (
                  !!calculations && !!calculations.length && !!selectedCalc ? t('project.no_records') : t('project.pls_choose_calc')
                )
              }</h3>
          }
        </div>
      </div>
      :
      <div className="measurment-section-card">
        <div className="no-measurment-found center-flex">
          <Icon name="big-files" />
          <div className="no-measurment-title">{t("project.no_calc_yet")}</div>
          <div className="no-measurment-message">{t("project.please_add_calc_message")}</div>
          {false && <label onClick={() => navigate(`/raptor/projects/add-measurment/${id}`)}>{t("button.add_measurment")}</label>}
        </div>
      </div>
  )
}

export default CalculationList