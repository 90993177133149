import React from 'react'
import { colorCodes } from '../../../constant/colors'

const PieSlice = ({
    fill = colorCodes.BLACK_1,
    height = 22,
    width = 21,
    style = {},
    viewBox = '0 0 21 22',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill="none">
        <path d="M19.9045 8.36615C19.5949 6.4599 18.6311 4.72098 17.1786 3.44813C15.7262 2.17528 13.8759 1.44797 11.9455 1.39115C11.8517 1.38754 11.7581 1.40245 11.6701 1.43503C11.5821 1.46761 11.5014 1.51723 11.4325 1.58103C11.3637 1.64484 11.3082 1.72159 11.269 1.80689C11.2299 1.89219 11.2079 1.98437 11.2045 2.07815V2.07815V2.14215L11.6535 8.85215C11.6676 9.06921 11.767 9.27187 11.93 9.41593C12.093 9.55999 12.3063 9.63376 12.5235 9.62115L19.2515 9.17215C19.44 9.15661 19.6146 9.0669 19.737 8.92272C19.8595 8.77855 19.9197 8.59169 19.9045 8.40315V8.36615Z" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.28649 5.38315C7.49394 5.33491 7.71173 5.36056 7.9023 5.45566C8.09288 5.55077 8.24431 5.70938 8.33049 5.90416C8.37662 5.99804 8.40445 6.09986 8.41249 6.20416C8.50449 7.50415 8.69649 10.3502 8.80649 11.8882C8.81574 12.0253 8.85203 12.1593 8.91325 12.2824C8.97448 12.4055 9.05944 12.5153 9.16325 12.6055C9.26706 12.6956 9.38767 12.7643 9.51814 12.8077C9.64861 12.8511 9.78637 12.8682 9.92349 12.8582V12.8582L15.5715 12.5102C15.6962 12.5026 15.8211 12.5208 15.9386 12.5634C16.056 12.606 16.1635 12.6722 16.2543 12.7579C16.3452 12.8436 16.4176 12.9471 16.467 13.0618C16.5164 13.1766 16.5417 13.3002 16.5415 13.4252C16.4294 15.0851 15.7827 16.664 14.6982 17.9256C13.6137 19.1873 12.1497 20.0637 10.5255 20.4238C8.90124 20.7838 7.20406 20.6082 5.688 19.923C4.17195 19.2379 2.91857 18.0802 2.11549 16.6232C1.62398 15.7614 1.31268 14.8088 1.20049 13.8232C1.15113 13.5205 1.13137 13.2137 1.14149 12.9072C1.14635 11.1396 1.75866 9.42734 2.8758 8.0575C3.99293 6.68766 5.54699 5.74346 7.27749 5.38315" stroke={fill} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


)

export default PieSlice